import React from 'react';
import { PanelProps } from '@grafana/data';
import { AppStateProvider } from './app-context/AppStateContext';
import { MenuContainer } from 'MainMenu/MenuContainer';
import { ModalForm } from './ModalForm';
import { HCtype, HCnonce } from './help-center/help-center-script';
import { getTemplateSrv } from '@grafana/runtime';

export const SimplePanel: React.FunctionComponent<PanelProps> = ({ options }) => {
  // Help center
  React.useEffect(() => {
    /** Create script tag */
    const scriptTag = document.createElement('script');
    scriptTag.nonce = HCnonce;
    scriptTag.type = HCtype;
    scriptTag.src = options.helpCenterSrc;
    scriptTag.defer = true;
    document.body.appendChild(scriptTag);

    /** Get main dashboard language to modify help center default language if not english*/
    const varLang = getTemplateSrv().replace('$lang_selection') as any;

    if (!varLang) {
      console.log('no language found !');
      return;
    }

    if (varLang === 'FR') {
      // @ts-ignore
      window.ZohoHCAsapSettings = {
        lang: 'fr_FR',
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppStateProvider>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <MenuContainer />
        <ModalForm />
      </div>
    </AppStateProvider>
  );
};
