import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, FormFieldProps, RelatedPluginTypes } from 'app-context/types';
import { css } from '@emotion/css';
import { IconRelatedPlugin } from 'shared/IconRelatedPlugin';
import { CLASS_TABLE, MACHINE_FILTER_TABLE, TAGS_TABLE } from 'helpers/constants';
import { getDefaultValue } from 'helpers/helpersForm';
import { RedStarRequiredField, TooltipField } from 'shared';
import { IsoClassFields } from './IsoClassFields';
import { getFormStyles } from '../getStepStyles';
import { useFormFieldsStep1 } from 'hooks/useFormFieldsStep1';

/**
 * - Display form elements of General info section.
 * - Check if dashboard name (machine name) already exists in Grafana.
 * @fields mac_address, machine_name, process_function,power_class, manufactuer, model
 */

interface Field {
  name: string;
  isRequired: boolean;
  tableName: string;
}
interface FormFieldConditional extends FormFieldProps {
  setCurrentStepslist: React.Dispatch<
    React.SetStateAction<{
      id: CurrentStep;
      sectionTitle: string;
      fields: Field[];
    }>
  >;
  currentStepsList: {
    id: CurrentStep;
    sectionTitle: string;
    fields: Field[];
  };
}

export const FormField: React.FunctionComponent<FormFieldConditional> = ({
  isInvalid,
  isRequired,
  isSubmit,
  name,
  tableName,
  setCurrentStepslist,
  currentStepsList,
}) => {
  const { state } = useAppState();
  const { dictionary_uiElements, form } = state;
  const { TR_customClass } = dictionary_uiElements;

  const styles = useStyles2(getFormStyles);

  const { INPUT, indexFieldForm, optionsSelect } = useFormFieldsStep1(
    isInvalid,
    isSubmit,
    name,
    tableName,
    setCurrentStepslist,
    currentStepsList
  );

  return (
    <div
      className={
        tableName === CLASS_TABLE && getDefaultValue(indexFieldForm, form) === TR_customClass
          ? styles.fieldClass
          : styles.fieldContainer
      }>
      <div className={styles.label}>
        <div
          className={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          `}>
          <div>
            {/* Field name  */}
            {name} {isRequired && <RedStarRequiredField />}
            {/* Tooltip if field === class */}
            {tableName === 'class' && <TooltipField tableName={tableName} />}
          </div>
          {/* Display overview dashboard icon */}
          {(tableName === TAGS_TABLE || tableName === MACHINE_FILTER_TABLE) && (
            <div className={styles.iconContainer}>
              <IconRelatedPlugin plugin={RelatedPluginTypes.sd} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.inputContainer}>
        {/* Iso classes */}
        {tableName === CLASS_TABLE && (
          <IsoClassFields
            tableName={tableName}
            optionsSelect={optionsSelect}
            name={name}
            indexFieldForm={indexFieldForm}
            isInvalid={isInvalid}
          />
        )}
        {/* Other fields */}
        {INPUT}
      </div>
    </div>
  );
};
