/** Constant diverse strings */
export const MACHINE_TAG = 'Machine';

export const MAC_ADRESS_PREFIX = '00-04-a3-0b-';
export const EDITABLE_MAC_ADDRESS_LENGTH = 11;

export const SIMPLEJSON_DATASOURCE = 'DashGen';

/** Tag names */
export const MACHINE_FILTER_PREFIX = 'mac_';
export const SUMMARY_TAG = 'Summary';
export const COMPARISON_TAG = 'Comparison';
export const MAIN_TAG = 'Main';

/** Template variables name */
export const tagsVariableName = 'tags_selection';
export const alertStateVariableName = 'alertstate_selection';
export const foldersVariableName = 'folders_selection';
export const machinesVariableName = 'machines_selection';

/**
 * MySql tables and columns
 */
export const ANALYSIS_PROFILE_TABLE = 'analysis_profile';
export const CLASS_TABLE = 'class';
export const MAC_ADDRESS_TABLE = 'mac_address';
export const MACHINE_FILTER_TABLE = 'machine_filter';
export const TAGS_TABLE = 'tags';
export const MACHINE_NAME_TABLE = 'machine_name';
export const MANUFACTURER_TABLE = 'manufacturer';
export const MODEL_TABLE = 'model';
export const FUNCTION_TABLE = 'process_function';
export const SENSOR_LOCATION_TABLE = 'sensor_location';
export const SENSOR_ORIENTATION_TABLE = 'sensor_orientation';
export const TYPE_TABLE = 'type';

export const INCIDENT_ADVISOR_TXT = 'asystom-ad-ia';

export const FIELDS_JSON_IDCARD: string[] = [
  CLASS_TABLE,
  MAC_ADDRESS_TABLE,
  MACHINE_NAME_TABLE,
  MANUFACTURER_TABLE,
  MODEL_TABLE,
  FUNCTION_TABLE,
  SENSOR_LOCATION_TABLE,
  SENSOR_ORIENTATION_TABLE,
  TYPE_TABLE,
  ANALYSIS_PROFILE_TABLE,
];

export const TABLENAMES_DB: string[] = [...FIELDS_JSON_IDCARD, TAGS_TABLE];

export const STATUS_INVALIDATED = 'INVALIDATED'; // ai session invalidated status
