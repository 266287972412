import { getBackendSrv } from '@grafana/runtime';
import { API_SEARCH_DASHBOARDS, API_DASHBOARD_BY_UID } from 'helpers/URLS';
import { mysqlRequest } from 'services/requests';
import { NotificationError } from 'shared/components/NotificationMessage';

/**
 * Get all the dashboards from Grafana
 * @returns dashboards names flatted
 */

export const getDashboardsListNames = async (dsMysqlUid: string) => {
  let grafanaDashboards: any[] = [];

  await getBackendSrv()
    .get(API_SEARCH_DASHBOARDS)
    // exclude dashboards that are folders (type dash-folder)
    .then(
      (result) => (grafanaDashboards = result?.filter((dashboard: { type: string }) => dashboard.type === 'dash-db'))
    )
    .catch((error: any) => {
      console.log(error);
      NotificationError(error);
    });

  if (!Array.isArray(grafanaDashboards)) {
    return;
  }

  /**
   * Filter dashboards in mysql database that don't exist in Grafana.
   * - Avoid update of dashboards being deleted in Grafana but still in database.
   * - Avoid update of dashboards related to another organization.
   */

  /**
   * Get all the dashboards saved in mysql database
   */

  const sqlRequest = `SELECT machine_name, mac_address AS address from identity_card`;

  /**
   * The function metricFindQuery(request) formats results in a non exploitable way (in this current case):
   * E.g. : to get "mac_address" and "machine_name", data received is :
   * [{text:"Dashboard name"}, {text : "00-04-A3-0B-00-FF-FF-FF"}, {text: Dashboard Name2}, {text: "00-04-A3-0B-00-EE-EE-EE"} ...]
   * No way to bond dashboard name and mac_address in securely.
   *
   * getBackendSrv().request is more structured. Data structure:
   * [['0000', '00-04-a3-0b-00-00-00-00'] , ['TEST CREATION EN', '00-04-a3-0b-00-11-22-33']]
   */

  const queryResult = await mysqlRequest(dsMysqlUid, sqlRequest);
  if (!queryResult.length) {
    return [];
  }

  // Format to fit selection options model (dropdown menu of dashboards list)
  const bddDashboards = queryResult.map((value: string[]) => ({
    name: value[0],
    value: value[0],
    label: value[0],
    macAddress: value[1], // Catch mac_address to discriminate dashboards with similar name
  }));

  const filteredDashboards = [] as any;
  for (const dashboard of grafanaDashboards) {
    await getBackendSrv()
      .get(`${API_DASHBOARD_BY_UID}${dashboard.uid}`) // needed to get dashboard mac address
      .then(async (result) => {
        const templating = result?.dashboard.templating.list;
        if (!templating) {
          return;
        }
        // Get mac_address template
        const dashboardMacAddress = templating.find(
          (template: { name: string }) => template.name === 'beacon_selection'
        );
        if (!dashboardMacAddress) {
          return;
        }

        // Only keep dashboards in db whose name and mac_address are the same than grafana dashboards
        const filtered = bddDashboards.filter(
          (bddDash: { name: string; macAddress: string }) =>
            dashboard.title?.toUpperCase() === bddDash.name?.toUpperCase() &&
            dashboardMacAddress.query?.toUpperCase() === bddDash.macAddress?.toUpperCase()
        );

        filtered.length &&
          filteredDashboards.push({
            name: filtered[0].name,
            value: filtered[0].value,
            label: filtered[0].label,
            macAddress: dashboardMacAddress.query, // Catch mac_address to discriminate dashboards with similar name
            folderId: result?.meta.folderId,
            tags: result?.dashboard?.tags,
          });
      })
      .catch((error: any) => {
        console.log(error);
        NotificationError(error);
        return;
      });
  }
  return filteredDashboards.flat();
};
