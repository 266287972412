import { getDataSourceSrv, getBackendSrv } from '@grafana/runtime';
import { Action, ErrorTypes } from 'app-context/types';
import { setError } from 'helpers/helpersForm';
import { SIMPLEJSON_DATASOURCE } from './constants';
import { URL_GET_DATASOURCE_BY_NAME } from './URLS';

const getDatasourceData = async (variables: any[], datasource: string, dispatch: (value: Action) => void) => {
  const dsTemplate: any = variables && variables.filter((template) => template.name === datasource);
  if (!dsTemplate) {
    return [];
  }
  const datasourceData = await getDataSourceSrv()
    .get(dsTemplate[0]?.current.value)
    .catch((e) => {
      console.log(e);
      setError(ErrorTypes.datasources, 'no datasource', dispatch);
    });
  return datasourceData;
};

export const getDatasources = async (dispatch: (value: Action) => void, grafanaVariables: any[]) => {
  const mysqlDs = await getDatasourceData(grafanaVariables, 'mysql', dispatch);
  const influxDs = await getDatasourceData(grafanaVariables, 'influx', dispatch);
  const jsonDs = await getBackendSrv().get(`${URL_GET_DATASOURCE_BY_NAME}${SIMPLEJSON_DATASOURCE}`);

  dispatch({
    type: 'ADD_DATASOURCES',
    payload: {
      mysql: mysqlDs,
      influx: influxDs,
      json: jsonDs,
    },
  });
};
