import { getBackendSrv } from '@grafana/runtime';
/**
 * Get select options data from :
 * - public/asystom/dictionaries/wizard-tool/EN.js
 * - public/asystom/dictionaries/wizard-tool/FR.js
 */

export const getJson = async (url: string) => {
  const results = await getBackendSrv()
    .get(url)
    .catch((e) => {
      console.log('Error while getting dictionnary JSON ', e);
    });
  return results;
};
