import { Icon, IconName, useStyles2 } from '@grafana/ui';
import { Process } from 'app-context/types';
import { css } from '@emotion/css';
import { GREEN, ORANGE } from 'helpers/helpersStyle';
import * as React from 'react';

interface Props {
  onClick: () => void;
  buttonText: string;
  modal: Process;
}

export const RestartProcessButton: React.FunctionComponent<Props> = ({ onClick, buttonText, modal }) => {
  const styles = useStyles2(getStyles);
  const iconName: IconName = modal === Process.update ? 'sync' : 'plus';

  return (
    <div onClick={onClick} className={`${modal === Process.update ? styles.buttonUpdate : styles.buttonAdd}`}>
      <Icon name={iconName} size="xl" className={styles.secondaryIcon} />
      {buttonText}
    </div>
  );
};

const getStyles = () => {
  return {
    buttonAdd: css`
      color: ${GREEN};
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    `,
    buttonUpdate: css`
      color: ${ORANGE};
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    `,
    secondaryIcon: css`
      margin-right: 10px;
    `,
  };
};
