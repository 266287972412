import { useStyles2 } from '@grafana/ui';
import { CurrentStep, Process } from 'app-context/types';
import { css } from '@emotion/css';
import { GREEN, ORANGE } from 'helpers/helpersStyle';
import * as React from 'react';

export interface ProgressBarProps {
  modalProcess: Process;
  currentStep: number | null;
}

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ modalProcess, currentStep }) => {
  const widthBar = currentStep && currentStep * (100 / CurrentStep.VALIDATION);

  let colorBackColor = '';
  let colorFrontColor = '';

  switch (modalProcess) {
    case Process.create: {
      colorBackColor = `${GREEN}14`;
      colorFrontColor = GREEN;
      break;
    }
    case Process.update: {
      colorBackColor = `${ORANGE}14`;
      colorFrontColor = ORANGE;
      break;
    }
  }
  const styles = useStyles2(() => getStyles(colorBackColor, colorFrontColor, widthBar));

  return (
    <div className={styles.backBar}>
      <div className={styles.frontBar} />
    </div>
  );
};

const getStyles = (colorBackColor: string, colorFrontColor: string, widthBar: number | null) => {
  return {
    backBar: css`
      width: 100%;
      background: ${colorBackColor};
      height: 6px;
    `,
    frontBar: css`
      width: ${widthBar}%;
      height: 6px;
      transition: 1.5s;
      background: ${colorFrontColor};
    `,
  };
};
