import { getBackendSrv } from '@grafana/runtime';
import { DashboardInfos, IsoClassificationsProps, TemplatesVar } from 'app-context/types';
import { INCIDENT_ADVISOR_TXT } from 'helpers/constants';
import {
  buildNewQuery,
  CALC_FAHRENHEIT,
  CALC_INCH,
  isPanelWithLengthUnit,
  getTemperatureUnit,
  getVibratoryThreshold,
  queryMaxInch,
} from 'helpers/helpersDashboards';
import { TranslationProps } from 'app-context/translation-types';
import { DASHBOARD_JSON_MODEL, DASHBOARD_VALVE_JSON_MODEL } from 'helpers/URLS';

export const GetDashboardModel = async (
  dashboardInfos: DashboardInfos,
  dictionary_uiElements: TranslationProps,
  temperatureUnit: string,
  lengthUnit: string,
  analysisProfile: string,
  isoClassifications: IsoClassificationsProps
) => {
  const AMBIENT_TEMPERATURE_TEXT = dictionary_uiElements.TR_ambientTemperature?.toUpperCase();
  const SURFACE_TEMPERATURE_TEXT = dictionary_uiElements.TR_surfaceTemperature?.toUpperCase();
  const ALIAS_AMBIENT_TEMP = dictionary_uiElements.TR_aliasAmbientTemperature?.toUpperCase();
  const ALIAS_VIBRATION_SEVERITY = dictionary_uiElements.TR_vibratorySeverity?.toUpperCase();

  let customDashboard = {} as any;

  await getBackendSrv()
    .get(
      `${analysisProfile === dictionary_uiElements.TR_valve ? DASHBOARD_VALVE_JSON_MODEL : DASHBOARD_JSON_MODEL}${
        dashboardInfos.lang
      }.json`
    )
    .then((response) => {
      customDashboard = response;
    });

  const { dashboard } = customDashboard;
  dashboard.style = dashboardInfos.style;
  dashboard.tags = dashboardInfos.tags;
  dashboard.title = dashboardInfos.dashboardName;

  /** temperature graph unit */
  const tempUnit = getTemperatureUnit(temperatureUnit);

  let incidentAdvisorPanelIndex = 0;

  /**
   * Replace beacon_selection var with mac_address.
   * Convert temperature (°F / °C)
   * Convert length (in / mm)
   */

  const canChangeTemperature = (panelTitle: string | undefined) =>
    ((panelTitle && panelTitle?.toUpperCase() === AMBIENT_TEMPERATURE_TEXT) ||
      panelTitle?.toUpperCase() === SURFACE_TEMPERATURE_TEXT) &&
    tempUnit === 'fahrenheit';

  const customValuesReplaced = dashboard?.panels?.map(
    (
      panel: {
        targets: any;
        id: number;
        datasource: { type: string; uid: string };
        type: string;
        panels: any[];
        title?: string;
        alert: any;
        yaxes: any;
      },
      panelIndex: number
    ) => {
      if (panel.type === INCIDENT_ADVISOR_TXT) {
        incidentAdvisorPanelIndex = panelIndex;
        panel.targets.forEach((target: { alias: string; query: string }) => {
          // Convert temperature unit and length unit in Incident Advisor plugin if necessary
          if (target.alias.toUpperCase() === ALIAS_AMBIENT_TEMP && tempUnit === 'fahrenheit') {
            target.query = buildNewQuery(target.query, 'FROM', `${CALC_FAHRENHEIT} FROM`);
          }
          // convert mm to inch
          if (target.alias?.toUpperCase() === ALIAS_VIBRATION_SEVERITY && lengthUnit === 'inch') {
            target.query = buildNewQuery(target.query, 'FROM', `${CALC_INCH} FROM`);
          }
        });
      }

      // Convert temperature unit from °C to °F in the other panels
      const changeTemperatureUnit = canChangeTemperature(panel?.title);

      // If unit is changed, update yaxes format and max of dashboard model
      if (changeTemperatureUnit) {
        dashboard.panels[panelIndex].fieldConfig.defaults.unit = tempUnit;
        dashboard.panels[panelIndex].fieldConfig.defaults.max = null;
      }

      // Convert length unit from mm to inch
      const changeLengthUnit = isPanelWithLengthUnit(panel.id) && lengthUnit === 'inch';

      if (changeLengthUnit) {
        dashboard.panels[panelIndex].fieldConfig.defaults.custom.axisLabel = 'in/s';
      }
      /** Panel is not collapased (section is open) */
      if (panel?.targets?.length) {
        panel?.targets?.map(
          (
            target: { query: string; alias: string; datasource: { type: string; uid: string } },
            targetIndex: number
          ) => {
            if (target.query) {
              /** Inject datasources */
              if (panel?.datasource?.type === 'mysql') {
                panel.datasource.uid = dashboardInfos.mysql.uid;
                target.datasource.uid = dashboardInfos.mysql.uid;
              }
              if (panel?.datasource?.type === 'influxdb') {
                panel.datasource.uid = dashboardInfos.influx.uid;
                target.datasource.uid = dashboardInfos.influx.uid;
              }

              // Convert temperature if necessary
              if (changeTemperatureUnit) {
                const convertTemperatureQuery = buildNewQuery(
                  dashboard.panels[panelIndex].targets[targetIndex].query,
                  'FROM',
                  `${CALC_FAHRENHEIT} FROM`
                );
                dashboard.panels[panelIndex].targets[targetIndex].query = convertTemperatureQuery;
              }
              // Convert length if necessary
              if (changeLengthUnit) {
                let convertLengthQuery = '';
                if (target.alias === 'MaxScale') {
                  // The max query needs to be treated differently
                  convertLengthQuery = queryMaxInch('$beacon_selection');
                } else {
                  convertLengthQuery = buildNewQuery(
                    dashboard.panels[panelIndex].targets[targetIndex].query,
                    'FROM',
                    `${CALC_INCH} FROM`
                  );
                }
                dashboard.panels[panelIndex].targets[targetIndex].query = convertLengthQuery;
              }

              // Replace '$beacon_selection' by the current mac address
              dashboard.panels[panelIndex].targets[targetIndex].query = target.query.replace(
                '$beacon_selection',
                dashboardInfos.beacon
              );
              return target;
            }
            return target;
          }
        );
        return panel;

        // Panel is collapsed (section is closed)
      } else if (panel.type === 'row' && panel.panels?.length) {
        panel.panels.map((panelRow, panelRowIndex) => {
          // Check if user has chosen unit other than celsius (default)
          const changeTemperatureUnit = canChangeTemperature(panelRow?.title);
          // If unit is changed, update yaxes format and max of dashboard model
          if (changeTemperatureUnit) {
            dashboard.panels[panelIndex].panels[panelRowIndex].fieldConfig.defaults.unit = tempUnit;
            dashboard.panels[panelIndex].panels[panelRowIndex].fieldConfig.defaults.max = null;
          }

          // Convert length unit from mm to inch
          const changeLengthUnit = isPanelWithLengthUnit(panelRow.id) && lengthUnit === 'inch';
          if (changeLengthUnit) {
            dashboard.panels[panelIndex].panels[panelRowIndex].fieldConfig.defaults.custom.axisLabel = 'in/s';
          }

          if (panelRow?.targets?.length) {
            panelRow?.targets?.map(
              (
                targetRow: { query: string; alias: string; datasource: { type: string; uid: string } },
                targetIndex: number
              ) => {
                if (targetRow.query) {
                  if (panelRow?.title) {
                    if (
                      panelRow.title?.toLowerCase().includes(dictionary_uiElements.TR_vibratorySeverity?.toLowerCase())
                    ) {
                      const currentClass = dashboardInfos.idCardValues.class;

                      /**
                       * As this panel is not collapsed by default,
                       * we know that the vibration severity alert panels (alarm and trip) are among these panels
                       */
                      let vibratoryThreshold = getVibratoryThreshold(
                        currentClass,
                        dictionary_uiElements,
                        lengthUnit,
                        dashboardInfos.customIso,
                        isoClassifications
                      );

                      if (
                        panelRow.title?.toLowerCase() === dictionary_uiElements.TR_vibratorySeverityAlarm?.toLowerCase()
                      ) {
                        dashboard.panels[panelIndex].panels[panelRowIndex].alert.conditions[0].evaluator.params[0] =
                          vibratoryThreshold.alarm;
                      }

                      if (
                        panelRow.title?.toLowerCase() ===
                          dictionary_uiElements.TR_vibratorySeverityTrip?.toLowerCase() ||
                        panelRow.title?.toLowerCase() === dictionary_uiElements.TR_vibratorySeverity?.toLowerCase() // Valve
                      ) {
                        dashboard.panels[panelIndex].panels[panelRowIndex].alert.conditions[0].evaluator.params[0] =
                          vibratoryThreshold.trip;
                      }
                    }
                  }

                  // Inject datasources
                  if (panelRow?.datasource?.type === 'mysql') {
                    panelRow.datasource.uid = dashboardInfos.mysql.uid;
                    targetRow.datasource.uid = dashboardInfos.mysql.uid;
                  }
                  if (panelRow?.datasource?.type === 'influxdb') {
                    panelRow.datasource.uid = dashboardInfos.influx.uid;
                    targetRow.datasource.uid = dashboardInfos.influx.uid;
                  }

                  /** Inject temperature unit if fahrenheit (celsius is set by default) */
                  if (changeTemperatureUnit) {
                    const newQuery = buildNewQuery(
                      dashboard.panels[panelIndex].panels[panelRowIndex].targets[targetIndex].query,
                      'FROM',
                      `${CALC_FAHRENHEIT} FROM`
                    );
                    dashboard.panels[panelIndex].panels[panelRowIndex].targets[targetIndex].query = newQuery;
                  }

                  if (changeLengthUnit) {
                    let convertLengthQuery = '';
                    if (targetRow.alias === 'MaxScale') {
                      // The max query needs to be treated differently
                      convertLengthQuery = queryMaxInch('$beacon_selection');
                    } else {
                      convertLengthQuery = buildNewQuery(
                        dashboard.panels[panelIndex].panels[panelRowIndex].targets[targetIndex].query,
                        'FROM',
                        `${CALC_INCH} FROM`
                      );
                    }
                    dashboard.panels[panelIndex].panels[panelRowIndex].targets[targetIndex].query = convertLengthQuery;
                  }

                  /** Replace '$beacon_selection' by the current mac address */
                  dashboard.panels[panelIndex].panels[panelRowIndex].targets[targetIndex].query =
                    targetRow.query.replace('$beacon_selection', dashboardInfos.beacon);
                  return targetRow;
                }
                return targetRow;
              }
            );
            return panelRow;
          }
        });
      }
      return panel;
    }
  );

  dashboard.panels = customValuesReplaced;

  /**
   * Fill identity card infos in dashboard JSON
   */

  const visibleItemsIdCard = [] as any;
  const fieldsJsonIdCard = [
    'analysis_profile',
    'class',
    'mac_address',
    'machine_name',
    'manufacturer',
    'model',
    'process_function',
    'sensor_location',
    'sensor_orientation',
    'subtype',
    'type',
    'customIso',
  ];

  fieldsJsonIdCard.map((field) => {
    visibleItemsIdCard.push({
      field: `${field}`,
      value: dashboardInfos.idCardValues[`${field}`] ? dashboardInfos.idCardValues[`${field}`] : '',
    });
  });

  dashboard.panels[incidentAdvisorPanelIndex].idCardElements = visibleItemsIdCard;

  /**
   * Inject templates variables
   */

  const templatesToInsert = [
    { name: TemplatesVar.beacon, label: 'Beacon', value: dashboardInfos.beacon },
    { name: TemplatesVar.lang, label: 'lang_sel_label', value: dashboardInfos.lang },
    { name: TemplatesVar.X, label: null, value: dashboardInfos.X },
    { name: TemplatesVar.Y, label: null, value: dashboardInfos.Y },
    { name: TemplatesVar.Z, label: null, value: dashboardInfos.Z },
    { name: TemplatesVar.image, label: null, value: dashboardInfos.fileImageName },
    { name: TemplatesVar.lengthUnit, label: null, value: lengthUnit },
  ];

  // If the list of templates is empty, we can push all user templates;
  // otherwise, old values are replaced
  if (!dashboard.templating.list.length) {
    templatesToInsert.map((template) => {
      dashboard.templating.list.push({
        hide: 2,
        label: template.label,
        name: template.name,
        query: template.value,
        skipUrlSync: false,
        type: 'constant',
      });
    });
  } else {
    templatesToInsert.map((template) => {
      const templateObject = {
        hide: 2,
        label: template.label,
        name: template.name,
        query: template.value,
        skipUrlSync: false,
        type: 'constant',
      };

      const indexofTemplate = dashboard.templating.list.findIndex(
        (dashTemplate: { name: string }) => dashTemplate.name === template.name
      );
      // Check if user template already exists
      // replace the old value by the user one
      if (indexofTemplate !== -1) {
        dashboard.templating.list[indexofTemplate] = templateObject;
      } else {
        dashboard.templating.list.push(templateObject);
      }
    });
  }

  // add datasource uid to session_id template (or any other template that needs a mysql datasource)
  dashboard.templating.list.map((template: { datasource: { type: string; uid: string } }) => {
    if (template?.datasource) {
      if (template.datasource.type === 'mysql') {
        template.datasource.uid = dashboardInfos.mysql.uid;
      }
    }
  });

  return customDashboard;
};
