/**
 * Resize any impage post by user
 * @param base64
 * Create output image (maxWidth = 570/maxHeight = 500)
 */

export function thumbnail(base64: any) {
  // Max size for thumbnail
  const maxWidth = 570;
  const maxHeight = 500;

  // Create and initialize two canvas
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  let canvasCopy = document.createElement('canvas');
  let copyContext = canvasCopy.getContext('2d') as CanvasRenderingContext2D;

  // Create original image
  let img = new Image();
  img.src = base64;

  // Determine new ratio based on max size
  let ratio = 1;
  if (img.width > maxWidth) {
    ratio = maxWidth / img.width;
  } else if (img.height > maxHeight) {
    ratio = maxHeight / img.height;
  }
  // Draw original image in second canvas
  canvasCopy.width = img.width;
  canvasCopy.height = img.height;
  copyContext.drawImage(img, 0, 0);

  // Copy and resize second canvas to first canvas
  canvas.width = img.width * ratio;
  canvas.height = img.height * ratio;

  // (image, sourcex, sourcey, sourceLargeur, sourceHauteur, destinationx, dy, dLargeur, dHauteur);
  ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL('image/jpeg', 0.85);
}
