import { Icon, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, ErrorTypes } from 'app-context/types';
import { css } from '@emotion/css';
import { getDashboardsListNames } from 'helpers/GetDashboardsList';
import { isInputInvalid, setError } from 'helpers/helpersForm';
import { RED } from 'helpers/helpersStyle';
import { TranslationProps } from 'app-context/translation-types';
import React, { useEffect, useState } from 'react';
import { SectionTitle } from 'shared/SectionTitle';
import { formSteps } from '../../formSteps';
import { FormFieldStep0 } from './FormFieldStep0';
import { DisplayErrors } from 'shared/components/DisplayErrors';

/**
 * - Retrieve Grafana dashboards and dashboards saved in db grafana_asystom.identity_card
 * - Compare them and keep the dashboards found in Grafana AND in db
 * - Display a dropdown menu with all the dashboards names to be updated or deleted
 */

export const Step0: React.FunctionComponent = () => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useAppState();
  const { dictionary_uiElements, error, datasourceMysql, steps, form, dashboardsList, customIso } = state;
  const { TR_errorNoDashboard, TR_errorRequestDb } = dictionary_uiElements;
  const styles = useStyles2(getStyles);

  const stepsList = formSteps(dictionary_uiElements);
  const { step99, step0 } = stepsList[0];
  const isUpdateSubmit = steps.stepsStatus.step0?.isSubmit;
  const isDeleteSubmit = steps.stepsStatus.step99?.isSubmit;

  const queryDashboardNames = async () => {
    setIsLoading(true);
    const dashboardNames = await getDashboardsListNames(datasourceMysql.uid).catch(() =>
      setError(ErrorTypes.badRequest, TR_errorRequestDb, dispatch)
    );
    if (!dashboardNames) {
      setError(ErrorTypes.noDashboardFound, TR_errorNoDashboard, dispatch);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    dispatch({ type: 'SET_DASHBOARDS_LIST', payload: dashboardNames });
  };

  useEffect(() => {
    if (!isDeleted && !form.length) {
      queryDashboardNames();
    }
  }, [isDeleted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        {/* Display errors */}
        <DisplayErrors error={error} dictionary_uiElements={dictionary_uiElements} />
        {!error.datasource && (
          <form className={styles.formStyle}>
            {/* title of deletion form */}
            {steps.current === CurrentStep.UPDATE_STEP1 && <SectionTitle title={step0.sectionTitle} />}
            {/* Dropdown menu of dashboard names to UPDATE */}
            {steps.current === CurrentStep.UPDATE_STEP1 &&
              step0.fields.map((field: any) => {
                return (
                  <FormFieldStep0
                    name={field.name}
                    tableName={field.tableName}
                    isRequired={field.isRequired}
                    key={field.name}
                    isInvalid={isInputInvalid(
                      field.name,
                      step0,
                      isUpdateSubmit,
                      form,
                      error,
                      customIso,
                      dictionary_uiElements
                    )}
                    dashboardsNames={dashboardsList}
                    loading={isLoading}
                  />
                );
              })}
            {/* title of update form */}
            {steps.current === CurrentStep.DELETE_STEP1 && !isDeleted && <SectionTitle title={step99.sectionTitle} />}
            {/* Dropdown menu of dashboard names to DELETE */}
            {steps.current === CurrentStep.DELETE_STEP1 &&
              !isDeleted &&
              step99.fields.map((field: any) => {
                return (
                  <FormFieldStep0
                    name={field.name}
                    tableName={field.tableName}
                    isRequired={field.isRequired}
                    key={field.name}
                    isInvalid={isInputInvalid(
                      field.name,
                      step99,
                      isDeleteSubmit,
                      form,
                      error,
                      customIso,
                      dictionary_uiElements
                    )}
                    dashboardsNames={dashboardsList}
                    setIsDeleted={setIsDeleted}
                    isDeleted={isDeleted}
                    loading={isLoading}
                  />
                );
              })}
            {/* If dashboard has been deleted, display message of deletion confirmation */}
            {isDeleted && <ConfirmDelete dictionary_uiElements={dictionary_uiElements} setIsDeleted={setIsDeleted} />}
          </form>
        )}
      </div>
    </div>
  );
};

interface ConfirmDeleteProps {
  dictionary_uiElements: TranslationProps;
  setIsDeleted: (value: boolean) => void;
}

const ConfirmDelete: React.FunctionComponent<ConfirmDeleteProps> = ({ dictionary_uiElements, setIsDeleted }) => {
  // translations
  const { TR_dashboardDeleted, TR_deleteAnother } = dictionary_uiElements;
  const styles = getStyles();
  return (
    <div className={styles.deleteContainer}>
      <h4 className={styles.title}>
        <Icon name="check" size="xxl" className={styles.icon} />
        {TR_dashboardDeleted} !
      </h4>
      <div>
        {/* "Delete another dashboard" button */}
        <div onClick={() => setIsDeleted(false)} className={styles.buttonDelete}>
          <Icon name="trash-alt" size="xl" className={styles.secondaryIcon} />
          {TR_deleteAnother}
        </div>
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    `,
    formContainer: css`
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    `,
    deleteContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 350px;
    `,
    buttonDelete: css`
      color: ${RED};
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      display: flex;
      height: 45px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      &:hover {
        opacity: 0.6;
      }
    `,
    secondaryIcon: css`
      margin-right: 10px;
    `,
    button: css`
      border: none;
      border-radius: 15px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      color: #8e8e8e;

      &:hover {
        opacity: 0.6;
      }
    `,
    title: css`
      font-size: 26px;
      color: #8e8e8e;
      text-align: center;
    `,
    icon: css`
      color: #8e8e8e;
      margin-right: 10px;
    `,
    formStyle: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 635px;
      min-width: 300px;
      justify-content: center;
      align-items: center;
      margin: auto;
    `,
  };
};
