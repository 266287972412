import { Process } from 'app-context/types';

/**
 * Colors
 */

export const BLUE_MACHINE_FILTER = '#bed7ff';
export const DRAGGING_OVER_COLOR = '#fbf5ec';

export const RED = '#FD7B63';
export const ORANGE = '#FA9D53';
export const GREEN = '#8AB7BB';

export const create_bg_button = GREEN;
export const create_bg_button_hover = '#88b3b7';
export const create_button_txt = '#051711';

export const update_bg_button = ORANGE;
export const update_bg_button_hover = '#f18e3f';
export const update_button_txt = '#422e03';

export const getColorOfProcess = (modalProcess: Process) => {
  let bgColor = '';
  let hoverBgColor = '';
  let txtColor = '';

  switch (modalProcess) {
    case Process.create:
      bgColor = GREEN;
      hoverBgColor = create_bg_button_hover;
      txtColor = '#051711';
      break;

    case Process.update:
      bgColor = ORANGE;
      hoverBgColor = update_bg_button_hover;
      txtColor = '#422e03';
      break;

    case Process.delete:
      bgColor = RED;
      hoverBgColor = '#e76750';
      txtColor = '#fbeaea';
      break;
  }
  return { bg: bgColor, hoverBg: hoverBgColor, txt: txtColor };
};

export const getTitleStyle = (modalProcess: Process, styles: any) => {
  let titleStyle = styles.title;

  switch (modalProcess) {
    case Process.update:
      titleStyle = styles.titleUpdate;
      break;

    case Process.delete:
      titleStyle = styles.titleDelete;
      break;

    case Process.manage_single_pages:
      titleStyle = styles.titleFilters;
      break;

    default:
      titleStyle = styles.titleCreate;
  }
  return titleStyle;
};

export const getIcon = (modalProcess: Process) => {
  let icon = '';

  switch (modalProcess) {
    case Process.create:
      icon = 'plus';
      break;

    case Process.update:
      icon = 'sync';
      break;

    case Process.delete:
      icon = 'trash-alt';
      break;

    case Process.manage_single_pages:
      icon = 'lock';
      break;

    default:
      icon = '';
  }
  return icon;
};

export const formContainerStyle = `
width: 100%;
max-width: 635px;
min-width: 300px;
height: 25px;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
margin-bottom: 12px;
`;
