import React from 'react';
import { RelatedPluginTypes } from 'app-context/types';
import { Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';

const IaIcon = require('img/icon-IA.png') as string;
const SdIcon = require('img/icon-SD.png') as string;

interface Props {
  plugin: RelatedPluginTypes;
}

export const IconRelatedPlugin: React.FunctionComponent<Props> = ({ plugin }) => {
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  const { TR_infoRequired_IA, TR_infoRequired_SD } = dictionary_uiElements;

  let imgIcon = '';
  let tooltipContent = '';
  let marginLeft = '';

  switch (plugin) {
    case RelatedPluginTypes.ia:
      imgIcon = IaIcon;
      tooltipContent = TR_infoRequired_IA;
      marginLeft = '0px';

      break;
    case RelatedPluginTypes.sd:
      imgIcon = SdIcon;
      tooltipContent = TR_infoRequired_SD;
      marginLeft = '0px';

      break;
    default:
      break;
  }

  return (
    <Tooltip content={tooltipContent} placement={'right'}>
      <img src={imgIcon} width={22} height={22} style={{ marginLeft: `${marginLeft}` }} />
    </Tooltip>
  );
};
