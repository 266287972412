import { useStyles2, Icon } from '@grafana/ui';
import { Process } from 'app-context/types';
import React from 'react';
import { css } from '@emotion/css';

interface FinalMessagesProps {
  currentModal: Process;
  dashboardName: string;
  messageUpdate: string;
  messageUpdate2: string;
  messageCreate: string;
  messageCreate2: string;
}

export const FinalMessages: React.FunctionComponent<FinalMessagesProps> = ({
  currentModal,
  dashboardName,
  messageUpdate,
  messageUpdate2,
  messageCreate,
  messageCreate2,
}) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.headerContainer}>
      <h4 className={styles.title}>
        <Icon name="check" size="xxl" className={styles.icon} />
        {currentModal === Process.create ? messageCreate : messageUpdate} <b>{dashboardName}</b> !
      </h4>
      <div className={styles.subtitle}>
        {currentModal === Process.update && messageUpdate2}
        {currentModal === Process.create && messageCreate2}
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    headerContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    title: css`
      font-size: 29px;
      color: #8e8e8e;
      text-align: center;
    `,
    subtitle: css`
      font-size: 18px;
      color: #bababa;
    `,
    icon: css`
      color: #8e8e8e;
      margin-right: 10px;
    `,
  };
};
