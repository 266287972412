import React, { useState } from 'react';
import { SectionTitle } from '../../../shared';
import { Select, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { useAppState } from '../../../app-context/AppStateContext';
import { SelectableValue, GrafanaTheme2 } from '@grafana/data';

interface UnitsFormProps {}

/** Dropdown menu of temperature units options */

export const UnitsForm: React.FunctionComponent<UnitsFormProps> = () => {
  const { state, dispatch } = useAppState();
  const { temperatureUnit, dictionary_uiElements, lengthUnit } = state;
  const {
    TR_placeHolderTemperature,
    TR_length,
    TR_meter,
    TR_inch,
    TR_temperature,
    TR_unitsTitle,
    TR_placeholderLength,
  } = dictionary_uiElements;

  const lengthLabel = lengthUnit === 'meter' ? TR_meter : TR_inch;

  const [temperature, setTemperature] = useState<SelectableValue>({ label: temperatureUnit, value: temperatureUnit });
  const [length, setLength] = useState<SelectableValue>({ label: lengthLabel, value: lengthUnit });

  const styles = useStyles2(getStyles);

  const handleChangeTemperature = (v: any) => {
    setTemperature({ label: v.label, value: v.label });
    dispatch({ type: 'SET_TEMPERATURE_UNIT', payload: v.label });
  };

  const handleChangeLength = (v: any) => {
    setLength(v);
    dispatch({ type: 'SET_LENGTH_UNIT', payload: v.value });
  };

  const temperatureOptions = [
    { label: 'Celsius (°C)', value: 'Celsius (°C)' },
    { label: 'Fahrenheit (°F)', value: 'Fahrenheit (°F)' },
  ];

  const lengthOptions = [
    { label: TR_meter, value: 'meter' },
    { label: TR_inch, value: 'inch' },
  ];

  return (
    <>
      {/* Units section */}
      <SectionTitle title={TR_unitsTitle} />
      <div className={styles.container}>
        <div className={styles.label}>
          <div>{TR_temperature?.toUpperCase()}</div>
        </div>
        <div className={styles.buttonsGroup}>
          <div className={styles.buttonWithIcon}>
            <Select
              width={50}
              options={temperatureOptions}
              value={temperature.value}
              onChange={(e) => handleChangeTemperature(e)}
              placeholder={TR_placeHolderTemperature}
              menuPlacement={'top'}
            />
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.label}>
          <div>{TR_length?.toUpperCase()}</div>
        </div>
        <div className={styles.buttonsGroup}>
          <div className={styles.buttonWithIcon}>
            <Select
              width={50}
              options={lengthOptions}
              value={length.value}
              onChange={(e) => handleChangeLength(e)}
              placeholder={TR_placeholderLength}
              menuPlacement={'top'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      width: 100%;
      max-width: 635px;
      min-width: 300px;
      height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto 12px auto;
    `,
    label: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text};
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      margin-right: 10px;
      max-width: 250px;
      min-width: 225px;
      padding: 7px;
      text-align: left;
      text-justify: center;
      width: 30%;
    `,
    buttonWithIcon: css`
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: row;
      border-radius: 5px;
    `,
    buttonsGroup: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
    `,
  };
};
