import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from 'app-context/AppStateContext';
import { RelatedPluginTypes } from 'app-context/types';
import { css } from '@emotion/css';
import { ANALYSIS_PROFILE_TABLE } from 'helpers/constants';
import { isInputInvalid } from 'helpers/helpersForm';
import React from 'react';
import { IconRelatedPlugin } from 'shared/IconRelatedPlugin';
import { SectionTitle } from 'shared/SectionTitle';
import { formSteps } from '../../formSteps';
import { FormFieldStep3 } from './FormFieldStep3';

/**
 * FORM STEP 3
 * Analysis profile
 * @fields : analysis_profile
 */

export interface Step3Props {}

export const Step3: React.FunctionComponent<Step3Props> = () => {
  const { state } = useAppState();
  const { dictionary_uiElements, form, error, customIso } = state;
  const styles = useStyles2(getStyles);

  const { TR_textAnalysisProfile, TR_textAnalysisProfile2, TR_textAnalysisProfile3, TR_textAnalysisProfile4 } =
    dictionary_uiElements;
  const formStep = formSteps(dictionary_uiElements);
  const { step3 } = formStep[0];
  const isSubmit = state.steps.stepsStatus.step3?.isSubmit;

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <SectionTitle title={step3.sectionTitle} />
        <div className={styles.containerText}>
          <div className={styles.headerText}>{TR_textAnalysisProfile}</div>
          <ul>
            <li style={{ marginBottom: '15px' }}>{TR_textAnalysisProfile2}</li>
            <li style={{ marginBottom: '15px' }}>{TR_textAnalysisProfile3}</li>
            <li style={{ marginBottom: '15px' }}>{TR_textAnalysisProfile4}</li>
          </ul>
        </div>
        {step3.fields.map(({ name, tableName, isRequired }) => {
          return (
            <div className={styles.fieldContainer} key={`${name}-${tableName}`}>
              <div className={styles.iconContainer}>
                {tableName === ANALYSIS_PROFILE_TABLE && <IconRelatedPlugin plugin={RelatedPluginTypes.ia} />}
              </div>
              <FormFieldStep3
                name={name}
                tableName={tableName}
                isRequired={isRequired}
                key={name}
                isInvalid={isInputInvalid(name, step3, isSubmit, form, error, customIso, dictionary_uiElements)}
                isSubmit={isSubmit}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    `,
    formContainer: css`
      display: flex;
      justify-content: center;
      flex-direction: column;
    `,
    containerText: css`
      width: 100%;
      text-align: left;
      max-width: 650px;
      min-width: 300px;
      margin: 20px 0;
    `,
    headerText: css`
      font-weight: 600;
      margin-bottom: 25px;
    `,
    fieldContainer: css`
      display: flex;
      flex-direction: row;
    `,
    iconContainer: css`
      width: 32px;
      text-align: center;
    `,
  };
};
