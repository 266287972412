import { Icon, IconName, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, Process } from 'app-context/types';
import { css, keyframes } from '@emotion/css';
import { findIndexInForm } from 'helpers/helpersForm';
import { getIcon, getTitleStyle, GREEN, ORANGE, RED } from 'helpers/helpersStyle';
import React from 'react';
import { ProgressBar } from './ProgressBar';
import { MAC_ADRESS_PREFIX } from 'helpers/constants';

interface HeaderFormProps {
  title: string;
  modalProcess: Process;
}

/**
 * Display form title and progress bar
 */

export const Header: React.FunctionComponent<HeaderFormProps> = ({ title, modalProcess }) => {
  const { state } = useAppState();
  const { currentModal, dashboardFolder, dictionary_uiElements, form, steps } = state;

  const styles = useStyles2(getStyles);
  const titleStyle = getTitleStyle(modalProcess, styles);
  const iconName = getIcon(modalProcess) as IconName;
  const { TR_dashboardNameField, TR_folder, TR_macAddressField, TR_machineNameField } = dictionary_uiElements;
  const indexMacAddress = findIndexInForm(TR_macAddressField, form);
  const indexMachineName = findIndexInForm(TR_machineNameField, form);
  const macAddressValue = form[indexMacAddress]?.value;
  const dashboardName = form[indexMachineName]?.value;

  return (
    <div className={styles.formHeader}>
      <div className={styles.titleContainer}>
        <div className={`${titleStyle} ${styles.titleWrapper}`}>
          <Icon name={iconName} size="xxl" className={styles.icon} />
          <div className={`${titleStyle} ${styles.title}`}>{title}</div>
        </div>
        {/*  Display mac address, dashboard name and folder at the top right corner form*/}
        {currentModal !== Process.delete &&
          currentModal !== Process.manage_single_pages &&
          steps.current !== CurrentStep.UPDATE_STEP1 &&
          steps.current !== CurrentStep.SUMMARY &&
          steps.current !== CurrentStep.VALIDATION && (
            <div
              className={css`
                animation: ${styles.element} 0.9s ease;
              `}>
              <div className={styles.fixedDataContainer}>
                {/* Folder name */}
                <div className={styles.fixedData}>
                  {TR_folder?.toUpperCase()} : {dashboardFolder.name}
                </div>
                {/* MAC address */}
                <div className={styles.fixedData}>
                  {TR_macAddressField} : {currentModal === Process.create && MAC_ADRESS_PREFIX}
                  {macAddressValue}
                </div>
                {/* Dashboard name */}
                <div className={styles.fixedData}>
                  {TR_dashboardNameField} : {dashboardName}
                </div>
              </div>
            </div>
          )}
      </div>
      {/* Don't display progress bar on delete and manage single pages process */}
      {currentModal !== Process.delete && currentModal !== Process.manage_single_pages && (
        <ProgressBar modalProcess={modalProcess} currentStep={steps.current} />
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    formHeader: css`
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
    titleContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 64px;
    `,
    title: css`
      font-size: 32px;
      font-weight: 700;
    `,
    titleCreate: css`
      color: ${GREEN};
    `,
    titleUpdate: css`
      color: ${ORANGE};
    `,
    titleDelete: css`
      color: ${RED};
    `,
    icon: css`
      margin-right: 10px;
      font-weight: 700;
    `,
    fixedDataContainer: css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 10px;
      transition: opacity 5s;
      opacity: 1;
    `,
    element: keyframes`
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    } 
    `,
    fixedData: css`
      font-size: 12px;
      font-weight: 500;
      color: #b1b1b1;
      margin-left: 34px;
    `,
    titleWrapper: css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 2;
    `,
  };
};
