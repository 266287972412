import React from 'react';
import { Modal, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { closeModal } from 'helpers/helpersModal';
import { FooterLogo } from 'shared/FooterLogo';
import FormContainer from './form/form-navigation-components/FormContainer';
import { useModalForm } from 'hooks/useModalForm';

/** Contains each Wizard form process (create, update, delete) */

interface ModalFormProps {}

export const ModalForm: React.FunctionComponent<ModalFormProps> = ({}) => {
  const { dispatch } = useAppState();
  const styles = useStyles2(getStyles);

  const { MODALS, modals, user } = useModalForm();

  return (
    <div>
      {MODALS.map((modal) => {
        return (
          modal.isOpen && (
            <Modal
              key={`${modal.process}-${modal.index}`}
              className={styles.modalContainer}
              onClickBackdrop={() => undefined} // Avoid closing modal when clicking outside the modal window
              isOpen={modal.isOpen}
              title={`ASYSTOM WIZARD TOOL - ${user.orgName}`}
              onDismiss={() => closeModal(modals[modal.index].process, dispatch, false)}>
              <div className={styles.modal}>
                <>
                  <FormContainer title={modal.title} modalProcess={modal.process} />
                  <FooterLogo />
                </>
              </div>
            </Modal>
          )
        );
      })}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modalContainer: css`
      margin: auto;
      max-width: 1200px;
      min-width: 400px;
      top: 20px;
      width: 100%;
      height: 95%;
      max-height: 95%;
    `,
    modal: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `,
  };
};
