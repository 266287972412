import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { RED } from 'helpers/helpersStyle';
import React from 'react';

export const RedStarRequiredField: React.FunctionComponent = () => {
  const styles = useStyles2(getStyles);
  return <span className={styles.redStar}>*</span>;
};

const getStyles = () => {
  return {
    redStar: css`
      color: ${RED};
      padding: 1px;
    `,
  };
};
