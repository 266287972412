import {
  Orientation,
  TemplatesVar,
  Action,
  FormProps,
  IsoClassificationsProps,
  DashboardReceived,
} from 'app-context/types';
import { CLASS_TABLE, INCIDENT_ADVISOR_TXT } from 'helpers/constants';
import { TranslationProps } from 'app-context/translation-types';
import { API_DASHBOARD_BY_UID, API_SEARCH_DASHBOARDS } from 'helpers/URLS';
import { getBackendSrv } from '@grafana/runtime';

export const CALC_FAHRENHEIT = '* 9/5 + 32'; // convert temperature - celsius / fahrenheit
export const CALC_INCH = '/ 25.4'; // convert length - meter / inch

export const queryMaxInch = (beacon: string) =>
  `SELECT max(*) FROM (SELECT max(vib_x_f1) / 25.4,  max(vib_x_f2) / 25.4, max(vib_x_f3)/25.4, max(vib_y_f1) / 25.4,  max(vib_y_f2) / 25.4, max(vib_y_f3)/25.4, max(vib_z_f1) / 25.4, max(vib_z_f2) / 25.4, max(vib_z_f3)/25.4 FROM \"Signature\" WHERE \"device\"='${beacon}' AND $timeFilter)`;

export const queryMaxMillimeter = (beacon: string) =>
  `SELECT max(/vib_._f/) FROM \"Signature\" WHERE \"device\"='${beacon}' AND $timeFilter`;

const VIB_X_PANELID = 103;
const VIB_Y_PANELID = 105;
const VIBZ_PANEL_ID = 107;
const VIBRMS_PANEL_ID = 49;
const ALERT_VIB_ALARM_PANEL_ID = 139;
const ALERT_VIB_TRIP_PANEL_ID = 166;

const MAX_SCALE_ALIAS = 'MaxScale';

export const buildNewQuery = (query: string, splitElement: string, stringToAdd: string) => {
  const splitQuery = query.split(splitElement);
  const newQuery = stringToAdd
    ? `${splitQuery[0]?.trim()} ${stringToAdd?.trim()} ${splitQuery[1]?.trim()}`
    : `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`;
  return newQuery;
};

export const searchAndFillPanelIdCard = (panels: any[], idCardItems: any) => {
  const searchIncidentAdvisorPanel = (panel: { type: string }) => panel.type === INCIDENT_ADVISOR_TXT;

  let incidentAdvisorPanelIndex = panels.findIndex(searchIncidentAdvisorPanel);
  panels[incidentAdvisorPanelIndex].idCardElements = idCardItems;
  return panels;
};

/**
 * Transpose X, Y, Z to 'Vertical', 'Axial', 'Horizontal' or 'Axial-vertical'
 */
export const getOrientationValues = (form: FormProps[]) => {
  const orientationObj: Orientation = {
    X: { value: 'X', increment: 0 },
    Y: { value: 'Y', increment: 0 },
    Z: { value: 'Z', increment: 0 },
  };

  const indexOrientation = form.findIndex((formField) => formField.tableName === 'sensor_orientation');
  const splitValue = form[indexOrientation].value.split(' ');

  switch (splitValue[0]) {
    case 'X':
      orientationObj.X.value = 'Vertical';
      orientationObj.X.increment += 1;
      break;

    case 'Y':
      orientationObj.Y.value = 'Vertical';
      orientationObj.Y.increment += 1;
      break;

    case 'Z':
      orientationObj.Z.value = 'Vertical';
      orientationObj.Z.increment += 1;
      break;
  }

  switch (splitValue[2]) {
    case 'X':
      orientationObj.X.value = 'Axial';
      if (orientationObj.X.increment > 0) {
        orientationObj.X.value = 'Axial - Vertical';
      }
      orientationObj.X.increment += 1;
      break;

    case 'Y':
      orientationObj.Y.value = 'Axial';
      if (orientationObj.Y.increment > 0) {
        orientationObj.Y.value = 'Axial - Vertical';
      }
      orientationObj.Y.increment += 1;
      break;

    case 'Z':
      orientationObj.Z.value = 'Axial';
      if (orientationObj.Z.increment > 0) {
        orientationObj.Z.value = 'Axial - Vertical';
      }
      orientationObj.Z.increment += 1;
      break;
  }

  let countZero = 0;
  for (const [, value] of Object.entries(orientationObj)) {
    if (value.increment === 0) {
      countZero += 1;
    }
  }

  if (countZero === 1) {
    for (const [key, value] of Object.entries(orientationObj)) {
      if (value.increment === 0) {
        // @ts-ignore
        // TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
        orientationObj[key].value = 'Horizontal';
      }
    }
  }
  return orientationObj;
};

/**
 * Get vibration severity threshold depending on the power_class of the machine.
 */
export const getVibratoryThreshold = (
  currentClass: string,
  dictionary_uiElements: TranslationProps,
  lengthUnit: string,
  customThresholds: { alert: string; warning: string },
  isoClassifications: IsoClassificationsProps
) => {
  const {
    TR_A1,
    TR_A2,
    TR_A3,
    TR_A4,
    TR_B1,
    TR_B2,
    TR_B3,
    TR_B4,
    TR_class1,
    TR_class2,
    TR_class3,
    TR_class4,
    TR_customClass,
  } = dictionary_uiElements;

  const { vibrationSeverityIsoMms, vibrationSeverityIsoIns } = isoClassifications;

  let vibratoryThreshold = { alarm: 0, trip: 0 };

  switch (currentClass) {
    case TR_class1: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class1 : vibrationSeverityIsoIns.class1;
      break;
    }
    case TR_class2: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class2 : vibrationSeverityIsoIns.class2;
      break;
    }
    case TR_class3: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class3 : vibrationSeverityIsoIns.class3;
      break;
    }
    case TR_class4: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class4 : vibrationSeverityIsoIns.class4;
      break;
    }
    // ISO 20816
    case TR_A1: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a1 : vibrationSeverityIsoIns.a1;
      break;
    }
    case TR_A2: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a2 : vibrationSeverityIsoIns.a2;
      break;
    }
    case TR_A3: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a3 : vibrationSeverityIsoIns.a3;
      break;
    }
    case TR_A4: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a4 : vibrationSeverityIsoIns.a4;
      break;
    }
    case TR_B1: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b1 : vibrationSeverityIsoIns.b1;
      break;
    }
    case TR_B2: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b2 : vibrationSeverityIsoIns.b2;
      break;
    }
    case TR_B3: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b3 : vibrationSeverityIsoIns.b3;
      break;
    }
    case TR_B4: {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b4 : vibrationSeverityIsoIns.b4;
      break;
    }
    case TR_customClass: {
      vibratoryThreshold = {
        alarm: parseFloat(customThresholds.warning),
        trip: parseFloat(customThresholds.alert),
      };
      break;
    }
    default:
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.default : vibrationSeverityIsoIns.default;
  }
  return vibratoryThreshold;
};

/**
 * Use in the deletion and update processes.
 * Get uid, template image, dashboard language, creation and update dates.
 */
export const getDashboardInfo = async (
  e: { label: string; folderId: number },
  dispatch: (value: Action) => void,
  setMetaDashboard: (value: { created: string; updated: string }) => void,
  isDeleteStep: boolean,
  AMBIENT_TEMPERATURE: string,
  customClass: string
) => {
  await getBackendSrv()
    .get(`${API_SEARCH_DASHBOARDS}?folderIds=${e.folderId}`)
    .then((result) =>
      result.map(async (dashboard: { title: string; uid: string; templating: { list: any }; type: string }) => {
        if (dashboard.type === 'dash-folder') {
          return;
        }
        if (dashboard.title === e.label) {
          dispatch({
            type: 'ADD_UID',
            payload: dashboard.uid,
          });

          await getBackendSrv()
            .get(`${API_DASHBOARD_BY_UID}${dashboard.uid}`)
            .then((result) => {
              if (!result) {
                return;
              }
              if (!isDeleteStep) {
                // Retrieve image name
                const img = result?.dashboard?.templating?.list?.filter(
                  (template: { name: string }) => template.name === TemplatesVar.image
                );
                dispatch({
                  type: 'ADD_FILE_NAME',
                  payload: img[0].query,
                });

                // Retrieve dashboard language
                const langVar = result?.dashboard?.templating?.list?.filter(
                  (template: { name: string }) => template.name === TemplatesVar.lang
                );
                dispatch({
                  type: 'ADD_LANG',
                  payload: langVar[0].query,
                });

                // Retrieve dashboard LENGTH UNIT
                const lengthUnit = result?.dashboard?.templating?.list?.filter(
                  (template: { name: string }) => template.name === TemplatesVar.lengthUnit
                );

                if (lengthUnit.length) {
                  dispatch({
                    type: 'SET_LENGTH_UNIT',
                    payload: lengthUnit[0].query,
                  });
                } else {
                  dispatch({
                    type: 'SET_LENGTH_UNIT',
                    payload: 'meter',
                  });
                }

                // Retrieve folder info
                dispatch({
                  type: 'ADD_DASHBOARD_FOLDER',
                  payload: {
                    id: result?.meta.folderId,
                    name: result?.meta.folderTitle,
                  },
                });

                // Get Temperature unit
                let unit = getTemperatureUnitInPanels(result?.dashboard?.panels, AMBIENT_TEMPERATURE);
                let temperatureUnit = getTemperatureUnit(unit);
                dispatch({ type: 'SET_TEMPERATURE_UNIT', payload: temperatureUnit });

                // get custom thresholds
                const searchIncidentAdvisorPanel = (panel: { type: string }) => panel.type === INCIDENT_ADVISOR_TXT;
                let incidentAdvisorPanelIndex = result?.dashboard?.panels.findIndex(searchIncidentAdvisorPanel);
                if (incidentAdvisorPanelIndex !== -1) {
                  const indexClass = result?.dashboard?.panels[incidentAdvisorPanelIndex].idCardElements.findIndex(
                    (e: { field: string }) => e.field === CLASS_TABLE
                  );
                  if (indexClass !== -1) {
                    if (
                      result?.dashboard?.panels[incidentAdvisorPanelIndex].idCardElements[indexClass].value ===
                      customClass
                    ) {
                      const indexCustomIso = result?.dashboard?.panels[
                        incidentAdvisorPanelIndex
                      ].idCardElements.findIndex((e: { field: string }) => e.field === 'customIso');
                      if (indexCustomIso !== -1) {
                        const thresholdsArray =
                          result?.dashboard?.panels[incidentAdvisorPanelIndex].idCardElements[indexCustomIso].value;
                        dispatch({
                          type: 'SET_CUSTOM_ISO',
                          payload: { warning: thresholdsArray[0], alert: thresholdsArray[1] },
                        });
                      }
                    }
                  }
                }
              } else {
                // Retrieve creation and updates dates (needed for deletion feature, just for display)
                setMetaDashboard({ created: result?.meta.created, updated: result.meta.updated });
              }
            });
        }
      })
    );
};

export const getTemperatureUnit = (unit: string) => {
  let temperatureUnit = '';

  switch (unit) {
    case 'celsius':
      temperatureUnit = 'Celsius (°C)';
      break;
    case 'fahrenheit':
      temperatureUnit = 'Fahrenheit (°F)';
      break;
    case 'Fahrenheit (°F)':
      temperatureUnit = 'fahrenheit';
      break;
    case 'Celsius (°C)':
      temperatureUnit = 'celsius';
      break;
    default:
      break;
  }
  return temperatureUnit;
};

/** Return the first temperature unit found in panel */
export const getTemperatureUnitInPanels = (panels: any, AMBIENT_TEMPERATURE: string) => {
  let unit = '';
  panels.forEach(
    (panel: { title: string; panels: any[]; collapsed: boolean; fieldConfig: { defaults: { unit: string } } }) => {
      // if the panel is collapsed (the section) then all the plugins are in panel.panels
      if (panel.collapsed && panel.panels.length) {
        const temperaturePanelIndex = panel.panels.findIndex(
          (panelCollapsed: { title: string }) =>
            panelCollapsed.title?.toUpperCase() === AMBIENT_TEMPERATURE?.toUpperCase()
        );
        if (temperaturePanelIndex !== -1) {
          unit = panel.panels[temperaturePanelIndex]?.fieldConfig.defaults.unit;
        }
      } else {
        if (panel.title?.toUpperCase() === AMBIENT_TEMPERATURE?.toUpperCase()) {
          unit = panel?.fieldConfig.defaults.unit;
        }
      }
    }
  );
  return unit;
};

export const updateTemperatureUnitPanels = (
  dashboard: any,
  temperatureUnit: string,
  AMBIENT_TEMPERATURE: string,
  SURFACE_TEMPERATURE: string,
  ALIAS_AMBIENT_TEMP: string
) => {
  if (!dashboard) {
    return;
  }
  /** Inject units (temperature) */
  let dashboardToUpdateTempUnit = getTemperatureUnitInPanels(dashboard.panels, AMBIENT_TEMPERATURE);
  let unitTemperatureUserChoice = getTemperatureUnit(temperatureUnit);

  const AMBIENT_TEMPERATURE_TEXT = AMBIENT_TEMPERATURE?.toUpperCase();
  const SURFACE_TEMPERATURE_TEXT = SURFACE_TEMPERATURE?.toUpperCase();

  if (dashboardToUpdateTempUnit !== unitTemperatureUserChoice) {
    dashboard.panels?.forEach(
      (panel: {
        type: string;
        targets: any[];
        panels: any[];
        collapsed: boolean;
        title: string;
        fieldConfig: { defaults: { unit: string; max: number | null } };
      }) => {
        if (panel.type === INCIDENT_ADVISOR_TXT) {
          panel.targets?.forEach((target: { alias: string; query: string }) => {
            /** Update Anomaly detector temperature widget */
            if (target?.alias?.toUpperCase() === ALIAS_AMBIENT_TEMP?.toUpperCase()) {
              if (unitTemperatureUserChoice === 'fahrenheit') {
                if (!target.query?.includes(CALC_FAHRENHEIT)) {
                  target.query = buildNewQuery(target.query, 'FROM', `${CALC_FAHRENHEIT} FROM`);
                }
              } else {
                target.query = target.query.replace(`${CALC_FAHRENHEIT} `, '');
              }
            }
          });
        }

        if (panel?.collapsed && panel.panels.length) {
          panel.panels.forEach(
            (panelRow: {
              title: string;
              targets: any[];
              fieldConfig: { defaults: { unit: string; max: number | null } };
            }) => {
              if (
                panelRow.title?.toUpperCase() === AMBIENT_TEMPERATURE_TEXT ||
                panelRow.title?.toUpperCase() === SURFACE_TEMPERATURE_TEXT
              ) {
                panelRow.fieldConfig.defaults.unit = unitTemperatureUserChoice;
                panelRow.fieldConfig.defaults.max = null;

                if (panelRow.targets) {
                  panelRow.targets.forEach((target: { query: string }) => {
                    let pattern =
                      dashboardToUpdateTempUnit === 'celsius' && !target.query?.includes(CALC_FAHRENHEIT)
                        ? 'FROM'
                        : CALC_FAHRENHEIT;

                    if (dashboardToUpdateTempUnit === 'celsius' && pattern === CALC_FAHRENHEIT) {
                      target.query = target.query?.replace(CALC_FAHRENHEIT, '');
                      pattern = 'FROM';
                    }
                    // Modify temperature queries
                    const splitQuery = target.query?.split(pattern);
                    const newQuery =
                      unitTemperatureUserChoice === 'celsius'
                        ? `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`
                        : `${splitQuery[0]?.trim()} ${CALC_FAHRENHEIT} FROM ${splitQuery[1]?.trim()}`;
                    target.query = newQuery;
                  });
                }
              }
            }
          );
        } else {
          if (
            panel.title?.toUpperCase() === AMBIENT_TEMPERATURE_TEXT ||
            panel.title?.toUpperCase() === SURFACE_TEMPERATURE_TEXT
          ) {
            panel.fieldConfig.defaults.unit = unitTemperatureUserChoice;
            panel.fieldConfig.defaults.max = null;
            if (panel.targets) {
              panel.targets.forEach((target: { query: string }) => {
                let pattern =
                  dashboardToUpdateTempUnit === 'celsius' && !target.query?.includes(CALC_FAHRENHEIT)
                    ? 'FROM'
                    : CALC_FAHRENHEIT;

                if (dashboardToUpdateTempUnit === 'celsius' && pattern === CALC_FAHRENHEIT) {
                  target.query = target.query?.replace(CALC_FAHRENHEIT, '');
                  pattern = 'FROM';
                }

                // Modify temperature queries
                const splitQuery = target.query?.split(pattern);
                const newQuery =
                  unitTemperatureUserChoice === 'celsius'
                    ? `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`
                    : `${splitQuery[0]?.trim()} ${CALC_FAHRENHEIT} FROM ${splitQuery[1]?.trim()}`;
                target.query = newQuery;
              });
            }
          }
        }
      }
    );
  }
};

/**
 * List all the panels that contain length unit (in mm/s or in/s).
 * Panel id needs to be added in this list if it contains length unit.
 */

export const isPanelWithLengthUnit = (panelId: number) =>
  panelId === VIB_X_PANELID ||
  panelId === VIB_Y_PANELID ||
  panelId === VIBZ_PANEL_ID ||
  panelId === VIBRMS_PANEL_ID ||
  panelId === ALERT_VIB_ALARM_PANEL_ID ||
  panelId === ALERT_VIB_TRIP_PANEL_ID;

export const getLengthVariable = (dashboard: { templating: { list: any[] } }) => {
  const varLength = dashboard.templating.list.filter((variable) => variable.name === TemplatesVar.lengthUnit);
  if (varLength.length) {
    return varLength[0].query;
  }
  return 'meter';
};

export const updateLengthUnitPanels = (
  dashboard: any,
  macAddress: string,
  newLengthUnit: string,
  ALIAS_VIBRATION_SEVERITY: string
) => {
  if (!dashboard) {
    return;
  }

  /** Inject units (length) */
  let oldLengthUnit = getLengthVariable(dashboard);

  if (oldLengthUnit !== newLengthUnit) {
    dashboard.panels?.forEach(
      (panel: {
        type: string;
        targets: any[];
        panels: any[];
        collapsed: boolean;
        title: string;
        fieldConfig: any;
        id: number;
      }) => {
        const changeLengthUnit = isPanelWithLengthUnit(panel.id);

        if (panel.type === INCIDENT_ADVISOR_TXT) {
          panel.targets?.forEach((target: { alias: string; query: string }) => {
            /** Update Anomaly detector temperature widget */
            if (target?.alias?.toUpperCase() === ALIAS_VIBRATION_SEVERITY?.toUpperCase()) {
              // Prevent conversion error. Here, no need to change query
              if (oldLengthUnit === 'inch' && !target.query?.includes(CALC_INCH)) {
                return;
              }
              // Prevent conversion error
              if (oldLengthUnit === 'meter' && target.query?.includes(CALC_INCH)) {
                target.query = target.query?.replace(CALC_INCH, '');
              }

              if (newLengthUnit === 'inch' && !target.query?.includes(CALC_INCH)) {
                target.query = buildNewQuery(target.query, 'FROM', `${CALC_INCH} FROM`);
              } else {
                target.query = target.query.replace(`${CALC_INCH} `, '');
              }
            }
          });
        }

        if (panel?.collapsed && panel.panels.length) {
          panel.panels.forEach((panelRow: { title: string; targets: any[]; fieldConfig: any; id: number }) => {
            const changeLengthUnit = isPanelWithLengthUnit(panelRow.id);

            if (changeLengthUnit) {
              panelRow.fieldConfig.defaults.custom.axisLabel = newLengthUnit === 'inch' ? 'in/s' : 'mm/s';

              if (panelRow.targets) {
                panelRow.targets.forEach((target: { query: string; alias: string }) => {
                  if (target.alias === MAX_SCALE_ALIAS) {
                    target.query = newLengthUnit === 'inch' ? queryMaxInch(macAddress) : queryMaxMillimeter(macAddress);
                  } else {
                    // "pattern" is used to split the string.
                    // If old length unit is meter, the split element is FROM
                    // otherwise, it's the math. inch conversion "/ 25.4"
                    let pattern = oldLengthUnit === 'meter' ? 'FROM' : CALC_INCH;
                    // Prevent conversion error. Here, no need to change query
                    if (oldLengthUnit === 'inch' && !target.query?.includes(CALC_INCH)) {
                      return;
                    }
                    // Prevent conversion error
                    if (oldLengthUnit === 'meter' && target.query?.includes(CALC_INCH)) {
                      target.query = target.query?.replace(CALC_INCH, '');
                      pattern = 'FROM';
                    }
                    // Modify queries with length unit
                    const splitQuery = target.query?.split(pattern);
                    const newQuery =
                      newLengthUnit === 'meter'
                        ? `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`
                        : `${splitQuery[0]?.trim()} ${CALC_INCH} FROM ${splitQuery[1]?.trim()}`;
                    target.query = newQuery;
                  }
                });
              }
            }
          });
        } else {
          if (changeLengthUnit) {
            panel.fieldConfig.defaults.custom.axisLabel = newLengthUnit === 'inch' ? 'in/s' : 'mm/s';
            if (panel.targets) {
              panel.targets.forEach((target: { query: string; alias: string }) => {
                if (target.alias === MAX_SCALE_ALIAS) {
                  target.query = newLengthUnit === 'inch' ? queryMaxInch(macAddress) : queryMaxMillimeter(macAddress);
                } else {
                  let pattern = oldLengthUnit === 'meter' ? 'FROM' : CALC_INCH;
                  // Prevent conversion error. Here, no need to change query
                  if (oldLengthUnit === 'inch' && !target.query?.includes(CALC_INCH)) {
                    return;
                  }
                  // Prevent conversion error
                  if (oldLengthUnit === 'meter' && target.query?.includes(CALC_INCH)) {
                    target.query = target.query?.replace(CALC_INCH, '');
                    pattern = 'FROM';
                  }

                  // Modify temperature queries
                  const splitQuery = target.query?.split(pattern);
                  const newQuery =
                    newLengthUnit === 'meter'
                      ? `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`
                      : `${splitQuery[0]?.trim()} ${CALC_INCH} FROM ${splitQuery[1]?.trim()}`;
                  target.query = newQuery;
                }
              });
            }
          }
        }
      }
    );
  }
};

// Function used to create the object of new dashboard in Manage Single pages process (admin only)
export const getNewDashboardObject = (tag: string, title: string, lang: string) => {
  return {
    id: null,
    isStarred: false,
    slug: '',
    tags: [tag],
    templatesVariables: [],
    title: title,
    type: 'dash-db',
    uid: '',
    uri: '',
    url: '',
    lang: lang,
  };
};

/**  injectuserTemplates() is only used by the "Manage Single Pages" feature.
 * It allows to reinject the user filter selection in the Overview Dashboard.
 */
export const injectUserTemplates = (oldDashboard: DashboardReceived, newDashboard: any, variableName: string) => {
  const variableIndex = oldDashboard.templatesVariables.findIndex((template) => template.name === variableName);

  if (variableIndex !== -1) {
    const variableIndexInNewDashboard = newDashboard.templating.list.findIndex(
      (template: { name: string }) => template.name === variableName
    );
    if (variableIndexInNewDashboard !== -1) {
      newDashboard.templating.list[variableIndexInNewDashboard].current.text =
        oldDashboard.templatesVariables[variableIndex].query;
      newDashboard.templating.list[variableIndexInNewDashboard].current.value =
        oldDashboard.templatesVariables[variableIndex].query;
      newDashboard.templating.list[variableIndexInNewDashboard].query =
        oldDashboard.templatesVariables[variableIndex].query;
    }
  }
};
