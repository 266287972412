import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { CLASS_TABLE, TAGS_TABLE } from 'helpers/constants';
import React from 'react';
import { ISO_DOC_EN, ISO_DOC_FR } from 'helpers/URLS';

interface TooltipFieldProps {
  tableName: string;
}

export const TooltipField: React.FunctionComponent<TooltipFieldProps> = ({ tableName }) => {
  const { state } = useAppState();
  const { dictionary_uiElements, lang } = state;
  const styles = useStyles2(getStyles);

  const { TR_classDescription, TR_tagsInfo } = dictionary_uiElements;

  const ISO_DOC_URL = lang.toUpperCase() === 'FR' ? ISO_DOC_FR : ISO_DOC_EN;

  const toolTipContent = (tableName: string) => {
    if (tableName === CLASS_TABLE) {
      return (
        <div className={styles.descriptionContainer}>
          <div className={styles.listElement}>
            {TR_classDescription}
            <a href={ISO_DOC_URL} target="_blank" rel="noreferrer noopener">
              ISO classifications
            </a>
          </div>
        </div>
      );
    }

    if (tableName === TAGS_TABLE) {
      return (
        <div className={styles.descriptionContainer}>
          <div>{TR_tagsInfo}</div>
        </div>
      );
    }
    return '';
  };

  return (
    <Tooltip content={toolTipContent(tableName)} interactive>
      <Icon name="info-circle"></Icon>
    </Tooltip>
  );
};

const getStyles = () => {
  return {
    listElement: css`
      margin-left: 15px;
    `,
    descriptionContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: self-start;
    `,
  };
};
