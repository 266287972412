import { getBackendSrv } from '@grafana/runtime';
import { URL_DASHBOARD_TEMPLATES } from 'helpers/URLS';
import { DashboardTemplateType } from 'app-context/types';

/**
 * Get template dashboards from :
 * - public/asystom/asystom-dashboards/comparison-dashboard_*.js
 * - public/asystom/asystom-dashboards/main-dashboard_*.js
 * - public/asystom/asystom-dashboards/synthetic-dashboard_*.js
 */

export const getTemplateDashboard = async (template: DashboardTemplateType, lang: string) => {
  const results = await getBackendSrv()
    .get(`${URL_DASHBOARD_TEMPLATES}${template}_${lang}.json`)
    .catch((e) => {
      console.log(`Error while getting dashboard template ${template}`, e);
    });
  return results;
};
