import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export interface SectionTitleProps {
  title: string;
}

export const SectionTitle: React.FunctionComponent<SectionTitleProps> = ({ title }) => {
  const styles = useStyles2(getStyles);
  return <div className={styles.sectionTitle}>{title}</div>;
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    sectionTitle: css`
      font-size: 18px;
      color: ${theme.colors.text};
      font-weight: 500;
      text-align: left;
      width: 100%;
      padding: 5px 0;
      margin-bottom: 6px;
    `,
  };
};
