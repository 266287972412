import { TranslationProps } from '../app-context/translation-types';
import {
  ANALYSIS_PROFILE_TABLE,
  CLASS_TABLE,
  FUNCTION_TABLE,
  MAC_ADDRESS_TABLE,
  MACHINE_FILTER_TABLE,
  MACHINE_NAME_TABLE,
  MANUFACTURER_TABLE,
  MODEL_TABLE,
  SENSOR_LOCATION_TABLE,
  SENSOR_ORIENTATION_TABLE,
  TAGS_TABLE,
  TYPE_TABLE,
} from '../helpers/constants';

import { CurrentStep } from '../app-context/types';

/**
 * Structure of the different steps and their required fields
 * Note: step98 (Manage single pages) is not included in this array beacause it doesn't require form field.
 */

export const formSteps = (dictionary_uiElements: TranslationProps) => {
  const {
    TR_analysisProfileField,
    TR_classField,
    TR_functionField,
    TR_macAddressField,
    TR_machineFilterField,
    TR_machineNameField,
    TR_machinePartField,
    TR_manufacturerField,
    TR_modelField,
    TR_sectionTitleStep0,
    TR_sectionTitleStep1,
    TR_sectionTitleStep2,
    TR_sectionTitleStep3,
    TR_sectionTitleStep99,
    TR_sectionTitleSummaryStep,
    TR_sensorLocationField,
    TR_sensorOrientationField,
    TR_tagsField,
  } = dictionary_uiElements;

  return [
    {
      /**
       * Update - first step
       */
      step0: {
        id: CurrentStep.UPDATE_STEP1,
        sectionTitle: TR_sectionTitleStep0,
        fields: [{ name: TR_machineNameField, isRequired: true, tableName: MACHINE_NAME_TABLE }],
      },

      /**
       * General Infos ( Creation - first step / Update - second step )
       */
      step1: {
        id: CurrentStep.GENERAL_INFOS,
        sectionTitle: TR_sectionTitleStep1,
        fields: [
          { name: TR_machineNameField, isRequired: true, tableName: MACHINE_NAME_TABLE },
          { name: TR_macAddressField, isRequired: true, tableName: MAC_ADDRESS_TABLE },
          { name: TR_functionField, isRequired: true, tableName: FUNCTION_TABLE },
          { name: TR_classField, isRequired: true, tableName: CLASS_TABLE },
          { name: TR_manufacturerField, isRequired: false, tableName: MANUFACTURER_TABLE },
          { name: TR_modelField, isRequired: false, tableName: MODEL_TABLE },
          { name: TR_machineFilterField, isRequired: false, tableName: MACHINE_FILTER_TABLE },
          { name: TR_tagsField, isRequired: false, tableName: TAGS_TABLE },
        ],
      },
      /**
       * Mounting location
       */
      step2: {
        id: CurrentStep.MOUNTING_LOCATION,
        sectionTitle: TR_sectionTitleStep2,
        fields: [
          { name: TR_machinePartField, isRequired: true, tableName: TYPE_TABLE },
          // { name: TR_typeField, isRequired: false, tableName: 'subtype' },
          { name: TR_sensorLocationField, isRequired: false, tableName: SENSOR_LOCATION_TABLE },
          { name: TR_sensorOrientationField, isRequired: true, tableName: SENSOR_ORIENTATION_TABLE },
        ],
      },
      /**
       * Analysis profile
       */
      step3: {
        id: CurrentStep.ANALYSIS_PROFILE,
        sectionTitle: TR_sectionTitleStep3,
        fields: [{ name: TR_analysisProfileField, isRequired: true, tableName: ANALYSIS_PROFILE_TABLE }],
      },
      /**
       * Form Summary
       */
      summary: {
        id: CurrentStep.SUMMARY,
        sectionTitle: TR_sectionTitleSummaryStep,
        fields: [
          // Mac address
          { name: TR_macAddressField, isRequired: true, tableName: MAC_ADDRESS_TABLE },
          // Machine name
          { name: TR_machineNameField, isRequired: true, tableName: MACHINE_NAME_TABLE },
          // Function
          { name: TR_functionField, isRequired: true, tableName: FUNCTION_TABLE },
          // Power Class
          { name: TR_classField, isRequired: true, tableName: CLASS_TABLE },
          // Manufacturer
          { name: TR_manufacturerField, isRequired: false, tableName: MANUFACTURER_TABLE },
          // Model
          { name: TR_modelField, isRequired: false, tableName: MODEL_TABLE },
          // Type
          { name: TR_machinePartField, isRequired: true, tableName: TYPE_TABLE },
          // Location
          { name: TR_sensorLocationField, isRequired: false, tableName: SENSOR_LOCATION_TABLE },
          // Orientation
          { name: TR_sensorOrientationField, isRequired: true, tableName: SENSOR_ORIENTATION_TABLE },
          // Analysis profile
          { name: TR_analysisProfileField, isRequired: true, tableName: ANALYSIS_PROFILE_TABLE },
        ],
      },
      /**
       * First and unique step of deletion
       */
      step99: {
        id: CurrentStep.DELETE_STEP1,
        sectionTitle: TR_sectionTitleStep99,
        fields: [
          // Dashboard Name
          { name: TR_machineNameField, isRequired: true, tableName: MACHINE_NAME_TABLE },
        ],
      },
      /**
       * First and unique step of manage single pages
       */
      step98: {
        id: CurrentStep.MANAGE_SINGLE_PAGES_STEP1,
        sectionTitle: 'MANAGE SINGLE PAGES',
        fields: [
          // Dashboard Name
          { name: TR_machineNameField, isRequired: true, tableName: MACHINE_NAME_TABLE },
        ],
      },
    },
  ];
};
