import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { Process, CurrentStep } from 'app-context/types';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import React from 'react';
import { Step0, Step1, Step2, Step3, Summary, Validation, Step98 } from '../steps';
import { Footer } from './FormFooter';
import { Header } from './FormHeader';

export interface ContainerFormProps {
  title: string;
  modalProcess: Process;
}
/**
 * Contains all the steps of the form
 */

const FormContainer: React.FunctionComponent<ContainerFormProps> = ({ title, modalProcess }) => {
  const { state } = useAppState();
  const { steps, user } = state;
  const { current } = steps;
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.containerForm}>
      <Header title={title} modalProcess={modalProcess} />
      <div className={styles.form}>
        {/* First step of update and unique step of deletion */}
        {(current === CurrentStep.UPDATE_STEP1 || current === CurrentStep.DELETE_STEP1) && <Step0 />}
        {/* First step of creation and second step of update - General infos */}
        {current === CurrentStep.GENERAL_INFOS && <Step1 />}
        {/* Next steps of update and creation - Mounting location */}
        {current === CurrentStep.MOUNTING_LOCATION && <Step2 />}
        {/* Next steps of update and creation - Analysis profile */}
        {current === CurrentStep.ANALYSIS_PROFILE && <Step3 />}
        {/* Next steps of update and creation - Summary of form entries */}
        {current === CurrentStep.SUMMARY && <Summary />}
        {/* Next steps of update and creation - Final form step - Confirmation */}
        {current === CurrentStep.VALIDATION && <Validation />}
        {/* First and unique step of manage single pages */}
        {current === CurrentStep.MANAGE_SINGLE_PAGES_STEP1 && (user.orgRole === 'Admin' || user.isGrafanaAdmin) && (
          <Step98 />
        )}
      </div>
      <div className={styles.footer}>
        <Footer process={modalProcess} />
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    containerForm: css`
      width: 100%;
      /* max-width: 960px; */
      min-width: 270px;
      margin: auto;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
    `,
    form: css`
      width: 100%;
      min-height: 510px;
      max-width: 1200px;
      padding: 10px;
      overflow: auto;
    `,
    footer: css`
      width: 80%;
      margin: auto;
    `,
    fixedDataContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 10px;
    `,
    fixedData: css`
      font-size: 12px;
      font-weight: 500;
      color: #b1b1b1;
      margin-left: 34px;
    `,
  };
};
export default FormContainer;
