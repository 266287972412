import { PanelPlugin } from '@grafana/data';
import { SimplePanel } from './SimplePanel';
import { HCsrc } from './help-center/help-center-script';

interface SimpleOptions {}

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder.addTextInput({
    path: 'helpCenterSrc',
    name: 'Help Center src',
    description: 'Paste help center src',
    defaultValue: HCsrc,
  });
});
