import React, { PropsWithChildren } from 'react';
import { withData } from './withData';
import { appStateReducer } from './AppReducer';
import { AppStateContextProps, AppState } from './types';

const AppStateContext = React.createContext<AppStateContextProps>({} as AppStateContextProps);

export const AppStateProvider = withData(
  ({ children, initialState }: PropsWithChildren<{ initialState: AppState }>) => {
    const [state, dispatch] = React.useReducer(appStateReducer, initialState);

    return <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>;
  }
);

export const useAppState = () => {
  return React.useContext(AppStateContext);
};
