import { SelectableValue, GrafanaTheme2 } from '@grafana/data';
import { Icon, Select, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { ErrorTypes, Process } from 'app-context/types';
import { css } from '@emotion/css';
import { addToForm, getUserFolders, isInputInvalid, setError } from 'helpers/helpersForm';
import { formContainerStyle } from 'helpers/helpersStyle';
import React, { useEffect, useState } from 'react';
import { SectionTitle } from 'shared';
import { DisplayErrors } from 'shared/components/DisplayErrors';
import { formSteps } from '../../formSteps';
import { FormField } from './FormFieldStep1';
import { UnitsForm } from './UnitsForm';
/**
 * FORM STEP 1 - Step 2 of update (after dashboard selection) - Step 1 of creation
 * General information
 * @fields :
 * - folder (creation only),
 * - machine_ name,
 * - mac_address,
 * - process_function,
 * - power_class,
 * - manufacturer,
 * - model,
 * - machine tag
 * - additional tags
 * - temperature
 */

export const Step1: React.FunctionComponent = () => {
  const { state, dispatch } = useAppState();
  const { currentModal, dashboardFolder, dictionary_uiElements, error, steps, form, customIso } = state;
  const { TR_folder, TR_folderPlaceholder, TR_folderNotFound } = dictionary_uiElements;
  const [folders, setFolders] = useState<SelectableValue[]>([]);

  const styles = useStyles2(getStyles);
  const formStep = formSteps(dictionary_uiElements);
  const { step1 } = formStep[0];
  const [currentStepsList, setCurrentStepslist] = useState(step1);
  const isSubmit = steps.stepsStatus.step1?.isSubmit;

  /** Get user folders */
  const getFolders = async () => {
    try {
      const folders = await getUserFolders();
      setFolders(folders);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFolders = (e: SelectableValue) => {
    if (!e) {
      return;
    }

    dispatch({
      type: 'ADD_DASHBOARD_FOLDER',
      payload: {
        id: e.value,
        name: e.label as string,
      },
    });
  };

  const resetError = () => {
    setError(ErrorTypes.macAddressDashboardDeleted, '', dispatch);
  };

  const abortCreate = () => {
    addToForm(dispatch, '', dictionary_uiElements.TR_macAddressField, 'mac_address');
    resetError();
  };

  /** Set General folder by default on creation */

  useEffect(() => {
    if (currentModal === Process.create) {
      getFolders();
      if (!Object.keys(dashboardFolder).length) {
        dispatch({
          type: 'ADD_DASHBOARD_FOLDER',
          payload: {
            id: 0,
            name: 'General',
          },
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      {/* Error section */}
      <DisplayErrors
        error={error}
        dictionary_uiElements={dictionary_uiElements}
        continueCreate={resetError}
        abortCreate={abortCreate}
      />
      {/* Create and update form fields (share same ui except that mac address fields are disabled on update)*/}
      <div className={styles.formContainer}>
        {!error.datasource && (
          <form>
            {/* General info section. Mac address, dashboard name and folder on creation only */}
            <SectionTitle title={step1.sectionTitle} />
            {currentModal === Process.create && (
              <div className={styles.selectContainer}>
                {/* Folder list */}
                <div className={styles.label}>{TR_folder?.toUpperCase()}</div>
                <div className={styles.inputContainer}>
                  <Select
                    width={50}
                    options={folders}
                    value={dashboardFolder.id}
                    onChange={(e) => handleChangeFolders(e)}
                    placeholder={TR_folderPlaceholder}
                    noOptionsMessage={TR_folderNotFound}
                    prefix={<Icon name={'folder-open'} />}
                  />
                </div>
              </div>
            )}
            {currentStepsList.fields
              .filter((field, index: number) => index < 2)
              .map((field: any) => {
                return (
                  <FormField
                    name={field.name}
                    tableName={field.tableName}
                    isRequired={field.isRequired}
                    key={field.name}
                    isInvalid={isInputInvalid(
                      field.name,
                      step1,
                      isSubmit,
                      form,
                      error,
                      customIso,
                      dictionary_uiElements
                    )}
                    isSubmit={isSubmit}
                    setCurrentStepslist={setCurrentStepslist}
                    currentStepsList={currentStepsList}
                  />
                );
              })}
            {/* Machine info section. Process function, power class, manufacturer, model */}
            <SectionTitle title={'Machine'} />
            {currentStepsList.fields
              .filter((field, index) => index > 1)
              .map((field: any) => {
                return (
                  <FormField
                    name={field.name}
                    tableName={field.tableName}
                    isRequired={field.isRequired}
                    key={field.name}
                    isInvalid={isInputInvalid(
                      field.name,
                      step1,
                      isSubmit,
                      form,
                      error,
                      customIso,
                      dictionary_uiElements
                    )}
                    isSubmit={isSubmit}
                    setCurrentStepslist={setCurrentStepslist}
                    currentStepsList={currentStepsList}
                  />
                );
              })}
            <UnitsForm />
          </form>
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    `,
    formContainer: css`
      width: 100%;
      display: flex;
      justify-content: center;
    `,
    input: css`
      background-color: #ffffff;
      border-radius: 2px;
      border: 1px solid #c7d0d9;
      color: #464c54;
      font-size: 14px;
      height: 32px;
      line-height: 1.5;
      padding: 0 8px 0 8px;
      position: relative;
      width: 100%;
      &:focus {
        border: 2px solid #589ce0;
        border-radius: 6px;
      }
    `,
    label: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text};
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      margin-right: 10px;
      max-width: 250px;
      min-width: 225px;
      padding: 7px;
      text-align: left;
      text-justify: center;
      width: 30%;
    `,
    inputContainer: css`
      width: 100%;
    `,
    selectContainer: css`
      ${formContainerStyle}
    `,
    buttonWithIcon: css`
      display: flex;
      flex-direction: row;
      padding: 4px;
      border-radius: 5px;
      border: 1px solid rgba(237, 241, 241, 0);
    `,
    buttonsGroup: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
    `,
  };
};
