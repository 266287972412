import { Button, Icon, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import * as React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { RED } from 'helpers/helpersStyle';

interface Props {
  dashboardName: string;
  deleteDashboard: (e: any) => void;
}
/**
 * Display text of deletion confirmation after dashboard selection
 */

export const ConfirmDeleteTextContainer: React.FunctionComponent<Props> = ({ dashboardName, deleteDashboard }) => {
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  const styles = useStyles2(getStyles);

  const { TR_confirmDelete, TR_irreversibleAction, TR_delete } = dictionary_uiElements;

  return (
    <div style={{ marginTop: '50px' }}>
      <div className={styles.errorContainer}>
        <div className={styles.errorText}>
          <div style={{ color: `${RED}` }}>
            <div>
              {TR_confirmDelete} <u>{dashboardName?.toUpperCase()}</u>?
            </div>
          </div>
          <div style={{ color: `${RED}` }}>
            <Icon name="exclamation-triangle" size="lg" /> {TR_irreversibleAction}{' '}
            <Icon name="exclamation-triangle" size="lg" />
          </div>
        </div>
        <Button className={styles.buttonDelete} onClick={deleteDashboard}>
          {TR_delete}
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    containerField: css`
      width: 100%;
      max-width: 600px;
      min-width: 300px;
      height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
    `,
    label: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text};
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      margin-right: 10px;
      max-width: 250px;
      min-width: 180px;
      padding: 7px;
      text-align: left;
      text-justify: center;
      width: 30%;
    `,
    inputContainer: css`
      width: 100%;
    `,
    listElement: css`
      margin-left: 15px;
    `,
    descriptionContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: self-start;
    `,
    errorText: css`
      color: ${theme.colors.text};
      font-weight: 500;
      padding: 10px;
      font-size: 16px;
      text-align: center;
    `,
    errorContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    buttonDelete: css`
      min-width: 125px;
      background: ${RED};
      padding: 0 28px;
      height: 45px;
      border: none;
      color: white;
      font-size: 18px;
      font-weight: 500;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #e76750;
        color: white;
      }
    `,
  };
};
