import * as React from 'react';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';

export const FooterLogo: React.FunctionComponent = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.footerLogo}>
      <img src="public/asystom/asystom-footer-logo.png" height="40px" width="auto" />
    </div>
  );
};

const getStyles = () => {
  return {
    footerLogo: css`
      margin: 15px auto 0 auto;
    `,
  };
};
