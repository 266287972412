import { getBackendSrv } from '@grafana/runtime';
import { Input, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { ErrorTypes, Process } from 'app-context/types';
import { css } from '@emotion/css';
import { addToForm, findIndexInForm, setError } from 'helpers/helpersForm';
import { API_QUERY_DASHBOARD } from 'helpers/URLS';
import React, { useEffect, useState } from 'react';
import { mysqlRequest } from 'services/requests';
import { EDITABLE_MAC_ADDRESS_LENGTH, MAC_ADRESS_PREFIX } from 'helpers/constants';

export interface MultiFieldsProps {
  fieldName: string;
  tableName: string;
  isSubmit: boolean | undefined;
}

interface StateProps {
  1: { value: string };
  2: { value: string };
  3: { value: string };
  4: { value: string };
}

/**
 * - Handle splitting mac address
 * @returns 4 inputs
 */

const MacAddressFields: React.FunctionComponent<MultiFieldsProps> = ({ fieldName, tableName, isSubmit }) => {
  const [value, setValue] = useState<StateProps>({
    1: { value: '' },
    2: { value: '' },
    3: { value: '' },
    4: { value: '' },
  });
  const [prefix, setPrefix] = useState('');
  const { dispatch, state } = useAppState();
  const { currentModal, datasourceMysql, dictionary_uiElements, error, form } = state;
  const { TR_errorMacAddressFormat, TR_errorMacAddressDashDeleted, TR_errorMacAddressExists } = dictionary_uiElements;

  const indexFieldForm = findIndexInForm(fieldName, form);
  // Get the index of the form field in the saved state form array
  const styles = useStyles2(getStyles);

  const handleChange = (e: any) => {
    // Reset error messages
    if (error.macAddress) {
      setError(ErrorTypes.macAddress, '', dispatch);
    }
    if (error.macAddressDashboardDeleted) {
      setError(ErrorTypes.macAddressDashboardDeleted, '', dispatch);
    }

    const newState = { ...value } as any;
    newState[e.target.tabIndex].value = e.target.value;
    setValue(newState);

    const finalValue = `${newState[1].value}-${newState[2].value}-${newState[3].value}-${newState[4].value}`;
    if (!finalValue.includes(' ')) {
      addToForm(dispatch, finalValue, fieldName, tableName);
    } else {
      addToForm(dispatch, '', fieldName, tableName);
    }

    if (finalValue.length === EDITABLE_MAC_ADDRESS_LENGTH && !finalValue.includes(' ')) {
      const re = /([0-9A-Fa-f]{2})-([0-9A-Fa-f]{2})-([0-9A-Fa-f]{2})-([0-9A-Fa-f]{2})/g;
      const isValid = re.test(finalValue);
      if (!isValid) {
        setError(ErrorTypes.macAddress, TR_errorMacAddressFormat, dispatch);
        return;
      }
      checkIfMacAddressExists(finalValue);
    }
  };

  const checkIfMacAddressExists = async (value: string) => {
    // Check if mac address exists in db
    const sqlQuery = `SELECT mac_address, machine_name FROM identity_card WHERE mac_address = '${prefix}${value}'`;
    const queryResult = await mysqlRequest(datasourceMysql.uid, sqlQuery);

    if (queryResult.length) {
      // if mac address exists, check if dashboard related to it exists in Grafana
      const machineName = queryResult[0][1];
      const searchDashName = await getBackendSrv().get(`${API_QUERY_DASHBOARD}${machineName}`);

      const dashGrafana = searchDashName.filter((dashboard: { title: string }) => dashboard.title === machineName);
      if (!dashGrafana.length) {
        setError(ErrorTypes.macAddressDashboardDeleted, TR_errorMacAddressDashDeleted, dispatch);
      }
      // if dashboard exists, throw error
      else {
        setError(ErrorTypes.macAddress, TR_errorMacAddressExists, dispatch);
      }
    }
    return;
  };

  const getDefaultMacAddress = () => {
    const macAddress = form[indexFieldForm]?.value;

    // Empty fields when user chooses to abort dashboard creation with a mac address already used but with no related dashboard in Grafana.
    if (macAddress === '') {
      setValue({
        1: { value: '' },
        2: { value: '' },
        3: { value: '' },
        4: { value: '' },
      });
      return;
    }

    const splitAddress = macAddress.split('-');
    setValue({
      1: { value: `${splitAddress[splitAddress.length - 4]}` },
      2: { value: `${splitAddress[splitAddress.length - 3]}` },
      3: { value: `${splitAddress[splitAddress.length - 2]}` },
      4: { value: `${splitAddress[splitAddress.length - 1]}` },
    });
  };

  const testMacAddress = (macAddress: string) => {
    if (!macAddress) {
      return;
    }
    const re = /([0-9A-Fa-f]{2})/g;
    return re.test(macAddress);
  };

  const getMacAddressPrefix = () => {
    const macAddressPrefix =
      currentModal === Process.update ? form[indexFieldForm]?.value?.slice(0, 12) : MAC_ADRESS_PREFIX;
    setPrefix(macAddressPrefix);
  };

  useEffect(() => {
    if (form.length && indexFieldForm !== -1) {
      getDefaultMacAddress();
    }
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentModal === Process.create || currentModal === Process.update) {
      getMacAddressPrefix();
    }
  }, [currentModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.macAdressContainer}>
      <div className={styles.numbers}>{prefix}</div>
      <Input
        width={6}
        name={'first'}
        value={value[1].value}
        onChange={(e) => handleChange(e)}
        placeholder="00"
        invalid={
          (isSubmit && (value[1].value.trim() === '' || value[1].value.length < 2)) ||
          error.macAddress ||
          error.macAddressPrefix ||
          error.macAddressDashboardDeleted ||
          (value[1].value.trim() !== '' && !testMacAddress(value[1].value))
            ? true
            : false
        }
        maxLength={2}
        tabIndex={1}
        disabled={currentModal === Process.update}
      />
      <span className={styles.dash}>-</span>
      <Input
        width={6}
        name={'second'}
        value={value[2].value}
        onChange={(e) => handleChange(e)}
        placeholder="00"
        invalid={
          (isSubmit && (value[2].value.trim() === '' || value[2].value.length < 2)) ||
          error.macAddress ||
          error.macAddressPrefix ||
          error.macAddressDashboardDeleted ||
          (value[2].value.trim() !== '' && !testMacAddress(value[2].value))
            ? true
            : false
        }
        maxLength={2}
        tabIndex={2}
        disabled={currentModal === Process.update}
      />
      <span className={styles.dash}>-</span>
      <Input
        width={6}
        name={'third'}
        value={value[3].value}
        onChange={(e) => handleChange(e)}
        placeholder="00"
        invalid={
          (isSubmit && (value[3].value.trim() === '' || value[3].value.length < 2)) ||
          (error.macAddress && !testMacAddress(value[3].value)) ||
          error.macAddress ||
          error.macAddressPrefix ||
          error.macAddressDashboardDeleted ||
          (value[3].value.trim() !== '' && !testMacAddress(value[3].value))
            ? true
            : false
        }
        maxLength={2}
        tabIndex={3}
        disabled={currentModal === Process.update}
      />
      <span className={styles.dash}>-</span>
      <Input
        width={6}
        name={'fourth'}
        value={value[4].value}
        onChange={(e) => handleChange(e)}
        placeholder="00"
        invalid={
          (isSubmit && (value[4].value.trim() === '' || value[4].value.length < 2)) ||
          (error.macAddress && !testMacAddress(value[4].value)) ||
          error.macAddress ||
          error.macAddressPrefix ||
          error.macAddressDashboardDeleted ||
          (value[4].value.trim() !== '' && !testMacAddress(value[4].value))
            ? true
            : false
        }
        maxLength={2}
        tabIndex={4}
        disabled={currentModal === Process.update}
      />
    </div>
  );
};

export default MacAddressFields;

const getStyles = () => {
  return {
    macAdressContainer: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    `,
    numbers: css`
      margin-right: 10px;
      font-size: 18px;
      font-weight: 500;
      color: #757575;
      letter-spacing: 0.1rem;
    `,
    dash: css`
      padding: 6px;
    `,
  };
};
