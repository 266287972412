import { SelectableValue } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Input, Select, MultiSelect } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, ErrorTypes, Process } from 'app-context/types';
import {
  CLASS_TABLE,
  MAC_ADDRESS_TABLE,
  MACHINE_FILTER_PREFIX,
  MACHINE_FILTER_TABLE,
  MACHINE_NAME_TABLE,
  MACHINE_TAG,
  SUMMARY_TAG,
  TAGS_TABLE,
  COMPARISON_TAG,
  MAIN_TAG,
  FUNCTION_TABLE,
  ANALYSIS_PROFILE_TABLE,
} from 'helpers/constants';
import {
  addToForm,
  findIndexDictionnary,
  findIndexInForm,
  getDefaultValue,
  getPlaceholder,
  setError,
} from 'helpers/helpersForm';
import React, { useEffect, useState } from 'react';
import MacAddressFields from 'form/steps/Step1/MacAddressFields';
import { formSteps } from 'form/formSteps';

/**
 * - Display form elements of General info section.
 * - Check if dashboard name (machine name) already exists in Grafana.
 * @fields mac_address, machine_name, process_function,power_class, manufactuer, model
 */

interface Field {
  name: string;
  isRequired: boolean;
  tableName: string;
}

export const useFormFieldsStep1 = (
  isInvalid: boolean | undefined,
  isSubmit: boolean | undefined,
  name: string,
  tableName: string,
  setCurrentStepslist: React.Dispatch<
    React.SetStateAction<{
      id: CurrentStep;
      sectionTitle: string;
      fields: Field[];
    }>
  >,
  currentStepsList: {
    id: CurrentStep;
    sectionTitle: string;
    fields: Field[];
  }
) => {
  /**
   * On update, compare origin dashboard name and name filled by user,
   * doesn't throw error if it's the same
   */
  const [fixedDashboardName, setFixedDashboardName] = useState('');

  const { state, dispatch } = useAppState();
  const { dictionary_uiElements, dropdownMenusOptions, form, currentModal, dashboardFolder, lengthUnit } = state;
  const { TR_dashboardExists, TR_formatCreateOptionText, TR_tagsField } = dictionary_uiElements;
  // const styles = getStyles(theme);

  const indexFieldForm = findIndexInForm(name, form);
  const [tags, setTags] = useState<any[]>(getDefaultValue(indexFieldForm, form));
  const [tagsOptions, setTagsOptions] = useState<any[]>([]);
  const [machineValue, setMachineValue] = useState<SelectableValue<string>>();
  const [machineTagsOptions, setMachineTagsOptions] = useState<SelectableValue[]>([]);

  const formStep = formSteps(dictionary_uiElements);
  const { step1 } = formStep[0];

  // Get the index of select options from dictionary

  const optionsIndex = findIndexDictionnary(tableName, dropdownMenusOptions);
  const optionsSelect = dropdownMenusOptions[optionsIndex] && dropdownMenusOptions[optionsIndex].values;
  const optionsTagsIndex = findIndexDictionnary(TAGS_TABLE, dropdownMenusOptions);

  const tagsIndex = findIndexInForm(TR_tagsField, form);

  const getDefaultTags = () => {
    if (tagsIndex !== -1) {
      if (tableName === TAGS_TABLE) {
        const tagsFilter = form[tagsIndex].value.filter(
          (tag: { value: string }) => !tag.value.includes(MACHINE_FILTER_PREFIX) && tag.value !== MACHINE_TAG
        );
        setTags(tagsFilter);
        return;
      }
      if (tableName === MACHINE_FILTER_TABLE) {
        const machinetags = form[tagsIndex].value.filter((tag: { value: string }) =>
          tag.value.includes(MACHINE_FILTER_PREFIX)
        );

        if (machinetags.length) {
          const splitLabel = machinetags[0].value.split('_');
          setMachineValue({ label: splitLabel[1], value: machinetags[0].value });
          return;
        }
      }
    }
  };
  /**
   * Query Grafana Dashboard by name and check if it already exists in folder.
   * Throw error if dashboard name already exists.
   */
  const checkIfNameExists = async (dashboardName: string) => {
    // Reset error on input change
    setError(ErrorTypes.dashboardName, '', dispatch);
    // if value entered is the same as the origin dashboard name,
    // don't throw error and keep origin dashboard name

    if (currentModal === Process.update && dashboardName.trim() === fixedDashboardName) {
      addToForm(dispatch, fixedDashboardName, name, tableName);
      return;
    }

    await getBackendSrv()
      .get(`/api/search?folderIds=${dashboardFolder.id}&query=${dashboardName.trim()}`)
      .then((result: any) => {
        if (result.length && dashboardName !== '') {
          for (const dashboardData of result) {
            if (dashboardData.title === dashboardName) {
              setError(ErrorTypes.dashboardName, `${dashboardName} : ${TR_dashboardExists}`, dispatch);
              return;
            }
          }
        }
        setError(ErrorTypes.dashboardName, '', dispatch);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e: any) => {
    // If the value is cleared, e is null
    if (!e) {
      if (tableName === TAGS_TABLE) {
        if (indexFieldForm !== -1 && form[indexFieldForm].value.length) {
          const tags = form[indexFieldForm].value.filter((tag: { value: string }) =>
            tag.value.includes(MACHINE_FILTER_PREFIX)
          );
          addToForm(dispatch, tags, name, tableName);
          setTags([]);
          return;
        }
        addToForm(dispatch, [], name, tableName);
        return;
      }

      if (tableName === MACHINE_FILTER_TABLE) {
        if (tagsIndex !== -1 && form[tagsIndex].value.length) {
          const tags = form[tagsIndex].value.filter(
            (tag: { value: string }) => !tag.value.includes(MACHINE_FILTER_PREFIX)
          );
          addToForm(dispatch, tags, TR_tagsField, TAGS_TABLE);
          setMachineValue(e);
          return;
        }
        setMachineValue(e);
        addToForm(dispatch, [], TR_tagsField, TAGS_TABLE);
        return;
      }
      addToForm(dispatch, '', name, tableName);
      return;
    }

    switch (tableName) {
      case MACHINE_NAME_TABLE: {
        addToForm(dispatch, e.target.value, name, tableName, e.target.value);
        e.target.value.trim() && checkIfNameExists(e.target.value); // Prevent checking empty dashboard name
        return;
      }

      case TAGS_TABLE: {
        if (indexFieldForm === -1) {
          setTags(e);
          addToForm(dispatch, e, name, tableName);
          return;
        }
        if (form[indexFieldForm].value.length) {
          const filtersInTags = form[indexFieldForm].value.filter(
            (tag: { value: string }) => typeof tag.value === 'string' && tag.value.includes(MACHINE_FILTER_PREFIX)
          );
          setTags(e);
          addToForm(dispatch, [...filtersInTags, ...e], name, tableName);
          return;
        }
        setTags(e);
        addToForm(dispatch, e, name, tableName);
        return;
      }

      case MACHINE_FILTER_TABLE: {
        if (tagsIndex === -1 || !form[tagsIndex]?.value?.length) {
          setMachineValue([e]);
          addToForm(dispatch, [e], TR_tagsField, TAGS_TABLE, e.label);
          return;
        }
        /** Add filter to tags */
        if (form[tagsIndex].value.length) {
          const additionalTags = form[tagsIndex].value.filter(
            (tag: { value: string }) => typeof tag.value === 'string' && !tag.value.includes(MACHINE_FILTER_PREFIX)
          );
          setMachineValue(e);
          addToForm(dispatch, [...additionalTags, e], TR_tagsField, TAGS_TABLE);
          return;
        }
        addToForm(dispatch, e, TR_tagsField, TAGS_TABLE);
        return;
      }

      case FUNCTION_TABLE: {
        if (e.label === dictionary_uiElements.TR_valve) {
          // Set by default class = Class I, analysis profile = 'Valve'
          addToForm(dispatch, e.label, name, tableName, e.label);
          addToForm(dispatch, dictionary_uiElements.TR_class1, dictionary_uiElements.TR_classField, CLASS_TABLE);
          addToForm(
            dispatch,
            dictionary_uiElements.TR_valve,
            dictionary_uiElements.TR_analysisProfileField,
            ANALYSIS_PROFILE_TABLE
          );
          return;
        }

        addToForm(
          dispatch,
          dictionary_uiElements.TR_rotating,
          dictionary_uiElements.TR_analysisProfileField,
          ANALYSIS_PROFILE_TABLE
        );
      }

      // handles simple inputs
      default: {
        addToForm(dispatch, e.label, name, tableName, e.label);
        return;
      }
    }
  };

  const createNewTag = (tagValue: string) => {
    const tagToCreate = { label: tagValue, value: tagValue };
    if (tagsIndex === -1) {
      setTags([tagValue]);
      setTagsOptions([tagToCreate]);
      addToForm(dispatch, [tagToCreate], name, tableName);
      return;
    }
    setTags([...form[indexFieldForm]?.value, tagToCreate]);
    const newtags = [...tagsOptions, tagToCreate];
    setTagsOptions(newtags);
    addToForm(dispatch, [...form[indexFieldForm]?.value, tagToCreate], name, tableName);
  };

  const createNewMachine = (tagValue: string) => {
    if (!tagValue) {
      return;
    }
    const machineToCreate = { label: tagValue, value: `${MACHINE_FILTER_PREFIX}${tagValue}` };
    if (tagsIndex === -1) {
      setMachineValue(machineToCreate);
      setMachineTagsOptions([machineToCreate]);
      addToForm(dispatch, [machineToCreate], TR_tagsField, TAGS_TABLE);
      return;
    }
    setMachineValue(machineToCreate);
    const newMachines = [...machineTagsOptions, machineToCreate];
    setMachineTagsOptions(newMachines);
    const removeMachineTag = form[tagsIndex]?.value?.filter(
      (tag: { value: string }) => !tag.value.includes(MACHINE_FILTER_PREFIX)
    );
    addToForm(dispatch, [...removeMachineTag, machineToCreate], TR_tagsField, TAGS_TABLE);
  };

  const getInput = () => {
    if (tableName === CLASS_TABLE) {
      return;
    }

    switch (tableName) {
      // Displays Mac address field (half field preffixed by the first 10 numbers of MAC address)
      case MAC_ADDRESS_TABLE:
        return <MacAddressFields tableName={tableName} fieldName={name} isSubmit={isSubmit} />;
      case MACHINE_NAME_TABLE:
        return (
          <Input
            name={name}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            value={getDefaultValue(indexFieldForm, form)}
            onChange={(v) => handleChange(v)}
            invalid={isInvalid}
            maxLength={55}
            width={50}
            tabIndex={4}
            id={'machine_name_input'}
          />
        );
      case CLASS_TABLE: // non creatable
        return (
          <Select
            width={50}
            options={optionsSelect}
            value={getDefaultValue(indexFieldForm, form)}
            onChange={(v) => handleChange(v)}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            invalid={isInvalid}
            isSearchable
            maxMenuHeight={130}
            id={'select_class'}
          />
        );
      case TAGS_TABLE:
        return (
          <MultiSelect
            width={50}
            options={tagsOptions}
            value={tags}
            onChange={(v) => handleChange(v)}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            maxMenuHeight={160}
            invalid={isInvalid}
            menuPlacement={'top'}
            allowCustomValue
            isSearchable
            formatCreateLabel={(v) => `${TR_formatCreateOptionText} ${name.toLowerCase()} : ${v}`} // translate 'Create'
            onCreateOption={(v) => createNewTag(v)}
          />
        );
      case MACHINE_FILTER_TABLE:
        return (
          <Select
            width={50}
            options={machineTagsOptions}
            value={machineValue}
            onChange={(v) => handleChange(v)}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            maxMenuHeight={160}
            invalid={isInvalid}
            menuPlacement={'top'}
            allowCustomValue
            isSearchable
            isClearable
            formatCreateLabel={(v) => `${TR_formatCreateOptionText} ${name.toLowerCase()} : ${v}`} // translate 'Create'
            onCreateOption={(v) => createNewMachine(v)}
          />
        );
      default:
        return (
          <Select
            width={50}
            options={optionsSelect}
            value={getDefaultValue(indexFieldForm, form)}
            onChange={(v) => handleChange(v)}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            invalid={isInvalid}
            menuPlacement={tableName === 'manufacturer' || tableName === 'model' ? 'top' : 'bottom'}
            maxMenuHeight={130}
            allowCustomValue
            isClearable
            isSearchable
            formatCreateLabel={(v) => `${TR_formatCreateOptionText} ${name.toLowerCase()} : ${v}`} // translate 'Create'
            onCreateOption={(v) => {
              optionsSelect.push({ label: v, value: v });
              addToForm(dispatch, v, name, tableName);
            }}
            id={`select_${tableName}`}
          />
        );
    }
  };

  // Retrieve tags in the selected folder
  const getTagsInFolders = async (folderId: number) => {
    await getBackendSrv()
      .get(`/api/search?&type=dash-db`)
      .then(async (resultDashboards) => {
        const dashboardTags = resultDashboards
          .filter((dashboard: { tags: string[] }) => dashboard.tags.length !== 0)
          .map((dashboard: { tags: string[] }) => dashboard.tags);

        const allTags = [] as any;
        const allMachineTags = [] as any;

        for (const dashboardTag of dashboardTags) {
          for (const tag of dashboardTag) {
            if (
              !tag.includes(MACHINE_FILTER_PREFIX) &&
              tag?.toLowerCase() !== SUMMARY_TAG?.toLowerCase() &&
              tag?.toLowerCase() !== MACHINE_TAG?.toLowerCase() &&
              tag?.toLowerCase() !== COMPARISON_TAG?.toLowerCase() &&
              tag?.toLowerCase() !== MAIN_TAG?.toLowerCase()
            ) {
              const indexOfTag = allTags.findIndex((machineTag: { value: string }) => machineTag.value === tag);
              if (indexOfTag === -1) {
                allTags.push({ label: tag, value: tag });
              }
            }
            if (tag.includes(MACHINE_FILTER_PREFIX)) {
              const indexOfMachineTag = allMachineTags.findIndex(
                (machineTag: { value: string }) => machineTag.value === tag
              );
              if (indexOfMachineTag === -1) {
                const tagSplit = tag.split('_');
                allMachineTags.push({ label: tagSplit[1], value: tag });
              }
            }
          }
        }

        // Retrieve tags created by user
        let userTags = [] as any;
        let userMachineTags = [] as any;
        // additional tags (remove 'Main', 'Comparison' and 'Summary' tags)
        if (form[tagsIndex]?.value?.length) {
          userTags = form[tagsIndex].value
            .filter((userTag: { value: string }) => {
              return allTags.findIndex((alltag: { value: string }) => alltag.value === userTag.value) < 0
                ? true
                : false;
            })
            .filter((userTag: { value: string }) => !userTag.value.includes(MACHINE_FILTER_PREFIX))
            .filter((userTag: { value: string }) => userTag.value?.toUpperCase() !== MACHINE_TAG?.toUpperCase())
            .filter((userTag: { value: string }) => userTag.value?.toUpperCase() !== SUMMARY_TAG?.toUpperCase())
            .filter((userTag: { value: string }) => userTag.value?.toUpperCase() !== COMPARISON_TAG?.toUpperCase());
          // machine tag
          userMachineTags = form[tagsIndex].value
            ?.filter((userMachineTag: { value: string }) => {
              return allMachineTags.findIndex(
                (allMachineTag: { value: string }) => allMachineTag.value === userMachineTag.value
              ) < 0
                ? true
                : false;
            })
            .filter((userMachineTag: { value: string }) => userMachineTag.value.includes(MACHINE_FILTER_PREFIX));
        }

        setTagsOptions([...allTags, ...userTags]);
        setMachineTagsOptions([allMachineTags, ...userMachineTags]);

        dispatch({
          type: 'ADD_SELECT_OPTIONS',
          payload: {
            tableName: TAGS_TABLE,
            value: [...allTags, ...userTags, ...allMachineTags, ...userMachineTags],
          },
        });
      });
  };

  // Retrieve original dashboard name to compare to (if user decides to rename dashboard)
  useEffect(() => {
    if (currentModal === Process.update && tableName === MACHINE_NAME_TABLE) {
      setFixedDashboardName(form[indexFieldForm]?.value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((tableName === MACHINE_FILTER_TABLE || tableName === TAGS_TABLE) && form.length) {
      if (tagsIndex !== -1 && form[tagsIndex].value.length) {
        getDefaultTags();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** On folder change, check if dashboard name exists in folder */
  useEffect(() => {
    if (Object.keys(dashboardFolder).length && tableName === MACHINE_FILTER_TABLE) {
      getTagsInFolders(dashboardFolder.id);
    }
    if (Object.keys(dashboardFolder).length && tableName === MACHINE_NAME_TABLE && currentModal === Process.create) {
      form[indexFieldForm]?.value?.trim() && checkIfNameExists(form[indexFieldForm]?.value);
    }
  }, [dashboardFolder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dropdownMenusOptions.length && optionsTagsIndex !== -1) {
      if (tableName === MACHINE_FILTER_TABLE) {
        const optionsSelect = dropdownMenusOptions[optionsTagsIndex] && dropdownMenusOptions[optionsTagsIndex].values;
        const machinesInTagsFiltered = optionsSelect.filter(
          (option) => typeof option.value === 'string' && option.value?.includes(MACHINE_FILTER_PREFIX)
        );
        const machinesInTags = [];
        for (const tag of machinesInTagsFiltered) {
          if (typeof tag.value === 'string') {
            const machineTag = tag.value.split('_');
            if (machineTag.length) {
              machinesInTags.push({ label: machineTag[1], value: tag.value });
            }
          }
        }
        setMachineTagsOptions(machinesInTags);
      }
      if (tableName === TAGS_TABLE) {
        const optionsSelect = dropdownMenusOptions[optionsTagsIndex] && dropdownMenusOptions[optionsTagsIndex].values;
        const tagsFiltered = optionsSelect.filter(
          (option) => typeof option.value === 'string' && !option.value?.includes(MACHINE_FILTER_PREFIX)
        );
        setTagsOptions(tagsFiltered);
      }
    }
  }, [dropdownMenusOptions[optionsTagsIndex]]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Handle valve function. If select, hide CLASS field */
  useEffect(() => {
    if (indexFieldForm !== -1 && tableName === FUNCTION_TABLE) {
      if (form[indexFieldForm]?.value === dictionary_uiElements.TR_valve) {
        const newFields = currentStepsList.fields?.filter((step) => step.tableName !== CLASS_TABLE);
        const newStep = { ...currentStepsList, fields: newFields };
        setCurrentStepslist(newStep);
        return;
      }
      setCurrentStepslist(step1);
    }
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  const INPUT = React.useMemo(() => getInput(), [lengthUnit, form, dropdownMenusOptions, isSubmit, getInput]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    INPUT,
    indexFieldForm,
    optionsSelect,
  };
};
