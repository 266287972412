import { getTemplateSrv } from '@grafana/runtime';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, ErrorTypes, Option, Process } from 'app-context/types';
import { CLASS_TABLE, TAGS_TABLE } from 'helpers/constants';
import { getDatasources } from 'helpers/GetDatasources';
import { getJson } from 'helpers/GetDictionnary';
import { getOptions } from 'helpers/GetDropdownMenus';
import { getLanguage, setError } from 'helpers/helpersForm';
import { addAllCustomValuesToEmptyEntry, getModalsProcesses } from 'helpers/helpersModal';
import React from 'react';
import { URL_DICO_JSON, URL_ISO_JSON } from 'helpers/URLS';

/**
 * - Load dictionnaries {LANG}.json
 * - Build dropdown menus options
 */

export const useModalForm = () => {
  const { state, dispatch } = useAppState();
  const { modals, dictionary_uiElements, lang, dropdownMenusOptions, datasourceMysql, steps, user } = state;
  const grafanaVariables = getTemplateSrv().getVariables();
  let dictionnaryOptions = {};

  const indexModalCreate = modals.findIndex((modal) => modal.process === Process.create);
  const indexModalUpdate = modals.findIndex((modal) => modal.process === Process.update);

  /**
   * Get the menu parts params (CREATE, UPDATE, DELETE, FILTERS)
   */
  const MODALS = getModalsProcesses(modals, dictionary_uiElements);

  /**
   * Get translated form dropdown menus options
   * TO DO handle dico error
   */
  const getDropdownOptionsFromDico = async () => {
    dispatch({ type: 'REMOVE_SELECT_OPTIONS' });

    /**
     * Get dictionary (EN.json | FR.json).
     * (Translated options)
     */
    const dico = await getJson(`${URL_DICO_JSON}${lang}.json`);
    if (dico) {
      dispatch({ type: 'ADD_OPTIONS_DICO', payload: dico.options });
      dictionnaryOptions = dico.options;
    }

    /**
     * Completion of dropdown menus with custom values if they exist.
     * mysql> select * from sensor_location;
    +----------------+
    | item           |
    +----------------+
    | Casing         |
    | Foundations    |
    | Input Shaft    |
    | Output Shaft   |
    | *Custom value* |
    +----------------+
     */
    for (const [optionKey, optionValue] of Object.entries(dictionnaryOptions as any)) {
      let menuOptions: string[] = [];
      if (optionKey === TAGS_TABLE) {
        continue;
      }
      const queryResult = await getOptions(datasourceMysql.uid, optionKey);

      if (!queryResult) {
        return;
      }

      const results = queryResult;

      /**
       * If dictionary key is empty (as manufacturer, model, tags, etc...) add all values,
       * no need to translate them.
       */
      addAllCustomValuesToEmptyEntry(optionKey, optionValue, results, menuOptions);

      /**
       * Completion of fixed list options :
       * - power_class,
       * - process_function,
       * - type
       * Translation of each option entry
       */
      for (const [, childValue] of Object.entries(optionValue as any) as any) {
        menuOptions.push(childValue);
      }

      /**
       * Add custom values to dropdown menu options
       */
      results.map((result: string) => {
        const findInDico = Object.keys(optionValue as any).find((key) => key === result[0]);
        const findInValuesSelect = menuOptions.findIndex((el) => el === result[0]);

        if (!findInDico && findInValuesSelect === -1 && optionKey !== TAGS_TABLE) {
          menuOptions.push(result[0]);
        }
      });

      const transformOptions = (menuOptions: string[], optionKey: string) => {
        if (optionKey === CLASS_TABLE) {
          // ISO Classes options are particular and built with nested options.
          // To be easier handled, these options are already written in the json (class.options).
          // In the JSON, class["Class I"] is needed for translation
          return (optionValue as any)?.options;
        } else {
          return menuOptions.map((option) => ({ label: option, value: option }));
        }
      };

      dispatch({
        type: 'ADD_SELECT_OPTIONS',
        payload: {
          tableName: optionKey,
          value: transformOptions(menuOptions, optionKey) as Option[],
        },
      });
    }
  };

  /**
   * Get translated language elements (UI).
   * Throw error if file is not found and don't display main menu buttons.
   */
  const getDictionary = async () => {
    const dico = await getJson(`${URL_DICO_JSON}${lang}.json`);
    if (typeof dico === 'object') {
      if (Object.keys(dico).length && Object.keys(dico?.ui_elements).length) {
        dispatch({ type: 'ADD_TRANSLATED_UI_TO_DICO', payload: dico.ui_elements });
      }
    } else {
      setError(ErrorTypes.badRequest, 'No translation file found', dispatch);
    }
  };

  const getIsoClassifications = async () => {
    const isoClasses = await getJson(URL_ISO_JSON);
    if (typeof isoClasses === 'object') {
      if (Object.keys(isoClasses).length) {
        dispatch({ type: 'SET_ISO_CLASSIFICATIONS', payload: isoClasses });
      }
    } else {
      setError(ErrorTypes.badRequest, 'No iso classifications file found', dispatch);
    }
  };

  /**
   * Get mysql and influx datasources
   */
  React.useEffect(() => {
    getDatasources(dispatch, grafanaVariables);
    getDictionary();
    getIsoClassifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Get home dashboard's language (on creation process only)
   */
  React.useEffect(() => {
    if (modals.length && modals[indexModalCreate].isOpen) {
      getLanguage(dispatch, grafanaVariables);
    }
  }, [modals]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Push panel options to state
   */
  // React.useEffect(() => {
  //   if (Object.keys(options).length) {
  //     dispatch({ type: 'SET_PANEL_OPTIONS', payload: options });
  //   }
  // }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Get dictionnary to build dropdown menu options
   */
  React.useEffect(() => {
    if (
      Object.keys(datasourceMysql).length &&
      !Object.keys(dropdownMenusOptions).length &&
      steps.current !== CurrentStep.DELETE_STEP1 &&
      (modals[indexModalCreate].isOpen || modals[indexModalUpdate].isOpen)
    ) {
      getDropdownOptionsFromDico();
    }
  }, [datasourceMysql, modals]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    MODALS,
    modals,
    user,
  };
};
