import { TAGS_TABLE, MACHINE_FILTER_PREFIX } from './constants';
import { ModalProps, Process } from 'app-context/types';
import { TranslationProps } from 'app-context/translation-types';

/**
 * If dictionary key is empty (as manufacturer, model, etc...) add all values,
 * no need to translate them.
 */
export const addAllCustomValuesToEmptyEntry = (
  dicoField: string,
  dicoFieldValue: any,
  results: any[],
  dropdownMenuOptions: string[]
) => {
  if (!Object.keys(dicoFieldValue as string).length) {
    // if tags, remove machine filters
    if (dicoField === TAGS_TABLE) {
      results.map((result: string) => {
        // Filter tags related to machine filters
        !result[0].includes(MACHINE_FILTER_PREFIX) && dropdownMenuOptions.push(result[0]);
        return dropdownMenuOptions;
      });
    } else {
      results?.map((result: string) => {
        dropdownMenuOptions.push(result[0]);
        return dropdownMenuOptions;
      });
    }
  }
};

export const getModalsProcesses = (modals: ModalProps[], dictionary_uiElements: TranslationProps) => {
  const indexModalCreate = modals.findIndex((modal) => modal.process === Process.create);
  const indexModalUpdate = modals.findIndex((modal) => modal.process === Process.update);
  const indexModalDelete = modals.findIndex((modal) => modal.process === Process.delete);
  const indexModalUpdateSinglePages = modals.findIndex((modal) => modal.process === Process.manage_single_pages);

  const { TR_modalTitleCreate, TR_modalTitleUpdate, TR_deleteDashboard } = dictionary_uiElements;

  const MODALS = [
    {
      index: indexModalCreate,
      isOpen: modals[indexModalCreate].isOpen,
      title: TR_modalTitleCreate,
      process: Process.create,
    },
    {
      index: indexModalUpdate,
      isOpen: modals[indexModalUpdate].isOpen,
      title: TR_modalTitleUpdate,
      process: Process.update,
    },
    {
      index: indexModalDelete,
      isOpen: modals[indexModalDelete].isOpen,
      title: TR_deleteDashboard,
      process: Process.delete,
    },
    {
      index: indexModalUpdateSinglePages,
      isOpen: modals[indexModalUpdateSinglePages].isOpen,
      title: 'MANAGE SINGLE PAGES',
      process: Process.manage_single_pages,
    },
  ];
  return MODALS;
};

export const toggleVisible = (modalProcess: Process, dispatch: any, isOpen: boolean) => {
  dispatch({
    type: 'TOGGLE_VISIBLE',
    payload: {
      process: modalProcess,
      isOpen: isOpen,
    },
  });
};

export const closeModal = (modalProcess: Process, dispatch: any, isOpen: boolean) => {
  toggleVisible(modalProcess, dispatch, isOpen);
  location.reload();
};
