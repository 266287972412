import { GrafanaTheme2 } from '@grafana/data';
import { Select, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { addToForm, findIndexDictionnary, findIndexInForm, getDefaultValue, getPlaceholder } from 'helpers/helpersForm';
import React from 'react';
import { RedStarRequiredField } from 'shared';
import { formContainerStyle } from 'helpers/helpersStyle';
import { FormFieldProps } from 'app-context/types';

/**
 * Display form elements of Analysis Profile section
 */

export const FormFieldStep3: React.FunctionComponent<FormFieldProps> = ({ isInvalid, isRequired, name, tableName }) => {
  // Get the index of the form field in the saved state

  const { state, dispatch } = useAppState();
  const { dropdownMenusOptions, form, dictionary_uiElements } = state;
  const styles = useStyles2(getStyles);
  const indexFieldForm = findIndexInForm(name, form);

  // Get the index of select options from dictionary
  const optionsIndex = findIndexDictionnary(tableName, dropdownMenusOptions);
  const optionsSelect = dropdownMenusOptions[optionsIndex] && dropdownMenusOptions[optionsIndex].values;

  const handleChange = (e: any) => {
    addToForm(dispatch, e.label, name, tableName, e.label);
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {name} {isRequired && <RedStarRequiredField />}{' '}
      </div>
      <div className={styles.inputContainer}>
        <Select
          width={50}
          options={optionsSelect}
          value={getDefaultValue(indexFieldForm, form)}
          onChange={(e) => handleChange(e)}
          placeholder={getPlaceholder(tableName, dictionary_uiElements)}
          invalid={isInvalid}
          isSearchable
        />
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      ${formContainerStyle}
    `,
    label: css`
      width: 30%;
      max-width: 250px;
      min-width: 180px;
      background-color: ${theme.colors.background.secondary};
      height: 32px;
      text-align: left;
      text-justify: center;
      margin-right: 10px;
      padding: 7px;
      font-size: 14px;
      color: ${theme.colors.text};
      font-weight: 400;
    `,
    inputContainer: css`
      width: 100%;
    `,
    listElement: css`
      margin-left: 15px;
    `,
    descriptionContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: self-start;
    `,
  };
};
