import { dateTimeFormat, SelectableValue, GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Icon, Select, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, DashboardListProps, FormFieldProps } from 'app-context/types';
import { css } from '@emotion/css';
import { MAC_ADDRESS_TABLE, TAGS_TABLE } from 'helpers/constants';
import { getSelectedDashboardIdCard } from 'helpers/GetDashboardIdCard';
import { getDashboardInfo } from 'helpers/helpersDashboards';
import {
  addToForm,
  findIndexInForm,
  getDefaultValue,
  getUserFolders,
  hasValueToBeTranslated,
  resetImage,
  payloadStep,
} from 'helpers/helpersForm';
import { formContainerStyle } from 'helpers/helpersStyle';
import { API_DASHBOARD_BY_UID } from 'helpers/URLS';
import React, { useEffect, useState } from 'react';
import { ConfirmDeleteTextContainer } from './ConfirmDeleteTextContainer';
import { NotificationError } from 'shared/components/NotificationMessage';

interface Step0Props extends FormFieldProps {
  dashboardsNames: DashboardListProps[];
  isDeleted?: boolean;
  loading: boolean;
  setIsDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * - Display dropdown menu to select dashboards.
 * - Loading of dashboard info
 * - !! Step 99 is the deletion feature !!
 */
export const FormFieldStep0: React.FunctionComponent<Step0Props> = ({
  dashboardsNames,
  isDeleted,
  isInvalid,
  loading,
  name,
  setIsDeleted,
  tableName,
}) => {
  const { state, dispatch } = useAppState();
  const {
    currentModal,
    dashboardFolder,
    dashboardsList,
    datasourceMysql,
    datasourceJson,
    dictionary_uiElements,
    form,
    lang,
    multiLingualDictionary,
    steps,
    uid,
  } = state;
  // translations
  const {
    TR_ambientTemperature,
    TR_analysisProfileField,
    TR_classField,
    TR_creationDashboard,
    TR_customClass,
    TR_folder,
    TR_folderNotFound,
    TR_folderPlaceholder,
    TR_functionField,
    TR_loadingDashboards,
    TR_macAddressField,
    TR_machineNameField,
    TR_machinePartField,
    TR_manufacturerField,
    TR_modelField,
    TR_noDashboardFound,
    TR_placeHolderDashboardSelect,
    TR_sensorLocationField,
    TR_sensorOrientationField,
    TR_tagsField,
    TR_updateDashboard,
  } = dictionary_uiElements;

  const sqlTables = [
    { tableName: 'machine_name', fieldName: TR_machineNameField },
    { tableName: 'mac_address', fieldName: TR_macAddressField },
    { tableName: 'process_function', fieldName: TR_functionField },
    { tableName: 'sensor_location', fieldName: TR_sensorLocationField },
    { tableName: 'sensor_orientation', fieldName: TR_sensorOrientationField },
    { tableName: 'manufacturer', fieldName: TR_manufacturerField },
    { tableName: 'model', fieldName: TR_modelField },
    { tableName: 'type', fieldName: TR_machinePartField },
    { tableName: 'class', fieldName: TR_classField },
    { tableName: 'tags', fieldName: TR_tagsField },
    { tableName: 'analysis_profile', fieldName: TR_analysisProfileField },
  ];

  const styles = useStyles2(getStyles);
  const indexField = findIndexInForm(name, form);
  const indexMacAddress = findIndexInForm(TR_macAddressField, form);
  const indexDashboardName = findIndexInForm(TR_machineNameField, form);

  const [macAddress, setMacAddress] = useState(getDefaultValue(indexMacAddress, form));

  const [isLoading, setIsLoading] = useState({ getUid: true, getData: true });
  const [errorDeleting, setErrorDeleting] = useState('');
  const [metaDashboard, setMetaDashboard] = useState({ created: '', updated: '' });
  const [folders, setFolders] = useState<SelectableValue[]>([]);
  const [dashboardsInFolder, setDashboardsInFolder] = useState<SelectableValue[]>([]);

  const createdDate = metaDashboard.created && dateTimeFormat(metaDashboard.created);
  const updatedDate = metaDashboard.updated && dateTimeFormat(metaDashboard.updated);

  const isDeleteStep = steps.current === CurrentStep.DELETE_STEP1;

  const WRITE_API_URL = `api/datasources/proxy/uid/${datasourceJson.uid}`;
  const IDENTITY_CARD_ENDPOINT = `${WRITE_API_URL}/identity_card`;

  /** Get user folders */
  const getFolders = async () => {
    try {
      const folders = await getUserFolders();
      setFolders(folders);
    } catch (error) {
      console.log(error);
    }
  };

  const getDashboardJsonInfo = async (e: { label: string; folderId: number }) => {
    try {
      await getDashboardInfo(e, dispatch, setMetaDashboard, isDeleteStep, TR_ambientTemperature, TR_customClass);
      setIsLoading({ getUid: false, getData: false });
    } catch (error) {
      console.log(error);
      setIsLoading({ getUid: false, getData: false });
    }
  };

  const getDataFromDb = async (e: any) => {
    const rows = await getSelectedDashboardIdCard(e, datasourceMysql.uid);
    if (!rows?.length) {
      NotificationError({ message: 'Dashboard not found' });
      return;
    }

    // need to rebuild the dashboard id card result to fit the existant code.

    const dashboardIdCard = { columns: sqlTables.map((table) => table.tableName), rows: rows[0] };

    dashboardIdCard?.columns?.map((column: any, indexColumn: number) => {
      const mysqlColName = column;
      const findColumnIndexInFixedTables = sqlTables.findIndex((col) => col.tableName === column);

      if (findColumnIndexInFixedTables === -1) {
        return;
      }

      const formFieldName = sqlTables[findColumnIndexInFixedTables]?.fieldName;

      if (mysqlColName === MAC_ADDRESS_TABLE) {
        setMacAddress(dashboardIdCard.rows[indexColumn]);
      }

      if (mysqlColName === TAGS_TABLE && !isDeleteStep) {
        const dashboardTagsIndex = dashboardsList.findIndex(
          (dash) => dash.macAddress?.toUpperCase() === e.macAddress?.toUpperCase()
        );

        if (dashboardTagsIndex !== -1) {
          const valuesTags = dashboardsList[dashboardTagsIndex].tags;

          let tags = [];
          for (const tag of valuesTags) {
            if (tag) {
              tags.push({ label: tag, value: tag });
            }
          }
          tags.length && addToForm(dispatch, tags, formFieldName, mysqlColName);
        }
      } else {
        /**
         * Translation part.
         * If the form is in deletion mode, no need to translate all the fields
         */

        if (isDeleteStep) {
          return;
        }

        const HAS_TO_BE_TRANSLATED = hasValueToBeTranslated(mysqlColName, lang);
        if (HAS_TO_BE_TRANSLATED) {
          for (const [dicoKey] of Object.entries(multiLingualDictionary[mysqlColName])) {
            if (dicoKey === dashboardIdCard.rows[indexColumn]) {
              addToForm(dispatch, multiLingualDictionary[mysqlColName][dicoKey], formFieldName, mysqlColName);
              return;
            }
            addToForm(dispatch, dashboardIdCard.rows[indexColumn], formFieldName, mysqlColName);
          }
        } else {
          addToForm(dispatch, dashboardIdCard.rows[indexColumn], formFieldName, mysqlColName);
        }
      }
    });

    setIsLoading({ getUid: true, getData: false });
    getDashboardJsonInfo(e);
  };

  /**
   * Delete dashboard in Grafana and in db
   */
  const deleteDashboard = async (e: any) => {
    e.preventDefault();

    await getBackendSrv()
      .delete(
        IDENTITY_CARD_ENDPOINT,
        { mac_address: macAddress },
        {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async () => {
        await getBackendSrv()
          .delete(`${API_DASHBOARD_BY_UID}${uid}`) // delete in Grafana
          .catch(() => setErrorDeleting('Error while deleting dashboard in Asystom View'));
      })
      .then(() => {
        const filterDashboardDeleted = dashboardsNames.filter((dash) => dash.macAddress !== macAddress);
        dispatch({ type: 'SET_DASHBOARDS_LIST', payload: filterDashboardDeleted });
      })
      .then(() => {
        dispatch({ type: 'RESET_STEPS', payload: CurrentStep.DELETE_STEP1 });
        setIsDeleted && setIsDeleted(true);
        setMacAddress('');
      })
      .catch(() => setErrorDeleting('Error while deleting dashboard'));
  };

  const handleChange = (e: any) => {
    setMacAddress('');
    if (isDeleteStep) {
      setIsDeleted && setIsDeleted(false);
    }
    resetImage(dispatch);
    dispatch({ type: 'IS_DATA_LOADED', payload: false });
    setIsLoading({ getUid: true, getData: true });
    addToForm(dispatch, e.label, name, tableName, e.label);
    getDataFromDb(e);
  };

  const handleChangeFolders = (e: any) => {
    if (!e) {
      return;
    }
    setMacAddress('');
    dispatch({
      type: 'ADD_DASHBOARD_FOLDER',
      payload: {
        id: e.value,
        name: e.label,
      },
    });
    const dropDownDashboards = dashboardsNames.filter((dash) => dash.folderId === e.value);
    setDashboardsInFolder(dropDownDashboards);
    // reset filled form on folder change
    dispatch({
      type: 'RESET_STEPS',
      payload: payloadStep(currentModal),
    });
  };
  /**
   * Get user's folders.
   * Set default folder (General) if no folder is selected
   */
  useEffect(() => {
    getFolders();
    if (!Object.keys(dashboardFolder).length) {
      dispatch({
        type: 'ADD_DASHBOARD_FOLDER',
        payload: {
          id: 0,
          name: 'General',
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading.getData && !isLoading.getUid) {
      dispatch({ type: 'IS_DATA_LOADED', payload: true });
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Filter dashboards by folders
   */
  useEffect(() => {
    if (dashboardsList.length) {
      setDashboardsInFolder(dashboardsList.filter((dash) => dash.folderId === dashboardFolder.id));
    }
  }, [dashboardsList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.containerField}>
        <div className={styles.label}>{TR_folder?.toUpperCase()}</div>
        <div className={styles.inputContainer}>
          <Select
            width={50}
            options={folders}
            value={dashboardFolder.id}
            onChange={(e) => handleChangeFolders(e)}
            placeholder={TR_folderPlaceholder}
            invalid={isInvalid}
            noOptionsMessage={TR_folderNotFound}
            prefix={<Icon name={'folder-open'} />}
          />
        </div>
      </div>
      <div className={styles.containerField}>
        <div className={styles.label}>{name}</div>
        <div className={styles.inputContainer}>
          <Select
            width={50}
            options={dashboardsInFolder}
            key={`key__${form[indexDashboardName]?.value}`} // !! this key allows setting a null value to the select component !!
            value={form[indexDashboardName]?.value || ''}
            onChange={(e) => handleChange(e)}
            placeholder={loading ? TR_loadingDashboards : TR_placeHolderDashboardSelect}
            invalid={isInvalid}
            noOptionsMessage={TR_noDashboardFound}
            isLoading={loading}
          />
        </div>
      </div>
      {macAddress.length !== 0 && (
        <>
          <div className={styles.containerField}>
            <div className={styles.label}>{TR_macAddressField}</div>
            <div className={styles.inputContainer}>{macAddress}</div>
          </div>
          {isDeleteStep && (
            <>
              <div className={styles.containerField}>
                <div className={styles.label}>{TR_creationDashboard?.toUpperCase()}</div>
                <div className={styles.inputContainer}>{createdDate}</div>
              </div>
              <div className={styles.containerField}>
                <div className={styles.label}>{TR_updateDashboard?.toUpperCase()}</div>
                <div className={styles.inputContainer}>{updatedDate}</div>
              </div>
            </>
          )}
        </>
      )}
      {errorDeleting && <div className={styles.errorText}>{errorDeleting}</div>}
      {/* if it is deletion mode, display confirmation message of deletion and delete button */}
      {isDeleteStep && macAddress.length !== 0 && !isDeleted && (
        <ConfirmDeleteTextContainer dashboardName={form[indexField]?.value} deleteDashboard={deleteDashboard} />
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    containerField: css`
      ${formContainerStyle}
    `,
    label: css`
      width: 30%;
      max-width: 250px;
      min-width: 215px;
      background-color: ${theme.colors.background.secondary};
      height: 32px;
      text-align: left;
      text-justify: center;
      margin-right: 10px;
      padding: 7px;
      font-size: 14px;
      color: ${theme.colors.text};
      font-weight: 400;
    `,
    inputContainer: css`
      width: 100%;
    `,
    errorText: css`
      color: ${theme.colors.text};
      font-weight: 500;
      padding: 10px;
      font-size: 16px;
      text-align: center;
    `,
  };
};
