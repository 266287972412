import { Button, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { CurrentStep, Process } from 'app-context/types';
import { css } from '@emotion/css';
import { stepBackward, stepForward, stepOne } from 'helpers/helpersForm';
import { closeModal } from 'helpers/helpersModal';
import {
  create_bg_button,
  create_bg_button_hover,
  create_button_txt,
  update_bg_button,
  update_bg_button_hover,
  update_button_txt,
  RED,
} from 'helpers/helpersStyle';
import React, { useEffect } from 'react';

interface FooterProps {
  process: Process;
}

/**
 * Contains the main form buttons controllers displayed in the footer of the form.
 * Each button is displayed depending on mode (creation, update or deletion) and form steps.
 * Allows to go forward or backward in steps form.
 */

export const Footer: React.FunctionComponent<FooterProps> = ({ process }) => {
  const { state, dispatch } = useAppState();
  const { steps, dictionary_uiElements, currentModal, isDataLoaded, error } = state;
  const styles = useStyles2(() => getStyles(process));

  const {
    TR_footerStepsCancel: CANCEL,
    TR_footerStepsPrevious: PREVIOUS,
    TR_footerStepsEdit: EDIT,
    TR_footerStepsNext: NEXT,
    TR_footerStepSubmitUpdate: UPDATE,
    TR_footerStepsSubmit: CREATE,
    TR_close: CLOSE,
  } = dictionary_uiElements;

  const buttonsLeft = () => {
    switch (true) {
      case steps.current === CurrentStep.DELETE_STEP1: {
        return (
          <Button
            size="md"
            variant="secondary"
            fill="text"
            onClick={() => closeModal(process, dispatch, false)}
            className={styles.secondaryButton}>
            {CLOSE}
          </Button>
        );
      }
      /**
       * Display Cancel button on first steps of each process.
       */
      case steps.current === CurrentStep.UPDATE_STEP1 ||
        (steps.current === CurrentStep.GENERAL_INFOS && process === Process.create): {
        return (
          <Button
            size="md"
            variant="secondary"
            fill="text"
            onClick={() => closeModal(process, dispatch, false)}
            className={styles.secondaryButton}>
            {CANCEL}
          </Button>
        );
      }
      /**
       * Display Previous button in all other steps
       */
      case (steps.current === CurrentStep.GENERAL_INFOS && process === Process.update) ||
        steps.current === CurrentStep.MOUNTING_LOCATION ||
        steps.current === CurrentStep.ANALYSIS_PROFILE: {
        return (
          <Button
            size="md"
            variant="secondary"
            fill="text"
            onClick={() => stepBackward(state, dispatch)}
            className={styles.secondaryButton}>
            {PREVIOUS}
          </Button>
        );
      }
      /**
       * Display Edit button in the summary step
       */
      case steps.current === CurrentStep.SUMMARY: {
        return (
          <Button
            size="md"
            variant="secondary"
            fill="text"
            className={styles.secondaryButton}
            onClick={() => stepOne(dispatch)}>
            {EDIT}
          </Button>
        );
      }

      default:
        return;
    }
  };

  const buttonRight = () => {
    const currentStep = steps.current;

    switch (true) {
      // Update mode
      case currentStep === CurrentStep.UPDATE_STEP1: {
        if (isDataLoaded) {
          return (
            <button className={styles.button} onClick={() => stepForward(state, dispatch)} type="submit">
              {NEXT}
            </button>
          );
        }
        return;
      }

      case currentStep && currentStep < CurrentStep.SUMMARY && currentStep !== CurrentStep.DELETE_STEP1: {
        return (
          <button className={styles.button} onClick={() => stepForward(state, dispatch)} type="submit">
            {NEXT}
          </button>
        );
      }

      case currentStep === CurrentStep.SUMMARY: {
        return (
          <button className={styles.button} onClick={() => stepForward(state, dispatch)} type="submit">
            {currentModal === Process.update ? UPDATE : CREATE}
          </button>
        );
      }

      default:
        return;
    }
  };

  useEffect(() => {
    isDataLoaded && buttonRight();
  }, [isDataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={styles.container}>
        {buttonsLeft()}
        {!error.datasource && buttonRight()}
      </div>
    </div>
  );
};

const getStyles = (process: Process) => {
  let bgColor = '';
  let bgColorHover = '';
  let textColor = '';
  /**
   * Get the colors of buttons for each process
   */

  switch (process) {
    case Process.create:
      bgColor = create_bg_button;
      bgColorHover = create_bg_button_hover;
      textColor = create_button_txt;
      break;
    case Process.update:
      bgColor = update_bg_button;
      bgColorHover = update_bg_button_hover;
      textColor = update_button_txt;
      break;

    default:
      bgColorHover = RED;
      break;
  }

  return {
    container: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `,
    button: css`
      min-width: 125px;
      background: ${bgColor};
      padding: 5px 28px;
      height: 45px;
      border: none;
      color: ${textColor};
      font-size: 18px;
      font-weight: 500;
      border-radius: 25px;
      &:hover {
        background: ${bgColorHover};
      }
    `,
    secondaryButton: css`
      color: ${bgColorHover};
      &:hover {
        color: ${bgColorHover};
        opacity: 0.6;
      }
    `,
    containerSubmit: css`
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      height: 100px;
    `,
  };
};
