import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
export const getFormStyles = (theme: GrafanaTheme2) => {
  return {
    fieldContainer: css`
      width: 100%;
      max-width: 635px;
      min-width: 300px;
      height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 12px;
    `,
    fieldClass: css`
      width: 100%;
      max-width: 635px;
      min-width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 12px;
    `,
    label: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text.primary};
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      margin-right: 10px;
      max-width: 250px;
      min-width: 225px;
      padding: 7px;
      text-align: left;
      text-justify: center;
      width: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    labelMin: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text.primary};
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      margin-right: 5px;
      max-width: 150px;
      min-width: 150px;
      padding: 7px;
      text-align: left;
      text-justify: center;
      width: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    inputContainer: css`
      width: 100%;
    `,
    listElement: css`
      margin-left: 15px;
    `,
    descriptionContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: self-start;
    `,
    iconContainer: css`
      width: 32px;
      text-align: center;
    `,
  };
};
