import { mysqlRequest } from 'services/requests';
import { NotificationError } from 'shared/components/NotificationMessage';

/**
 * Retreive all options from database for each field of dictionnary json
 * (class, process_function, sensor_location, subtype, type, tags, manufacturer, model, analysis_profile)
 */
export const getOptions = async (datasourceUid: string, table: string) => {
  /**
   * mysql> describe subtype;
  +-------+-------------+------+-----+---------+-------+
  | Field | Type        | Null | Key | Default | Extra |
  +-------+-------------+------+-----+---------+-------+
  | item  | varchar(50) | NO   | PRI | NULL    |       |
  +-------+-------------+------+-----+---------+-------+
   */
  let sqlRequest = `SELECT * FROM ${table}`;
  const queryResult = await mysqlRequest(datasourceUid, sqlRequest).catch((err) => {
    NotificationError(err);
  });
  return queryResult;
};
