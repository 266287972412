import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { Process } from 'app-context/types';
import { css } from '@emotion/css';
import { isFilled, findIndexInForm } from 'helpers/helpersForm';
import React, { useEffect, useState } from 'react';
import { MAC_ADRESS_PREFIX } from 'helpers/constants';

interface SummaryLinesProps {
  fieldName: string;
  tableName: string;
}

export const SummaryRows: React.FunctionComponent<SummaryLinesProps> = ({ fieldName, tableName }) => {
  const { state } = useAppState();
  const { dictionary_uiElements, form, currentModal } = state;
  const [prefix, setPrefix] = useState('');
  const styles = useStyles2(getStyles);
  const macAddressIndex = findIndexInForm(dictionary_uiElements.TR_macAddressField, form);

  const getMacAddressPrefix = () => {
    const macAddressPrefix =
      currentModal === Process.update ? form[macAddressIndex]?.value.slice(0, 12) : MAC_ADRESS_PREFIX;
    setPrefix(macAddressPrefix);
  };

  useEffect(() => {
    if (currentModal === Process.create || currentModal === Process.update) {
      getMacAddressPrefix();
    }
  }, [currentModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div key={fieldName} className={styles.containerFields}>
      <div className={styles.label}>{fieldName}</div>
      <div className={styles.filledFields}>{isFilled(tableName, form, currentModal, prefix)}</div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    containerFields: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    label: css`
      width: 30%;
      max-width: 250px;
      min-width: 220px;
      height: 27px;
      text-align: left;
      text-justify: center;
      margin-right: 10px;
      padding: 4px;
      font-size: 14px;
      color: ${theme.colors.text.secondary};
      font-weight: 400;
      margin-bottom: 5px;
      background-color: ${theme.colors.background.secondary};
    `,
    filledFields: css`
      width: 100%;
      height: 27px;
      text-align: left;
      text-justify: center;
      margin-right: 10px;
      padding: 4px;
      font-size: 14px;
      color: ${theme.colors.text};
      font-weight: 500;
      margin-bottom: 5px;
      border-bottom: 1px solid ${theme.colors.border.weak};
    `,
  };
};
