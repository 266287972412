import { Spinner, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from 'app-context/AppStateContext';
import { Process } from 'app-context/types';
import { css } from '@emotion/css';
import { GREEN, ORANGE, RED } from 'helpers/helpersStyle';
import React, { useEffect, useState } from 'react';
import { MenuButton } from './MenuButton';

const AsystomLogo = require('../img/grafana_asystom_icon.svg') as string;

/**
 * Display wizard tool menu on main dashboard
 */
export const MenuContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useAppState();
  const { user, dictionary_uiElements, datasourceMysql } = state;
  const { TR_addDashboard, TR_updateDashboard, TR_deleteDashboard } = dictionary_uiElements;

  const buttonProps = [
    {
      wizardFeature: Process.create,
      text: TR_addDashboard,
      iconName: 'plus',
      background: GREEN,
    },
    {
      wizardFeature: Process.update,
      text: TR_updateDashboard?.toUpperCase(),
      iconName: 'sync',
      background: ORANGE,
    },
    {
      wizardFeature: Process.delete,
      text: TR_deleteDashboard,
      iconName: 'trash-alt',
      background: RED,
    },
    {
      wizardFeature: Process.manage_single_pages,
      text: 'MANAGE SINGLE PAGES (ADMIN)',
      iconName: 'lock',
      background: 'black',
    },
  ];
  const styles = useStyles2(getStyles);

  useEffect(() => {
    datasourceMysql?.id && setIsLoading(false);
  }, [datasourceMysql]);

  return (
    <div className={styles.mainContainer}>
      {isLoading && (
        <div className={styles.center}>
          <Spinner />
        </div>
      )}
      {user.orgRole !== 'Viewer' &&
        !isLoading &&
        buttonProps.map(({ wizardFeature, text, iconName, background }) => {
          if (wizardFeature === Process.manage_single_pages && user.orgRole !== 'Admin') {
            return null;
          }
          return (
            <MenuButton
              text={text}
              iconName={iconName}
              key={wizardFeature}
              background={background}
              modalProcess={wizardFeature}
            />
          );
        })}
      {user.orgRole === 'Viewer' && (
        <div className={styles.imageContainer}>
          <div style={{ paddingRight: '8px' }}>
            <img src={AsystomLogo} height="60px" width="auto" />
          </div>
          <div>
            <div className={styles.orgTitle}>{user?.orgName?.toUpperCase()}</div>
            <div className={styles.viewer}>Viewer mode</div>
          </div>
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      width: 100%;
      max-width: 1300px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      margin-top: 5px;
    `,
    imageContainer: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    orgTitle: css`
      font-size: 25px;
      font-weight: 500;
      color: #168158;
    `,
    viewer: css`
      color: #168158;
    `,
    center: css`
      text-align: center;
    `,
  };
};
