import { GrafanaTheme2 } from '@grafana/data';
import { Badge, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { CLASS_TABLE, MACHINE_FILTER_PREFIX, MACHINE_TAG } from 'helpers/constants';
import { findIndexInForm } from 'helpers/helpersForm';
import { DEFAULT_IMAGE } from 'helpers/URLS';
import React, { useState, useEffect } from 'react';
import { SectionTitle } from 'shared/SectionTitle';
import { formSteps } from '../../formSteps';
import { SummaryRows } from './SummaryRows';

/**
 * Summary of the form fields
 */

export const Summary: React.FunctionComponent = () => {
  const { state } = useAppState();
  const { dictionary_uiElements, form, temperatureUnit, lengthUnit } = state;
  const { TR_tagsField, TR_temperatureUnit, TR_lengthUnit, TR_meter, TR_inch } = dictionary_uiElements;

  const [additionalTags, setAdditionalTags] = useState([]);
  const [machineTags, setMachineTags] = useState([]);

  const styles = useStyles2(getStyles);
  const steps = formSteps(dictionary_uiElements);
  const { summary } = steps[0];
  const tagsIndex = findIndexInForm(TR_tagsField, form);
  const displayLength = lengthUnit === 'meter' ? TR_meter : TR_inch;

  useEffect(() => {
    /**
     * Keep tags that are not 'Machine" and that don't have 'mac_' prefixes.
     */
    if (tagsIndex !== -1 && form.length) {
      const TAGS = form[tagsIndex].value?.filter(
        (tag: { value: string; label: string }) =>
          tag.label !== MACHINE_TAG && typeof tag.value === 'string' && !tag.value?.includes(MACHINE_FILTER_PREFIX)
      );
      setAdditionalTags(TAGS);

      const machineFilter = form[tagsIndex].value
        ?.filter(
          (tag: { value: string }) => typeof tag.value === 'string' && tag.value?.includes(MACHINE_FILTER_PREFIX)
        )
        ?.map((tag: { value: string }) => tag.value?.split('_')[1]);
      setMachineTags(machineFilter);
    }
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  const summaryListRows = React.useMemo(() => {
    let fields = summary.fields;
    if (form.length) {
      const profileIndex = findIndexInForm(dictionary_uiElements.TR_analysisProfileField, form);
      if (profileIndex !== -1) {
        if (form[profileIndex].value === dictionary_uiElements.TR_valve) {
          fields = summary.fields.filter((field) => field.tableName !== CLASS_TABLE);
        }
      }
    }
    return fields;
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>
        <SectionTitle title={summary.sectionTitle} />
      </div>
      <div className={styles.formContainer}>
        <div className={styles.innerForm}>
          <div className={styles.containerCols}>
            <div className={styles.badgeContainer}>
              {additionalTags &&
                additionalTags?.length !== 0 &&
                additionalTags?.map((tag: { label: string }, index: number) => (
                  <Badge
                    text={tag.label}
                    color="blue"
                    key={`${index}-${tag.label}`}
                    className={css`
                      margin-right: 2px;
                    `}
                  />
                ))}
            </div>
            <div className={styles.col}>
              {/* Display fields form */}
              {summaryListRows?.map(({ tableName, name }) => {
                return <SummaryRows fieldName={name} tableName={tableName} key={`${tableName}-${name}`} />;
              })}
              {/*  Treat machine filter tag separately because it's part of "tags" */}
              <div className={styles.containerFields}>
                <div className={styles.label}>MACHINE TAG</div>
                <div className={styles.filledFields}>{machineTags[0]}</div>
              </div>
              {/* Temperature unit */}
              <div className={styles.containerFields}>
                <div className={styles.label}>{TR_temperatureUnit?.toUpperCase()}</div>
                <div className={styles.filledFields}>{temperatureUnit}</div>
              </div>
              {/* Length unit */}
              <div className={styles.containerFields}>
                <div className={styles.label}>{TR_lengthUnit?.toUpperCase()}</div>
                <div className={styles.filledFields}>{displayLength}</div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img
                src={state.fileImage !== '#' ? `${state.fileImage}` : DEFAULT_IMAGE}
                width="320px"
                height="auto"
                className={styles.file}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    `,
    formContainer: css`
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    `,
    sectionTitle: css`
      font-size: 12px;
      color: #808080;
      font-weight: 700;
      text-align: left;
      height: 33px;
      width: 100%;
      margin-bottom: 5px;
    `,
    containerCols: css`
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 10px;
      align-items: flex-start;
    `,
    innerForm: css`
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    `,
    file: css`
      padding: 10px;
      max-height: 385px;
    `,
    col: css`
      width: 100%;
    `,
    badge: css`
      margin-right: 5px;
    `,
    badgeContainer: css`
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: self-start;
      margin-bottom: 12px;
    `,
    containerFields: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    label: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text.secondary};
      font-size: 14px;
      font-weight: 400;
      height: 27px;
      margin-bottom: 5px;
      margin-right: 10px;
      max-width: 250px;
      min-width: 220px;
      padding: 4px;
      text-align: left;
      text-justify: center;
      width: 30%;
    `,
    filledFields: css`
      width: 100%;
      height: 27px;
      text-align: left;
      text-justify: center;
      margin-right: 10px;
      padding: 4px;
      font-size: 14px;
      color: ${theme.colors.text};
      font-weight: 500;
      margin-bottom: 5px;
      border-bottom: 1px solid ${theme.colors.border.weak};
    `,
  };
};
