import { config, getTemplateSrv } from '@grafana/runtime';
import { ThemeContext } from '@grafana/ui';
import {
  AppState,
  ErrorProps,
  Process,
  TemplatesVar,
  FolderProps,
  DashboardListProps,
  MacAddressProps,
  IsoClassificationsProps,
} from 'app-context/types';
import * as React from 'react';
import { TranslationProps } from './translation-types';

/**
 * Initialize default state and pass it to the main app component
 */

export const withData = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<{ initialState: AppState }>>
) => {
  // eslint-disable-next-line react/display-name
  return ({ children }: React.PropsWithChildren<{}>) => {
    const userPath = config.bootData.user;
    const GRAFANA_USER = {
      isGrafanaAdmin: userPath.isGrafanaAdmin,
      orgName: userPath.orgName,
      orgId: userPath.orgId,
      orgRole: userPath.orgRole,
    };

    const currentTheme = React.useContext(ThemeContext);
    const langSelectQuery: string = getTemplateSrv().replace(`$${TemplatesVar.lang}`);

    const defaultState = {
      dashboardFolder: {} as FolderProps,
      dictionary_uiElements: {} as TranslationProps,
      dropdownMenusOptions: [],
      datasourceMysql: [],
      datasourceInflux: [],
      datasourceJson: [],
      user: GRAFANA_USER,
      theme: currentTheme,
      error: {} as ErrorProps,
      currentModal: Process.none,
      selectedFolder: { label: 'General', value: 0 },
      modals: [
        {
          process: Process.create,
          isOpen: false,
        },
        {
          process: Process.update,
          isOpen: false,
        },
        {
          process: Process.delete,
          isOpen: false,
        },
        {
          process: Process.manage_single_pages,
          isOpen: false,
        },
      ],
      steps: {
        current: 1,
        stepsStatus: {
          // First and unique step of deletion process
          step99: {
            isSubmit: false,
            isValid: false,
          },
          // First and unique step of manage single pages
          step98: {
            isSubmit: false,
            isValid: false,
          },
          // Update first step
          step0: {
            isSubmit: false,
            isValid: false,
          },
          // Creation first step
          step1: {
            isSubmit: false,
            isValid: false,
          },
          // Next steps of update and creation
          step2: {
            isSubmit: false,
            isValid: false,
          },
          step3: {
            isSubmit: false,
            isValid: false,
          },
          // Summary step
          step4: {
            isSubmit: false,
            isValid: false,
          },
          // Final step (send dashboard to db and Grafana)
          step5: {
            isSubmit: false,
            isValid: false,
          },
        },
      },
      dashboardsList: [] as DashboardListProps[],
      form: [],
      fileImage: '#',
      validation: [],
      multiLingualDictionary: {},
      lang: langSelectQuery,
      fileImageName: '',
      uid: '',
      isDataLoaded: false,
      panelOptions: {
        macAddressPrefixOption: '',
      },
      macAddresses: [] as MacAddressProps[],
      temperatureUnit: 'Celsius (°C)',
      lengthUnit: 'meter',
      customIso: { alert: '', warning: '' },
      isoClassifications: {} as IsoClassificationsProps,
    };

    const [initialState] = React.useState<AppState>(defaultState);
    return <WrappedComponent initialState={initialState}>{children}</WrappedComponent>;
  };
};
