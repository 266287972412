import React from 'react';
import { useStyles2, Icon, IconName } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useAppState } from 'app-context/AppStateContext';
import { Process } from 'app-context/types';
import { toggleVisible } from 'helpers/helpersModal';
import { payloadStep } from 'helpers/helpersForm';

interface MainButtonProps {
  text: string;
  iconName: string;
  background: string;
  modalProcess: Process;
}
/**
 * Display buttons of the wizard tool menu
 */

export const MenuButton = ({ text, iconName, background, modalProcess }: MainButtonProps) => {
  const { dispatch } = useAppState();
  const styles = useStyles2((theme) => getStyles(background, theme));

  const toggleModal = () => {
    toggleVisible(modalProcess, dispatch, true);
    dispatch({
      type: 'RESET_STEPS',
      payload: payloadStep(modalProcess),
    });
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={() => toggleModal()} id={`button_${text}`}>
        <Icon name={iconName as IconName} className={styles.icon} size={'sm'} />
        {text}
      </button>
    </div>
  );
};

const getStyles = (background: string, theme: GrafanaTheme2) => {
  return {
    container: css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    `,
    button: css`
      align-items: center;
      background-color: ${theme.colors.background.secondary};
      border-radius: 3px;
      border: none;
      color: ${theme.colors.text};
      display: flex;
      flex-direction: row;
      font-size: 14px;
      justify-content: flex-start;
      margin-bottom: 4px;
      min-width: 265px;
      padding: 5px;
      width: 100%;
      font-weight: 500;
      transition: background-color 300ms ease-out 100ms;
      &:hover {
        color: ${theme.colors.background.primary};
        background-color: ${background};
        border: none;
      }
    `,
    icon: css`
      height: 16px;
      margin-right: 10px;
      width: 16px;
    `,
  };
};
