import { escapeSpecialChars } from './helpersForm';
import { mysqlRequest } from 'services/requests';

export const getSelectedDashboardIdCard = async (e: { label: string; macAddress: string }, dsUid: string) => {
  const selectedDashboardName = escapeSpecialChars(e.label);
  const sqlQuery = `SELECT machine_name, mac_address, process_function, sensor_location, sensor_orientation, manufacturer, model, type, class, tags, analysis_profile FROM identity_card WHERE machine_name = "${selectedDashboardName}" AND mac_address="${e.macAddress}"`;

  const queryResult = await mysqlRequest(dsUid, sqlQuery);

  return queryResult;
};
