import React, { useState } from 'react';
import { FileUpload, Icon, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { DEFAULT_IMAGE } from 'helpers/URLS';
import { useAppState } from 'app-context/AppStateContext';
import { findIndexInForm } from 'helpers/helpersForm';
import { css } from '@emotion/css';

interface Props {
  file: string | File;
  setFile: (value: string) => void;
}

export const DownloadImageContainer: React.FunctionComponent<Props> = ({ file, setFile }) => {
  const [isFilePreview, setIsFilePreview] = useState(false);

  const { state, dispatch } = useAppState();

  const { dictionary_uiElements: dictionary_uiElements, form, error } = state;
  const dashboardNameIndex = findIndexInForm(dictionary_uiElements.TR_machineNameField, form);
  const styles = useStyles2(getStyles);

  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      setIsFilePreview(true);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        dispatch({ type: 'SET_ERROR', payload: { errorName: 'badRequest', text: '' } });
        setFile(e.target.result);
        $('#preview').attr('src', e.target.result as any);
        const fileName = form[dashboardNameIndex].value.split(' ').join('_') + '-' + Date.now() + '.jpg';
        dispatch({ type: 'ADD_FILE_NAME', payload: fileName });
        dispatch({ type: 'ADD_IMAGE_FILE', payload: e.target.result });
      };
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  };

  const removeImage = () => {
    const spans = $('body').find("[aria-label='File name']");
    spans.text('');
    setIsFilePreview(false);
    setFile('#');
    dispatch({ type: 'ADD_IMAGE_FILE', payload: '#' });
    dispatch({ type: 'ADD_FILE_NAME', payload: '' });
    dispatch({ type: 'SET_ERROR', payload: { errorName: 'badRequest', text: '' } });
  };

  return (
    <div className={styles.containerPreview}>
      <div>
        <FileUpload onFileUpload={({ currentTarget }) => readURL(currentTarget)} accept="image/*">
          {dictionary_uiElements.TR_uploadButton}
        </FileUpload>
      </div>
      <div className={styles.containerImageAndButton}>
        {error.badRequest ? (
          <div>{error.badRequest}</div>
        ) : (
          <div>
            {isFilePreview || file !== '#' ? (
              <img src={`${file}`} width="auto" height="200px" id="preview" style={{ maxWidth: '500px' }} />
            ) : (
              <img src={DEFAULT_IMAGE} width="280px" height="auto" />
            )}
          </div>
        )}
        {file !== '#' && (
          <button className={styles.removeButton} onClick={() => removeImage()}>
            <Icon name="trash-alt" size="xl" />
          </button>
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    containerPreview: css`
      display: flex;
      flex-direction: column;
      width: 70%;
      margin-top: 40px;
    `,
    form: css`
      width: 100%;
      display: flex;
      justify-content: center;
    `,
    containerImageAndButton: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 14px auto 0 auto;
    `,
    removeButton: css`
      border: none;
      background-color: ${theme.colors.background.secondary};
      &:hover {
        opacity: 0.7;
      }
    `,
  };
};
