import { getVibratoryThreshold } from 'helpers/helpersDashboards';
import { SelectableValue } from '@grafana/data';
import {
  Process,
  Action,
  DashboardProps,
  Orientation,
  ErrorTypes,
  TemplatesVar,
  AppState,
  CurrentStep,
  FormProps,
  DicoProps,
  IsoClassificationsProps,
  ErrorProps,
  DashboardInfos,
} from 'app-context/types';
import {
  ANALYSIS_PROFILE_TABLE,
  CLASS_TABLE,
  FUNCTION_TABLE,
  MAC_ADDRESS_TABLE,
  MACHINE_FILTER_TABLE,
  MACHINE_TAG,
  MACHINE_NAME_TABLE,
  MANUFACTURER_TABLE,
  MODEL_TABLE,
  SENSOR_LOCATION_TABLE,
  SENSOR_ORIENTATION_TABLE,
  TAGS_TABLE,
  TYPE_TABLE,
  MAC_ADRESS_PREFIX,
} from 'helpers/constants';
import { formSteps } from 'form/formSteps';
import { getBackendSrv } from '@grafana/runtime';
import { TranslationProps } from '../app-context/translation-types';
import { API_QUERY_DB } from './URLS';

/**
 * Check the validity of field form on submit and set input appropriate style
 */

export const isInputInvalid = (
  field: string,
  step: any,
  isSubmit: boolean | undefined,
  form: FormProps[],
  error: ErrorProps,
  customIso: { warning: string; alert: string },
  dico: TranslationProps
) => {
  const findIndexFieldForm = form.findIndex((formField) => formField.fieldName === field);
  const findFieldStep = step.fields.findIndex((stepField: any) => stepField.name === field);
  const { TR_machineNameField, TR_classField, TR_customClass } = dico;

  if (field === TR_machineNameField && error.name !== '') {
    return true;
  }

  if (isSubmit) {
    // handle custom power class
    if (
      field === TR_classField &&
      form[findIndexFieldForm]?.value === TR_customClass &&
      (!customIso.warning || !customIso.alert || error.customThresholds !== '')
    ) {
      return true;
    }
    // Invalid if required field doesn't exist in form, or value is empty
    if ((findIndexFieldForm === -1 || !form[findIndexFieldForm]?.value) && step.fields[findFieldStep].isRequired) {
      return true;
    }
  }
  return false;
};

/** Get user folders */
export const getUserFolders = async (): Promise<SelectableValue[]> => {
  const folderOptions: SelectableValue[] = [{ label: 'General', value: 0 }];

  await getBackendSrv()
    .get(`/api/search?folderIds=0`) // General folder and all other folders
    .then(async (result) => {
      if (!result) {
        return;
      }
      for (const folder of result) {
        if (folder.type === 'dash-folder') {
          folderOptions.push({ label: folder.title, value: folder.id });
        }
      }
    });
  return folderOptions;
};

/**
 * Check if value exists, get default value from store
 */
export const getDefaultValue = (index: number, form: FormProps[]) => {
  return index !== -1 ? form[index]?.value : ('' as string);
};

/**
 * Get the index of the dictionary entries, used to display options in select element
 */
export const findIndexDictionnary = (entry: string, dropdownMenusOptions: DicoProps[]) => {
  const index = dropdownMenusOptions.findIndex((field) => field.name === entry);
  return index;
};

/**
 * Get the index in the form of the field name
 */
export const findIndexInForm = (name: string, form: FormProps[]) => {
  const index = form?.findIndex((formField) => formField.fieldName === name);
  return index;
};

/**
 * Retreive input placeholders
 */
export const getPlaceholder = (tableName: string, dictionary_uiElements: TranslationProps) => {
  const {
    TR_placeHolderClass,
    TR_placeHolderFunction,
    TR_placeHolderTags,
    TR_placeHolderType,
    // TR_placeHolderSubtype,
    TR_placeHolderOrientation,
    TR_placeHolderLocation,
    TR_placeHolderManufacturer,
    TR_placeHolderModel,
    TR_placeHolderMachineName,
    TR_placeHolderAnalysisProfile,
    TR_placeHolderMachineFilter,
  } = dictionary_uiElements;

  switch (tableName) {
    case CLASS_TABLE:
      return TR_placeHolderClass;
    case FUNCTION_TABLE:
      return TR_placeHolderFunction;
    case TAGS_TABLE:
      return TR_placeHolderTags;
    case TYPE_TABLE:
      return TR_placeHolderType;
    case SENSOR_ORIENTATION_TABLE:
      return TR_placeHolderOrientation;
    case SENSOR_LOCATION_TABLE:
      return TR_placeHolderLocation;
    case MANUFACTURER_TABLE:
      return TR_placeHolderManufacturer;
    case MODEL_TABLE:
      return TR_placeHolderModel;
    case MACHINE_NAME_TABLE:
      return TR_placeHolderMachineName;
    case ANALYSIS_PROFILE_TABLE:
      return TR_placeHolderAnalysisProfile;
    case MACHINE_FILTER_TABLE:
      return TR_placeHolderMachineFilter;
    default:
      return '';
  }
};

export const escapeSpecialChars = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .replace(/"/g, '\\"')
    .replace(/'/g, "\\'")
    .replace(/,/g, '\\,')
    .replace(/;/g, '\\;')
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(/</g, '\\<')
    .replace(/>/g, '\\>')
    .trim();
};

/**
 * Check if field is filled
 */
export const isFilled = (tableName: string, form: any, currentModal: Process, macAddressPrefix?: string) => {
  const findField = form.findIndex((formField: { tableName: string }) => formField.tableName === tableName);
  const formValue = form[findField]?.value;
  // if creating dashboard and field is mac_address then add custom prefix to it.
  if (tableName === MAC_ADDRESS_TABLE) {
    return currentModal === Process.update ? formValue : `${macAddressPrefix}${formValue}`;
  }

  return findField !== -1 && formValue ? formValue : '-';
};

/**
 * Add value to form
 */
export const addToForm = (
  dispatch: (value: Action) => void,
  formValue: string | any[],
  fieldName: string,
  tableName: string,
  labelValue?: string
) => {
  dispatch({
    type: 'ADD_TO_FORM',
    payload: {
      value: formValue,
      fieldName: fieldName,
      tableName: tableName,
      label: labelValue ? labelValue : '',
    },
  });
};

/**
 * Dispatch error
 */
export const setError = (errorName: ErrorTypes, errorText: string, dispatch: (value: Action) => void) => {
  dispatch({
    type: 'SET_ERROR',
    payload: {
      errorName: errorName,
      text: errorText,
    },
  });
};

/**
 * Fields to translate before to be sent to DB : analysis_profile, class, process_function, sensor_location, subtype, type
 */
export const hasValueToBeTranslated = (field: string, lang: string) =>
  (field === ANALYSIS_PROFILE_TABLE ||
    field === CLASS_TABLE ||
    field === FUNCTION_TABLE ||
    field === SENSOR_LOCATION_TABLE ||
    field === TYPE_TABLE) &&
  lang !== 'EN';

export const isCreatableField = (field: string) =>
  field === FUNCTION_TABLE ||
  field === SENSOR_LOCATION_TABLE ||
  field === TYPE_TABLE ||
  field === MANUFACTURER_TABLE ||
  field === MODEL_TABLE;

export const getUpdatedTemplatingList = (
  dashboardToUpdate: DashboardProps,
  orientationValues: Orientation,
  fileImageName: string,
  lengthUnit: string
) => {
  // Keep all templates variables except 'imageFile', 'X', 'Y', 'Z' and length unit
  const templatesCopy = dashboardToUpdate.dashboard.templating.list
    .filter((template) => template.name !== TemplatesVar.image)
    .filter((template) => template.name !== TemplatesVar.X)
    .filter((template) => template.name !== TemplatesVar.Y)
    .filter((template) => template.name !== TemplatesVar.Z)
    .filter((template) => template.name !== TemplatesVar.lengthUnit);

  // Update template var list with orientation values and 'imageFile'
  const templatesToInsert = [
    { name: TemplatesVar.X, value: orientationValues['X'].value },
    { name: TemplatesVar.Y, value: orientationValues['Y'].value },
    { name: TemplatesVar.Z, value: orientationValues['Z'].value },
    { name: TemplatesVar.image, value: fileImageName },
    { name: TemplatesVar.lengthUnit, value: lengthUnit },
  ];

  templatesToInsert.map((template) => {
    templatesCopy.push({
      current: {
        text: template.value,
        value: template.value,
      },
      hide: 2,
      label: null,
      name: template.name,
      options: [
        {
          text: template.value,
          value: template.value,
        },
      ],
      query: template.value,
      skipUrlSync: false,
      type: 'constant',
    });
  });
  return templatesCopy;
};

/**
 * Handle tags
 */
export const handleUpdateTags = (tags: any[], searchMachine: (value: string) => boolean) => {
  let tagValues = [] as string[];
  // if user has added tags in his form

  tags.forEach((tag: any) => {
    const tagValue = tag.value;
    tagValues.push(tagValue);
  });

  // Check if default tag 'Machine' is in tags array
  const machineInTags = tagValues.findIndex(searchMachine);
  if (machineInTags === -1) {
    tagValues.push(MACHINE_TAG);
  }
  // Concatenate all tags separated by a comma, in a single string
  return tagValues.map((tag) => escapeSpecialChars(tag)).join();
};

/**
 * Remove image (file and name)
 */

export const resetImage = (dispatch: (value: Action) => void) => {
  dispatch({ type: 'ADD_FILE_NAME', payload: '' });
  dispatch({ type: 'ADD_IMAGE_FILE', payload: '#' });
};

export const getDashboardJsonInfos = (
  indexTags: number,
  orientationValues: Orientation,
  searchMachineTag: (tag: { value: string }) => boolean,
  state: AppState,
  customIso: { warning: string; alert: string }
) => {
  const { currentModal, datasourceInflux, datasourceMysql, dictionary_uiElements, fileImageName, form, lang, theme } =
    state;
  const { TR_macAddressField, TR_machineNameField } = dictionary_uiElements;
  const indexBeacon = findIndexInForm(TR_macAddressField, form);
  const indexDashboardName = findIndexInForm(TR_machineNameField, form);

  let dashboardInfos = {} as DashboardInfos;
  dashboardInfos.dashboardName = form[indexDashboardName].value.trim();
  dashboardInfos.beacon =
    currentModal === Process.update
      ? form[indexBeacon].value.toLowerCase()
      : `${MAC_ADRESS_PREFIX}${form[indexBeacon].value}`.toLowerCase();

  dashboardInfos.lang = lang;
  dashboardInfos.style = theme.name;
  dashboardInfos.fileImageName = fileImageName;
  dashboardInfos.mysql = { type: 'mysql', uid: datasourceMysql.uid };
  dashboardInfos.influx = { type: 'influxdb', uid: datasourceInflux.uid };
  dashboardInfos.X = orientationValues['X'].value;
  dashboardInfos.Y = orientationValues['Y'].value;
  dashboardInfos.Z = orientationValues['Z'].value;
  dashboardInfos.customIso = customIso;
  /**
   * Add default tag 'Machine' in json (tag used in Main Dashboard and Synthetic Dashboard)
   */
  if (indexTags !== -1 && form[indexTags].value.length) {
    dashboardInfos.tags = form[indexTags].value.map((tag: { value: string }) => tag.value);
    // dashboardInfos.tags = form[indexTags].value.map((tag: string) => tag);

    const findMachine = form[indexTags].value.findIndex(searchMachineTag);
    // if 'Machine' is not in user tags, add it
    if (findMachine === -1) {
      dashboardInfos.tags.push(MACHINE_TAG);
    }
  } else if (indexTags === -1) {
    // if no tags in user form, add 'Machine'
    dashboardInfos.tags = [MACHINE_TAG];
  }
  return dashboardInfos;
};

/**
 * On create process, language is retreived,
 * in any other case (update or delete) we keep the dashboard's current language.
 */
export const getLanguage = (dispatch: (value: Action) => void, grafanaVariables: any[]) => {
  const langSelect = grafanaVariables && grafanaVariables.filter((template) => template.name === TemplatesVar.lang);
  dispatch({ type: 'ADD_LANG', payload: langSelect[0].query as string });
};

/**
 * For each form step, get the related required fields
 */
const getRequiredFields = (step: any) => {
  const requiredFields = step.fields.filter((field: any) => (field.isRequired ? field : null));
  return requiredFields;
};

export const payloadStep = (value: Process) => {
  let step = 0;
  switch (value) {
    // Update dashboard machine step
    case Process.update:
      step = CurrentStep.UPDATE_STEP1;
      break;
    // Delete dashboard machine step
    case Process.delete:
      step = CurrentStep.DELETE_STEP1;
      break;
    // Update single pages step
    case Process.manage_single_pages:
      step = CurrentStep.MANAGE_SINGLE_PAGES_STEP1;
      break;
    // creation
    default:
      step = CurrentStep.GENERAL_INFOS;
      break;
  }
  return step;
};

/**
 * If all required fields are filled in, go to the next form step
 */
export function stepForward(state: AppState, dispatch: any) {
  const { form, dictionary_uiElements, steps } = state;
  const getSteps = formSteps(dictionary_uiElements);
  const { step99, step0, step1, step2, step3 } = getSteps[0];

  dispatch({
    type: 'IS_SUBMITTED',
    payload: {
      value: true,
      step: `step${steps.current}`,
    },
  });

  if (!form) {
    return;
  }

  if (steps.current > 3) {
    dispatch({
      type: 'STEP_FORWARD',
      payload: steps.current + 1,
    });
    return;
  }

  let requiredFieldsStatus: string[] = [];
  let requiredFields: string[] = [];

  if (steps.current === 99) {
    requiredFields = getRequiredFields(step99);
  }

  if (steps.current === 0) {
    requiredFields = getRequiredFields(step0);
  }

  if (steps.current === 1) {
    requiredFields = getRequiredFields(step1);
  }

  if (steps.current === 2) {
    requiredFields = getRequiredFields(step2);
  }

  if (steps.current === 3) {
    requiredFields = getRequiredFields(step3);
  }

  requiredFields.map((requiredField: any) => {
    let index = form.findIndex((formField) => formField.fieldName === requiredField.name);
    // if requiredField is found in form and is validated, fill requFieldsStatus array with true
    if (index !== -1) {
      form[index].isValidate ? requiredFieldsStatus.push('true') : requiredFieldsStatus.push('false');
    }
    return;
  });

  const isAllValidate = requiredFieldsStatus.find((e) => e === 'false');
  // If no false is found in the requFieldsStatus array, then go to the next step
  if (
    isAllValidate === undefined &&
    requiredFieldsStatus.length === requiredFields.length &&
    state.error.name === '' &&
    state.error.macAddress === ''
  ) {
    dispatch({
      type: 'STEP_FORWARD',
      payload: steps.current + 1,
    });
  }

  return;
}

/**
 * Go to previous from step
 */
export const stepBackward = (state: AppState, dispatch: any) => {
  const { steps } = state;

  dispatch({
    type: 'STEP_BACKWARD',
    payload: steps.current - 1,
  });
  return;
};

/**
 * Go back to initial form step
 */
export const stepOne = (dispatch: any) => {
  dispatch({
    type: 'INITIAL_STEP',
    payload: 1,
  });
  return;
};

export const postToDb = async (dsUid: number, query: string) => {
  await getBackendSrv().post(API_QUERY_DB, {
    queries: [
      {
        datasource: {
          type: 'mysql',
          uid: dsUid,
        },
        format: 'table',
        rawSql: query,
        refId: 'tempvar',
      },
    ],
  });
};

/**
 * Search for Vibration Severity alert panel and inject alert threshold, depending on power_class.
 * Here, we have to search in dashboard.panels if the row is unfold, otherwise (if fold), search in dashboard.panels.panels
 */

export const injectThresholdInAlertPanel = (
  idCardItems: any,
  DASHBOARD_TO_UPDATE: { panels: any[] },
  dictionary_uiElements: TranslationProps,
  lengthUnit: string,
  customAlert: { warning: string; alert: string },
  isoClassifications: IsoClassificationsProps
) => {
  const searchCurrentClass = (item: { field: string }) => item.field === 'class';
  const searchAlertPanel = (panel: { type: string; title: string }) =>
    panel.type === 'row' && panel.title?.toLowerCase() === dictionary_uiElements.TR_panelAlertTitle?.toLowerCase();

  const currentClassIndex = idCardItems.findIndex(searchCurrentClass);
  const indexAlertPanel = DASHBOARD_TO_UPDATE.panels.findIndex(searchAlertPanel);
  const currentClass = idCardItems[currentClassIndex]?.value;
  const vibratoryThreshold = getVibratoryThreshold(
    currentClass,
    dictionary_uiElements,
    lengthUnit,
    customAlert,
    isoClassifications
  );

  const ALERT_PANEL = DASHBOARD_TO_UPDATE.panels[indexAlertPanel];
  const { TR_vibratorySeverity, TR_vibratorySeverityAlarm, TR_vibratorySeverityTrip } = dictionary_uiElements;

  if (indexAlertPanel !== -1) {
    if (ALERT_PANEL.panels.length) {
      // alarm
      const indexVibratoryAlarmPanelRow = ALERT_PANEL.panels.findIndex(
        (panel: { title: string }) => panel.title.toLowerCase() === TR_vibratorySeverityAlarm
      );

      if (indexVibratoryAlarmPanelRow !== -1 && ALERT_PANEL.panels[indexVibratoryAlarmPanelRow]?.alert) {
        ALERT_PANEL.panels[indexVibratoryAlarmPanelRow].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.alarm;
      }

      // trip
      const indexVibratoryTripPanelRow = ALERT_PANEL.panels.findIndex(
        (panel: { title: string }) => panel.title.toLowerCase() === TR_vibratorySeverityTrip
      );

      if (indexVibratoryTripPanelRow !== -1 && ALERT_PANEL.panels[indexVibratoryTripPanelRow]?.alert) {
        ALERT_PANEL.panels[indexVibratoryTripPanelRow].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.trip;
      }

      // trip for valve
      const indexVibratoryPanelRow = ALERT_PANEL.panels.findIndex(
        (panel: { title: string }) => panel.title.toLowerCase() === TR_vibratorySeverity
      );

      if (indexVibratoryPanelRow !== -1 && ALERT_PANEL.panels[indexVibratoryPanelRow]?.alert) {
        ALERT_PANEL.panels[indexVibratoryPanelRow].alert.conditions[0].evaluator.params[0] = vibratoryThreshold.trip;
      }
    } else {
      // alarm
      const indexVibratoryAlarmPanel = DASHBOARD_TO_UPDATE.panels.findIndex(
        (panel: { title: string }) => panel.title?.toLowerCase() === TR_vibratorySeverityAlarm?.toLowerCase()
      );

      if (indexVibratoryAlarmPanel !== -1 && DASHBOARD_TO_UPDATE.panels[indexVibratoryAlarmPanel].alert) {
        DASHBOARD_TO_UPDATE.panels[indexVibratoryAlarmPanel].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.alarm;
      }

      // trip
      const indexVibratoryTripPanel = DASHBOARD_TO_UPDATE.panels.findIndex(
        (panel: { title: string }) => panel.title?.toLowerCase() === TR_vibratorySeverityTrip?.toLowerCase()
      );

      if (indexVibratoryTripPanel !== -1 && DASHBOARD_TO_UPDATE.panels[indexVibratoryTripPanel].alert) {
        DASHBOARD_TO_UPDATE.panels[indexVibratoryTripPanel].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.trip;
      }

      // trip for valve
      const indexVibratoryPanel = DASHBOARD_TO_UPDATE.panels.findIndex(
        (panel: { title: string }) => panel.title?.toLowerCase() === TR_vibratorySeverity?.toLowerCase()
      );

      if (indexVibratoryPanel !== -1 && DASHBOARD_TO_UPDATE.panels[indexVibratoryPanel].alert) {
        DASHBOARD_TO_UPDATE.panels[indexVibratoryPanel].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.trip;
      }
    }
  }

  return DASHBOARD_TO_UPDATE;
};
