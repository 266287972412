import { Button } from '@grafana/ui';
import { ErrorProps } from 'app-context/types';
import { css } from '@emotion/css';
import { RED } from 'helpers/helpersStyle';
import React from 'react';

interface DisplayErrorsProps {
  error: ErrorProps;
  dictionary_uiElements: {
    TR_yes: string;
    TR_no: string;
    TR_confirmMacAddress: string;
  };
  continueCreate?: () => void;
  abortCreate?: () => void;
}

export const DisplayErrors: React.FunctionComponent<DisplayErrorsProps> = ({
  error,
  dictionary_uiElements,
  continueCreate,
  abortCreate,
}) => {
  const styles = getErrorStyles();

  return (
    <div className={styles.error}>
      {error.name && <div>{error.name}</div>}
      {error.macAddressPrefix && <div>{error.macAddressPrefix}</div>}
      {error.macAddress && <div>{error.macAddress}</div>}
      {error.datasource && <div>{error.datasource}</div>}
      {error.noDashboardFound && <div>{error.noDashboardFound}</div>}
      {error.badRequest && <div>{error.badRequest}</div>}
      {error.customThresholds && <div>{error.customThresholds}</div>}
      {error.macAddressDashboardDeleted && (
        <ConfirmOverWriteDashboard
          dictionary_uiElements={dictionary_uiElements}
          continueCreate={continueCreate}
          error={error}
          abortCreate={abortCreate}
        />
      )}
    </div>
  );
};

const ConfirmOverWriteDashboard: React.FunctionComponent<DisplayErrorsProps> = ({
  dictionary_uiElements,
  error,
  continueCreate,
  abortCreate,
}) => {
  const styles = getErrorStyles();
  const { TR_confirmMacAddress, TR_yes, TR_no } = dictionary_uiElements;

  return (
    <div className={styles.displayContinue}>
      <div>{error.macAddressDashboardDeleted}</div>
      <div>
        {TR_confirmMacAddress} {'  '}
        <Button size="sm" variant="destructive" className={styles.buttonConfirm} onClick={continueCreate}>
          {TR_yes}
        </Button>
        <Button size="sm" variant="secondary" onClick={abortCreate}>
          {TR_no}
        </Button>
      </div>
    </div>
  );
};

const getErrorStyles = () => {
  return {
    error: css`
      color: ${RED};
      text-align: center;
    `,
    displayContinue: css`
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    `,
    buttonConfirm: css`
      margin-right: 5px;
    `,
  };
};
