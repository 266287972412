import { CLASS_TABLE, EDITABLE_MAC_ADDRESS_LENGTH } from 'helpers/constants';
import { AppState, Action, Process } from './types';

/**
 * Contains all the state actions
 */

export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    /**
     * Toggle visibility of different modals (CREATE, UPDATE, DELETE)
     */

    case 'TOGGLE_VISIBLE': {
      const selectedModalId = state.modals.findIndex((modal) => modal.process === action.payload.process);
      const restOfModals = state.modals.filter((modal) => modal.process !== action.payload.process);

      const newState = [
        ...restOfModals,
        {
          process: action.payload.process,
          isOpen: state.modals[selectedModalId] && !state.modals[selectedModalId].isOpen,
        },
      ];

      return {
        ...state,
        modals: newState,
        currentModal: action.payload.process,
      };
    }

    /**
     * Go to the next form step
     */

    case 'STEP_FORWARD': {
      return {
        ...state,
        steps: {
          ...state.steps,
          current: action.payload,
        },
        error: {
          name: '',
          macAddress: '',
          datasource: '',
          noDashboardFound: '',
          badRequest: '',
          macAddressDashboardDeleted: '',
          macAddressPrefix: '',
          customThresholds: '',
        },
      };
    }

    /**
     * Go to the previous form step
     */

    case 'STEP_BACKWARD': {
      return {
        ...state,
        steps: {
          ...state.steps,
          current: action.payload,
        },
      };
    }

    case 'INITIAL_STEP': {
      return {
        ...state,
        steps: {
          ...state.steps,
          current: action.payload,
        },
      };
    }

    case 'ADD_TO_FORM': {
      const { fieldName, value, tableName } = action.payload;
      const filteredForm = state.form.filter((field) => field.fieldName !== fieldName);
      const updatedFormFields = [
        ...filteredForm,
        {
          fieldName: fieldName,
          value: value,
          isValidate:
            value === '' ||
            value === null ||
            value === undefined ||
            (tableName === 'mac_address' &&
              state.currentModal !== Process.update &&
              value.length !== EDITABLE_MAC_ADDRESS_LENGTH) ||
            (tableName === CLASS_TABLE &&
              value === state.dictionary_uiElements.TR_customClass &&
              (!state.customIso.alert || !state.customIso.warning)) ||
            state.error.customThresholds !== ''
              ? false
              : true,
          tableName: tableName,
        },
      ];

      return {
        ...state,
        form: updatedFormFields,
      };
    }

    case 'RESET_STEPS': {
      return {
        ...state,
        steps: {
          ...state.steps,
          current: action.payload,
          stepsStatus: {
            // Deletion first and unique step
            step99: {
              isSubmit: false,
              isValid: false,
            },
            // Manage single pages first and unique step
            step98: {
              isSubmit: false,
              isValid: false,
            },
            // Update first step
            step0: {
              isSubmit: false,
              isValid: false,
            },
            // Creation first step
            step1: {
              isSubmit: false,
              isValid: false,
            },
            // Next steps of creation and update
            step2: {
              isSubmit: false,
              isValid: false,
            },
            step3: {
              isSubmit: false,
              isValid: false,
            },
            step4: {
              isSubmit: false,
              isValid: false,
            },
          },
        },
        form: [],
        fileImage: '#',
        fileImageName: '',
        uid: '',
        error: {
          name: '',
          macAddress: '',
          datasource: '',
          noDashboardFound: '',
          badRequest: '',
          macAddressDashboardDeleted: '',
          macAddressPrefix: '',
          customThresholds: '',
        },
        lengthUnit: 'meter',
        temperatureUnit: 'Celsius (°C)',
        customIso: {
          alert: '',
          warning: '',
        },
      };
    }

    case 'ADD_IMAGE_FILE': {
      return {
        ...state,
        fileImage: action.payload,
      };
    }

    case 'ADD_LANG': {
      return {
        ...state,
        lang: action.payload,
      };
    }

    case 'SET_ERROR': {
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.errorName]: action.payload.text,
        },
      };
    }

    case 'ADD_FILE_NAME': {
      return {
        ...state,
        fileImageName: action.payload,
      };
    }

    case 'IS_SUBMITTED': {
      return {
        ...state,
        steps: {
          ...state.steps,
          stepsStatus: {
            ...state.steps.stepsStatus,
            [action.payload.step]: {
              isSubmit: action.payload.value,
            },
          },
        },
      };
    }

    case 'ADD_SELECT_OPTIONS': {
      const filteredDico = state.dropdownMenusOptions.filter((element) => element.name !== action.payload.tableName);
      return {
        ...state,
        dropdownMenusOptions: [
          ...filteredDico,
          {
            name: action.payload.tableName,
            values: action.payload.value,
          },
        ],
      };
    }

    case 'REMOVE_SELECT_OPTIONS': {
      return {
        ...state,
        dropdownMenusOptions: [],
      };
    }

    case 'ADD_UID': {
      return {
        ...state,
        uid: action.payload,
      };
    }

    case 'IS_DATA_LOADED': {
      return {
        ...state,
        isDataLoaded: action.payload,
      };
    }

    case 'ADD_DATASOURCES': {
      return {
        ...state,
        datasourceMysql: action.payload.mysql,
        datasourceInflux: action.payload.influx,
        datasourceJson: action.payload.json,
      };
    }

    case 'ADD_OPTIONS_DICO': {
      return {
        ...state,
        multiLingualDictionary: action.payload,
      };
    }

    case 'ADD_TRANSLATED_UI_TO_DICO': {
      return {
        ...state,
        dictionary_uiElements: action.payload,
      };
    }

    case 'SET_DASHBOARDS_LIST': {
      return {
        ...state,
        dashboardsList: action.payload,
      };
    }

    case 'ADD_DASHBOARD_FOLDER': {
      return {
        ...state,
        dashboardFolder: action.payload,
      };
    }

    case 'SET_MAC_ADDRESSES': {
      return {
        ...state,
        macAddresses: action.payload,
      };
    }

    case 'SET_SELECTED_FOLDER': {
      return {
        ...state,
        selectedFolder: action.payload,
      };
    }

    case 'SET_TEMPERATURE_UNIT': {
      return {
        ...state,
        temperatureUnit: action.payload,
      };
    }

    case 'SET_LENGTH_UNIT': {
      return {
        ...state,
        lengthUnit: action.payload,
      };
    }

    case 'SET_CUSTOM_ISO': {
      return {
        ...state,
        customIso: { alert: action.payload.alert, warning: action.payload.warning },
      };
    }

    case 'SET_ISO_CLASSIFICATIONS': {
      return {
        ...state,
        isoClassifications: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
