import { getBackendSrv } from '@grafana/runtime';
import { Button, Icon, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from 'app-context/AppStateContext';
import { DashboardReceived, DashboardTemplateType, TemplatesVar } from 'app-context/types';
import { css } from '@emotion/css';
import {
  alertStateVariableName,
  COMPARISON_TAG,
  foldersVariableName,
  machinesVariableName,
  MAIN_TAG,
  SUMMARY_TAG,
  tagsVariableName,
} from 'helpers/constants';
import { getTemplateDashboard } from 'helpers/GetTemplateDashboard';
import { API_DASHBOARD_BY_UID, API_QUERY_DASHBOARD, API_SAVE_JSON } from 'helpers/URLS';
import React, { useEffect, useState } from 'react';
import { NotificationSuccess } from 'shared/components/NotificationMessage';
import { getNewDashboardObject, injectUserTemplates } from 'helpers/helpersDashboards';

interface Step98Props {}

/**
 * MANAGE SINGLE PAGES - Feature only accessible to admin user.
 * Allows to update or delete single page dashboards :
 * - MAIN DASHBOARD,
 * - OVERVIEW DASHBOARD
 * - COMPARISON DASHBOARD
 * Dashboards will be deleted if they have a different language from Main Dashboard
 * and if they are deprecated (as "Synthetic dashboard")
 */

export const Step98: React.FunctionComponent<Step98Props> = () => {
  const { state } = useAppState();
  const { dictionary_uiElements, lang, theme, datasourceInflux } = state;
  const { TR_comparisonDashboard, TR_deprecatedSynthDashboardName, TR_overviewDashboard, TR_finalMessageUpdate2 } =
    dictionary_uiElements;
  const [dashboardsToUpdate, setDashboardsToUpdate] = useState([] as DashboardReceived[]);
  const [dashboardsToDelete, setDashboardsToDelete] = useState([] as DashboardReceived[]);
  const [dashboardsToCreate, setDashboardsToCreate] = useState([] as DashboardReceived[]);
  const [isValidate, setIsValidate] = useState(false);

  const styles = getStyles(theme);
  /**
   * API Call
   * Get Comparison, main and overview Dashboards by tag
   * and categorize dashboards (to delete or to update).
   */

  const searchByTag = async (tag: string) => {
    const newDashboardsToDelete = [] as DashboardReceived[];
    const newDashboardsToUpdate = [] as DashboardReceived[];

    await getBackendSrv()
      .get(`${API_QUERY_DASHBOARD}&tag=${tag}`)
      .then(async (result: DashboardReceived[]) => {
        if (!result.length) {
          return [];
        }

        /** For each dashboard, get json to retrieve dashboard lang and template variables */
        for await (const dash of result) {
          await getBackendSrv()
            .get(`${API_DASHBOARD_BY_UID}${dash.uid}`)
            .then((resultDash: { dashboard: { templating: { list: any[] } } }) => {
              if (Object.keys(resultDash).length) {
                const templateVariables = resultDash?.dashboard?.templating?.list;
                const langSelectionIndex = templateVariables.findIndex(
                  (variable: { name: string }) => variable.name === TemplatesVar.lang
                );

                if (langSelectionIndex !== -1) {
                  const dashboardLang = templateVariables[langSelectionIndex].query;
                  dash.lang = dashboardLang;
                  dash.templatesVariables = templateVariables;
                  // Categorize dashboards in another language and the deprecated "Synthetic Dashboard" to be deleted
                  if (dashboardLang !== lang || dash.title?.toUpperCase()?.includes(TR_deprecatedSynthDashboardName)) {
                    newDashboardsToDelete.push(dash);
                  } else {
                    newDashboardsToUpdate.push(dash);
                  }
                }
              }
            })
            .catch((err) => console.log(err));
        }
        return result;
      })
      .catch((err) => {
        console.log(err);
      });

    return { delete: newDashboardsToDelete, update: newDashboardsToUpdate };
  };

  /** Sort dashboards to update or to delete */

  const sortDashboards = async () => {
    const summaryDashboards = await searchByTag(SUMMARY_TAG);
    const comparisonDashboards = await searchByTag(COMPARISON_TAG);
    const mainDashboards = await searchByTag(MAIN_TAG);

    const newDashboardsToDelete = [
      ...summaryDashboards.delete,
      ...comparisonDashboards.delete,
      ...mainDashboards.delete,
    ];
    const newDashboardsToUpdate = [
      ...summaryDashboards.update,
      ...comparisonDashboards.update,
      ...mainDashboards.update,
    ];

    const newDashboardsToCreate = [] as DashboardReceived[];

    /**
     * If Comparison dashboard or Overview Dashboard don't exist in dashboards to updated array,
     * add them to dashboards to be created array.
     */
    const comparisonDashboardIndex = newDashboardsToUpdate.findIndex((updateDash) =>
      updateDash.title?.toUpperCase()?.includes(TR_comparisonDashboard)
    );
    const overviewDashboardIndex = newDashboardsToUpdate.findIndex((updateDash) =>
      updateDash.title?.toUpperCase()?.includes(TR_overviewDashboard)
    );

    if (comparisonDashboardIndex === -1) {
      const comparisonDashboardObject = getNewDashboardObject(COMPARISON_TAG, TR_comparisonDashboard, lang);
      newDashboardsToCreate.push(comparisonDashboardObject);
    }

    if (overviewDashboardIndex === -1) {
      const overviewDashboardObject = getNewDashboardObject(SUMMARY_TAG, TR_overviewDashboard, lang);
      newDashboardsToCreate.push(overviewDashboardObject);
    }

    setDashboardsToDelete(newDashboardsToDelete);
    setDashboardsToUpdate(newDashboardsToUpdate);
    setDashboardsToCreate(newDashboardsToCreate);
  };

  /** Delete dashboards in Grafana */
  const deleteDashboards = async () => {
    for await (const dash of dashboardsToDelete) {
      await getBackendSrv()
        .delete(`${API_DASHBOARD_BY_UID}${dash.uid}`)
        .catch((err) => console.log(err));
    }
  };

  const postDashboard = async (
    dashboard: DashboardReceived,
    tag: string,
    tagTemplate: DashboardTemplateType,
    lang: string,
    isUpdate: boolean
  ) => {
    if (dashboard.tags.findIndex((dashTag) => dashTag?.toUpperCase() === tag.toUpperCase()) !== -1) {
      const dashboardTemplate = await getTemplateDashboard(tagTemplate, lang);
      /**
       * If dashboard is an Overview Dashboard (new version), keep title and templates variables (to keep user's configuration).
       * !! Caution !! If new templates variables are added to the template json of Overview Dashboard,
       * they will be overwritten.
       */
      if (isUpdate && tagTemplate === DashboardTemplateType.overview) {
        dashboardTemplate.title = dashboard.title;

        injectUserTemplates(dashboard, dashboardTemplate, tagsVariableName);
        injectUserTemplates(dashboard, dashboardTemplate, foldersVariableName);
        injectUserTemplates(dashboard, dashboardTemplate, alertStateVariableName);
        injectUserTemplates(dashboard, dashboardTemplate, machinesVariableName);
      }

      if (tagTemplate === DashboardTemplateType.comparison) {
        // Inject datasources in Comparison dashboard (needed for queries)
        const newPanels = dashboardTemplate.panels.map(
          (panel: { datasource: { type: string; uid: string }; targets: any[] }) => {
            if (panel?.datasource?.type === 'influxdb') {
              panel.datasource.uid = datasourceInflux.uid;
            }
            if (panel?.targets?.length) {
              panel.targets.map((target: { datasource: { type: string; uid: string } }) => {
                if (target?.datasource?.type === 'influxdb') {
                  target.datasource.uid = datasourceInflux.uid;
                  return target;
                }
                return target;
              });
            }
            return panel;
          }
        );
        dashboardTemplate.panels = newPanels;
      }

      const dashboardToUpdate = {
        dashboard: dashboardTemplate,
        message: 'Dashboard updated with new template',
        overwrite: isUpdate,
      };

      await getBackendSrv()
        .post(API_SAVE_JSON, dashboardToUpdate)
        .then(() => NotificationSuccess(`${dashboardTemplate.title}. ${TR_finalMessageUpdate2}`))
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    return;
  };

  /** Overwrite dashboards (update) in Grafana */
  const updateDashboards = async () => {
    for await (const dashboard of dashboardsToUpdate) {
      if (dashboard.tags[0] === MAIN_TAG) {
        await postDashboard(dashboard, MAIN_TAG, DashboardTemplateType.main, lang, true);
      }

      if (dashboard.title === TR_comparisonDashboard) {
        await postDashboard(dashboard, COMPARISON_TAG, DashboardTemplateType.comparison, lang, true);
      }
      // Includes is necessary to catch all the overview dashboards
      if (dashboard.title?.toUpperCase()?.includes(TR_overviewDashboard?.toUpperCase())) {
        await postDashboard(dashboard, SUMMARY_TAG, DashboardTemplateType.overview, lang, true);
      }
    }
  };

  /** Create dashboards in Grafana (especially Comparison dashboard and Overview dashboard) */
  const createDashboards = async () => {
    for await (const dashboard of dashboardsToCreate) {
      if (dashboard.title === TR_comparisonDashboard) {
        await postDashboard(dashboard, COMPARISON_TAG, DashboardTemplateType.comparison, lang, false);
      }

      if (dashboard.title === TR_overviewDashboard) {
        await postDashboard(dashboard, SUMMARY_TAG, DashboardTemplateType.overview, lang, false);
      }
    }
  };

  const onValidate = () => {
    if (dashboardsToDelete.length) {
      deleteDashboards();
    }

    if (dashboardsToUpdate.length) {
      updateDashboards();
    }

    if (dashboardsToCreate.length) {
      createDashboards();
    }

    setIsValidate(true);
  };

  useEffect(() => {
    sortDashboards();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {!isValidate ? (
        <DisplayDashboardsLists
          dashboardsToDelete={dashboardsToDelete}
          dashboardsToUpdate={dashboardsToUpdate}
          dashboardsToCreate={dashboardsToCreate}
          onValidate={onValidate}
        />
      ) : (
        <div className={styles.finalMessageContainer}>Done !</div>
      )}
    </div>
  );
};

interface DisplayDashboardsListsProps {
  dashboardsToUpdate: DashboardReceived[];
  dashboardsToDelete: DashboardReceived[];
  dashboardsToCreate: DashboardReceived[];
  onValidate: () => void;
}

const DisplayDashboardsLists: React.FunctionComponent<DisplayDashboardsListsProps> = ({
  dashboardsToDelete,
  dashboardsToUpdate,
  dashboardsToCreate,
  onValidate,
}) => {
  const { state } = useAppState();
  const { lang, user } = state;

  const styles = useStyles2(getStyles);

  const DASHBOARDS_SECTIONS = [
    {
      dashboards: dashboardsToDelete,
      sectionTitle: 'TO DELETE',
      subtitle: `Deprecated dashboards or dashboards not in ${lang}`,
    },
    {
      dashboards: dashboardsToUpdate,
      sectionTitle: 'TO UPDATE',
      subtitle: 'Update dashboards with their json template',
    },
    {
      dashboards: dashboardsToCreate,
      sectionTitle: 'TO CREATE',
      subtitle: 'New dashboards',
    },
  ];

  return (
    <div>
      <div>
        <b>SINGLE PAGES = MAIN DASHBOARD, COMPARISON DASHBOARD, OVERVIEW DASHBOARD</b>
      </div>
      <div
        className={css`
          padding: 16px;
        `}>
        <div>Current language: {lang}</div>
        <div>Current organization: {user.orgName}</div>
      </div>
      <div className={styles.sectionsContainer}>
        {DASHBOARDS_SECTIONS.map((section) => (
          <DashboardSection
            dashboards={section.dashboards}
            sectionTitle={section.sectionTitle}
            subtitle={section.subtitle}
            key={section.sectionTitle}
          />
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button size={'lg'} variant={'primary'} onClick={() => onValidate()}>
          VALIDATE
        </Button>
      </div>
    </div>
  );
};

/**
 * Dashboards sections:
 * - dashboards to delete (those not in the current language or obsolete)
 * - dashboards to update (obsolete dashboards)
 * - dashboards to create (missing dashboards)
 */

interface DashboardsSectionProps {
  dashboards: DashboardReceived[];
  sectionTitle: string;
  subtitle: string;
}

const DashboardSection: React.FunctionComponent<DashboardsSectionProps> = ({ dashboards, sectionTitle, subtitle }) => {
  const { state } = useAppState();
  const { theme } = state;
  const styles = getStyles(theme);
  return (
    <>
      {dashboards.length !== 0 && (
        <div className={styles.section}>
          <div className={styles.dashboardSectionTitle}>
            {/* Icon & title */}
            <h3
              className={css`
                font-weight: 600;
              `}>
              {sectionTitle}
            </h3>
            {/* Subtitle */}
            <h5>
              <i>{subtitle}</i>
            </h5>
          </div>
          {/* Dashboards list */}
          {dashboards.map((dash) => (
            <div key={dash.uid}>
              <Icon name={'angle-right'} />
              {dash.title} ({dash.lang})
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    sectionsContainer: css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `,
    section: css`
      border-radius: 8px;
      border: 1px solid ${theme.colors.border.weak};
      flex: 1;
      margin-bottom: 12px;
      padding: 18px;
    `,
    dashboardSectionTitle: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    `,
    buttonContainer: css`
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 90px;
    `,
    finalMessageContainer: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 300px;
      font-size: 32px;
      font-weight: 600;
    `,
  };
};
