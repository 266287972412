import { getBackendSrv } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { Process, RelatedPluginTypes } from 'app-context/types';
import { css } from '@emotion/css';
import { SENSOR_ORIENTATION_TABLE, SIMPLEJSON_DATASOURCE } from 'helpers/constants';
import { isInputInvalid } from 'helpers/helpersForm';
import React, { useEffect, useState } from 'react';
import { IconRelatedPlugin } from 'shared/IconRelatedPlugin';
import { SectionTitle } from 'shared/SectionTitle';
import { formSteps } from '../../formSteps';
import { DownloadImageContainer } from './DownloadImageContainer';
import { FormField } from './FormFieldStep2';
import { URL_GET_DATASOURCE_BY_NAME } from 'helpers/URLS';

/**
 * FORM STEP 2
 * Mounting location information
 * @fields :
 * - machine part,
 * - sensor location,
 * - sensor_orientation,
 * - image_file
 */

export const Step2: React.FunctionComponent = () => {
  const { state, dispatch } = useAppState();
  const { dictionary_uiElements, currentModal, fileImageName, form, error, customIso } = state;
  const steps = formSteps(dictionary_uiElements);
  const { step2 } = steps[0];
  const [file, setFile] = useState(state.fileImage);
  const isSubmit = state.steps.stepsStatus.step2?.isSubmit;
  const styles = useStyles2(getStyles);

  const getImage = async () => {
    const dsJson: any = await getBackendSrv().get(`${URL_GET_DATASOURCE_BY_NAME}${SIMPLEJSON_DATASOURCE}`);
    await getBackendSrv()
      .get(`api/datasources/proxy/uid/${dsJson.uid}/downloadImage?fileName=` + fileImageName)
      .then((response) => {
        setFile(response);
        dispatch({ type: 'ADD_IMAGE_FILE', payload: response });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: { errorName: 'badRequest', text: 'Image not found, please upload another image.' },
        });
      });
  };

  useEffect(() => {
    if (currentModal === Process.update && fileImageName !== '' && state.fileImage === '#') {
      getImage();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form>
          <SectionTitle title={step2.sectionTitle} />
          {step2.fields.map((field: any) => {
            return (
              <div className={styles.fieldContainer} key={`${field.tableName}-${field.name}`}>
                <div className={styles.iconContainer}>
                  {field.tableName === SENSOR_ORIENTATION_TABLE && <IconRelatedPlugin plugin={RelatedPluginTypes.ia} />}
                </div>
                <FormField
                  name={field.name}
                  isRequired={field.isRequired}
                  tableName={field.tableName}
                  key={field.name}
                  isInvalid={isInputInvalid(field.name, step2, isSubmit, form, error, customIso, dictionary_uiElements)}
                />
              </div>
            );
          })}
          <DownloadImageContainer file={file} setFile={setFile} />
        </form>
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    `,
    formContainer: css`
      width: 100%;
      display: flex;
      justify-content: center;
    `,
    fieldContainer: css`
      display: flex;
      flex-direction: row;
    `,
    iconContainer: css`
      width: 32px;
      text-align: center;
    `,
  };
};
