import { AppEvents } from '@grafana/data';
//@ts-ignore
import appEvents from 'grafana/app/core/app_events';

export const NotificationSuccess = (message: string) => {
  return appEvents.emit(AppEvents.alertSuccess, ['Success', message]);
};

export const NotificationError = (err?: any) => {
  return appEvents.emit(AppEvents.alertError, [
    err.status ? `Error status ${err.status}` : 'Error',
    err.data ? err.data?.message : err.message,
  ]);
};
